import React, { useState } from 'react';
import { IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { EXCHANGES_LOGO } from 'src/enums/common';
import { Monitor as EditIcon } from 'react-feather';
import MachineDetailModal from 'src/views/jobs/components/MachineDetailModal';

const useStyles = makeStyles(theme => ({
  root: {},
  parity: {
    display: 'inline-block',
    marginLeft: 10
  },
  machineCellStatus: {
    width: 10
  },
  machineCellDetail: {
    flex: 1
  },
  machineCell: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0
    }
  },
  machineName: {
    display: 'flex',
    fontSize: '12px'
  },
  machineIp: {
    display: 'flex',
    fontSize: '12px'
  },
  exchangeImage: {
    height: 15,
    marginBottom: 5,
    display: 'block',
    '&:last-child': {
      marginBottom: 0
    }
  },
  activeBookMachine: {
    color: 'green'
  },
  a: {
    color: '#e6e5e8',
    textDecoration: 'none'
  }
}));

export default function MachineDetailCell({ machineId }) {
  const [editOpen, setEditOpen] = useState(false);
  const classes = useStyles();
  const machine = useSelector(state => state.machine.list[machineId]);

  function renderMachineName() {
    if (machine.machineType === 'books') {
      return (
        <div className={classes.machineName}>
          {EXCHANGES_LOGO[machine.parametres.CNBOT_EXCHANGE]}
          <span className={classes.parity}>
            {machine.parametres.CNBOT_PARITY}
          </span>
        </div>
      );
    } else if (machine.machineType === 'order') {
      return machine.parametres.CNBOT_COIN;
    } else if (machine.machineType === 'transaction') {
      return machine.parametres.CNBOT_COIN;
    }
    return '';
  }

  return (
    <div className={classes.machineCell}>
      <IconButton color="inherit" onClick={() => setEditOpen(true)}>
        <SvgIcon
          fontSize="small"
          className={`${
            machine.socketConnected ? classes.activeBookMachine : ''
          }`}
        >
          <EditIcon />
        </SvgIcon>
      </IconButton>
      <div className={classes.machineCellDetail}>
        {renderMachineName()}
        <div className={classes.machineIp}>
          <a href={`ssh://root@${machine.publicIp}`} className={classes.a}>
            {machine.publicIp}
          </a>
        </div>
      </div>

      <MachineDetailModal
        machineId={machine.id}
        open={editOpen}
        onClose={() => setEditOpen(false)}
      />
    </div>
  );
}
