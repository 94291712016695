import React, { useEffect } from 'react';
import { Box, Card, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import wait from 'src/utils/wait';
import { CoinEnum, MinorCoinEnum } from 'src/enums/common';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CoinCell from 'src/components/CoinCell';
import Select from 'src/components/Select';
import AddressCell from './AddressCell';
import {
  createNewTransfer,
  fetchAllAddresses
} from 'src/store/actions/transferActions';
import AccountCell from 'src/components/AccountCell';
import ConfirmButton from 'src/components/ConfirmButton';

const withdrawValidationSchema = Yup.object().shape({
  coin: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  amount: Yup.number().required('Required')
});

function Withdraw({ onSubmit }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const addresses = useSelector(state => state.transfer.addresses);
  const accounts = useSelector(state => state.accounts.list);

  const formik = useFormik({
    initialValues: {
      coin: '',
      account: '',
      address: '',
      amount: ''
    },
    validationSchema: withdrawValidationSchema,
    onSubmit: async values => {
      try {
        const { coin, ...params } = values;
        await dispatch(createNewTransfer(params));

        enqueueSnackbar(`Transfer completed ${values.amount}`, {
          variant: 'success'
        });
        formik.resetForm();
        formik.setStatus({ success: true });
        await wait(500);
        if (onSubmit) {
          onSubmit();
        }
      } catch (error) {
        formik.setStatus({ success: false });
        formik.setErrors({ submit: error.message });
      }
    }
  });

  useEffect(() => {
    dispatch(fetchAllAddresses());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    formik.setFieldValue('account', '');
    formik.setFieldValue('address', '');
    // eslint-disable-next-line
  }, [formik.values.coin]);

  useEffect(() => {
    formik.setFieldValue('address', '');
    // eslint-disable-next-line
  }, [formik.values.account]);

  const coins = [MinorCoinEnum.USDT, ...Object.values(CoinEnum)];
  coins.pop(); // delete ETH_UNAVAILABLE

  return (
    <Card>
      <Box mt={2}>
        <form onSubmit={formik.handleSubmit}>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item md={12} sm={12} xs={12}>
                <Select
                  id="coin"
                  name="Coin"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.coin}
                  error={formik.touched.coin && formik.errors.coin}
                  items={Object.values(coins)}
                  renderCell={coin => <CoinCell coin={coin} />}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Select
                  id="account"
                  name="From"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.account}
                  error={formik.touched.account && formik.errors.account}
                  items={formik.values.coin ? Object.keys(accounts) : []}
                  renderCell={v => (
                    <AccountCell
                      account={v}
                      coin={formik.values.coin}
                      balance
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Select
                  id="address"
                  name="To"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  error={formik.touched.address && formik.errors.address}
                  items={Object.keys(addresses).filter(
                    v =>
                      formik.values.account &&
                      addresses[v].coin === formik.values.coin &&
                      formik.values.account !== addresses[v].account
                  )}
                  renderCell={v => <AddressCell address={v} balance coin />}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  error={Boolean(formik.touched.amount && formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  size="small"
                  label="Amount"
                  name="amount"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          {formik.errors.submit && (
            <Box mt={3} margin={1}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Box>
          )}
          <ConfirmButton
            buttonTitle="Withdraw"
            content="Do you approve withdraw?"
            variant="contained"
            color="secondary"
            disabled={formik.isSubmitting}
            confirmAction={formik.handleSubmit}
          />
        </form>
      </Box>
    </Card>
  );
}

export default Withdraw;
