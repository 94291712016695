import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Card,
  CardHeader,
  makeStyles,
  fade
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  primary: {
    background: theme.palette.primary.main
  },
  secondary: {
    background: theme.palette.secondary.main
  },
  error: {
    background: theme.palette.error.main
  },
  success: {
    background: theme.palette.success.main
  },
  info: {
    background: theme.palette.info.main
  },
  warning: {
    background: theme.palette.warning.main
  },
  'primary-overlay': {
    background: fade(theme.palette.primary.main, 0.1)
  },
  'secondary-overlay': {
    background: fade(theme.palette.secondary.main, 0.1)
  },
  'error-overlay': {
    background: fade(theme.palette.error.main, 0.1)
  },
  'success-overlay': {
    background: fade(theme.palette.success.main, 0.1)
  },
  'info-overlay': {
    background: fade(theme.palette.info.main, 0.1)
  },
  'warning-overlay': {
    background: fade(theme.palette.warning.main, 0.1)
  }
}));

function Row({
  columns,
  dataRow,
  isNested,
  renderNestedRow,
  rowClassName,
  selectedRow,
  handleClickRow = noop,
  highlightedRow,
  highlightColor
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        className={`${rowClassName} ${
          selectedRow === dataRow.id || highlightedRow?.includes(dataRow.id)
            ? classes[highlightColor]
            : ''
        }`}
        onClick={() => handleClickRow(dataRow)}
      >
        {isNested && (
          <TableCell width={30} style={{ paddingBottom: 5, paddingTop: 5 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map(({ key, renderCell, align, ...restCellOptions }) => (
          <TableCell
            key={key}
            align={align}
            style={{ paddingBottom: 5, paddingTop: 5 }}
            {...restCellOptions}
          >
            {renderCell ? renderCell(dataRow[key], dataRow) : dataRow[key]}
          </TableCell>
        ))}
      </TableRow>
      {isNested && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={3}>
                {renderNestedRow && renderNestedRow(dataRow)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function DataTable({
  columns,
  head,
  idProp = 'id',
  source = [],
  isNested = false,
  size = 'medium',
  rowClassName = '',
  renderNestedRow,
  handleClickRow,
  pagination,
  selectedRow = null,
  highlightedRow = null,
  highlightColor = 'primary'
}) {
  return (
    <Card>
      {head && <CardHeader title={head} />}
      {pagination && (
        <TablePagination
          component="div"
          count={pagination.limit * pagination.totalPages}
          onChangePage={pagination?.onChangePage}
          page={pagination.page - 1}
          rowsPerPage={pagination.limit || 1}
          rowsPerPageOptions={[pagination.limit]}
        />
      )}
      <PerfectScrollbar>
        <Table size={size}>
          <TableHead>
            <TableRow>
              {isNested && <TableCell />}
              {columns.map(({ key, title, renderTitle, align }) => (
                <TableCell key={key || title} align={align}>
                  {renderTitle ? renderTitle() : title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {source.map(dataRow => (
              <Row
                key={dataRow[idProp]}
                dataRow={dataRow}
                rowClassName={rowClassName}
                columns={columns}
                isNested={isNested}
                renderNestedRow={renderNestedRow}
                handleClickRow={handleClickRow}
                selectedRow={selectedRow}
                highlightedRow={highlightedRow}
                highlightColor={highlightColor}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {pagination && (
        <TablePagination
          component="div"
          count={pagination.limit * pagination.totalPages}
          onChangePage={pagination?.onChangePage}
          page={pagination.page - 1}
          rowsPerPage={pagination.limit || 1}
          rowsPerPageOptions={[pagination.limit]}
        />
      )}
    </Card>
  );
}

DataTable.propTypes = {
  highlightColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
    'primary-overlay',
    'secondary-overlay',
    'error-overlay',
    'warning-overlay',
    'success-overlay',
    'info-overlay'
  ])
};

export default DataTable;
