import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  useTheme
} from '@material-ui/core';
import Select from 'src/components/Select';
import AddressCell from './AddressCell';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircle as AddIcon, X as CloseIcon } from 'react-feather';
import { createNewTransferJobAccount } from 'src/store/actions/transferActions';
import wait from 'src/utils/wait';
import { useSnackbar } from 'notistack';

const newAccountValidationSchema = Yup.object().shape({
  addressId: Yup.string().required('Required'),
  expectationRatio: Yup.number().required('Required')
});

function AddAccount({ jobs, handleAdd }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const addresses = useSelector(state => state.transfer.addresses);

  const [isOpen, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: { addressId: '', expectationRatio: '' },
    validationSchema: newAccountValidationSchema,
    onSubmit: async values => {
      try {
        await dispatch(
          createNewTransferJobAccount({ id: jobs.id, account: values })
        );
        enqueueSnackbar(`Created Job Account for the ${jobs.coin}`, {
          variant: 'success'
        });
        formik.resetForm();
        formik.setStatus({ success: true });
        await wait(500);
        handleAdd && handleAdd(values);
        handleClose();
      } catch (error) {
        formik.setStatus({ success: false });
        formik.setErrors({ submit: error.message });
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <Box>
      <Collapse in={!isOpen}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<AddIcon />}
          style={{ height: 60 }}
          onClick={() => setOpen(true)}
        >
          Add Account
        </Button>
      </Collapse>
      <Collapse in={isOpen}>
        <Box
          p={1}
          borderRadius={5}
          border={1}
          borderColor={theme.palette.action.disabled}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Select
                    id="addressId"
                    name="Address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.addressId}
                    error={formik.touched.addressId && formik.errors.addressId}
                    items={Object.keys(addresses).filter(
                      v =>
                        !jobs.accounts.some(acc => acc.addressId === v) &&
                        addresses[v].coin === jobs.coin
                    )}
                    renderCell={v => <AddressCell address={v} coin balance />}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(
                      formik.touched.expectationRatio &&
                        formik.errors.expectationRatio
                    )}
                    helperText={
                      formik.touched.expectationRatio &&
                      formik.errors.expectationRatio
                    }
                    size="small"
                    label="Ratio"
                    name="expectationRatio"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.expectationRatio}
                    variant="outlined"
                  />
                </Grid>
                {formik.errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      {formik.errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Grid item md={12} sm={12} xs={12}>
                  <Box display="flex" flexDirection="row">
                    <Button
                      fullWidth
                      color="secondary"
                      disabled={formik.isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Add Account
                    </Button>
                    <IconButton color="inherit" onClick={handleClose}>
                      <SvgIcon fontSize="small">
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Collapse>
    </Box>
  );
}

export default AddAccount;
