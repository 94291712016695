import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import DataTable from 'src/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderFailed } from 'src/store/actions/transactionActions';
import moment from 'moment';
import { EXCHANGES_LOGO, COINS_ICONS } from 'src/enums/common';

const useStyles = makeStyles(theme => ({
  root: {},
  exchangeImage: {
    height: 15
  },
  coinIcon: {
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  commissionRate: {
    display: 'block',
    fontSize: 10
  },
  coinCell: {
    display: 'flex',
    alignItems: 'center'
  },
  coinLabel: {
    marginLeft: 10
  },
  earningCurrencyCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  earningCurrency: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 5,
    '& > svg': {
      width: 16,
      height: 'auto'
    }
  },
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  }
}));

export const transactionStatus = {
  completed: {
    text: 'Completed',
    color: 'success'
  },
  test: {
    text: 'Test',
    color: 'info'
  },
  insufficient: {
    text: 'Insufficient',
    color: 'info'
  },
  failed: {
    text: 'Failed',
    color: 'error'
  },
  // eslint-disable-next-line no-useless-computed-key
  ['in-progress']: {
    text: 'In Progress',
    color: 'warning'
  },
  success: {
    text: 'Success',
    color: 'success'
  }
};

function InsufficientTransactionTable({ options }) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const insufficientTransactions = useSelector(
    state => state.transaction.insufficientTransactions
  );

  useEffect(() => {
    dispatch(getOrderFailed(options));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = [
    {
      key: 'date',
      title: 'Date',
      align: 'left',
      width: 120,
      renderCell: (createdAt, record) => {
        return (
          <>
            {moment(createdAt).format('DD.MM.YYYY')} <br />
            {moment(createdAt).format('HH:mm:ss')}
          </>
        );
      }
    },
    {
      key: 'coin',
      title: 'Coin',
      renderCell: coin => {
        return (
          <div className={classes.coinCell}>
            <span className={classes.coinIcon}>{COINS_ICONS[coin]}</span>
            <Typography
              variant="inherit"
              className={classes.coinLabel}
              color="textPrimary"
            >
              {coin}
            </Typography>
          </div>
        );
      }
    },
    {
      key: 'fromExchange',
      title: 'From',
      renderCell: (exchange, record) => (
        <div className={classes.exchangeCell}>{EXCHANGES_LOGO[exchange]}</div>
      )
    },
    {
      key: 'toExchange',
      title: 'From',
      renderCell: (exchange, record) => (
        <div className={classes.exchangeCell}>{EXCHANGES_LOGO[exchange]}</div>
      )
    },
    {
      key: 'quantity',
      title: 'Quantity'
    },
    {
      key: 'earning',
      title: 'Earning'
    }
  ];

  return (
    <DataTable
      columns={columns}
      source={insufficientTransactions}
      idProp="vid"
    />
  );
}

export default InsufficientTransactionTable;
