/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_ALL_MACHINES,
  GET_ALL_DROPLETS,
  PING_MACHINE_ACTION_TYPE,
  UPDATED_MACHINE
} from '../actions/machineAction';

const initialState = {
  list: {},
  droplets: []
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MACHINES: {
      return produce(state, draft => {
        action.payload.forEach(machine => {
          draft.list[machine.id] = machine;
        });
      });
    }

    case GET_ALL_DROPLETS: {
      return produce(state, draft => {
        draft.droplets = action.payload;
      });
    }

    case PING_MACHINE_ACTION_TYPE: {
      return produce(state, draft => {
        if (action.data.machineId && state.list[action.data.machineId]) {
          draft.list[action.data.machineId] = {
            ...state.list[action.data.machineId],
            pingLastDate: action.data.date
          };
        }
      });
    }

    case UPDATED_MACHINE: {
      return produce(state, draft => {
        if (action.payload.id && state.list[action.payload.id]) {
          draft.list[action.payload.id] = {
            ...state.list[action.payload.id],
            ...action.payload
          };
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
