/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  GET_ALL_TRANSACTIONS,
  GET_ALL_ORDER_FAILED,
  ADD_NEW_TRANSACTION,
  GET_EARNING,
  GET_EARNING_COINS,
  GET_ALL_FAIL_TRANSACTIONS,
  INITIAL_INSUFFICIENTS,
  ADD_INSUFFICIENT
} from '../actions/transactionActions';

const initialState = {
  list: [],
  errorList: [],
  listOptions: {},
  orderFailed: [],
  insufficientTransactions: [],
  reports: {
    earnings: {},
    earningCoins: {}
  }
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS: {
      return produce(state, draft => {
        const { result, ...restOptions } = action.payload;
        draft.list = result;

        draft.listOptions = restOptions;
      });
    }

    case INITIAL_INSUFFICIENTS: {
      return produce(state, draft => {
        draft.insufficientTransactions = action.payload.reverse();
      });
    }

    case ADD_INSUFFICIENT: {
      return produce(state, draft => {
        if (draft.insufficientTransactions.length === 100) {
          draft.insufficientTransactions.unshift();
        }
        draft.insufficientTransactions = [
          action.payload,
          ...draft.insufficientTransactions
        ];
      });
    }

    case GET_ALL_FAIL_TRANSACTIONS: {
      return produce(state, draft => {
        const { result, ...restOptions } = action.payload;
        draft.errorList = result;

        draft.listOptions = restOptions;
      });
    }

    case GET_ALL_ORDER_FAILED: {
      return produce(state, draft => {
        const { result, ...restOptions } = action.payload;
        draft.orderFailed = result;

        draft.listOptions = restOptions;
      });
    }

    case ADD_NEW_TRANSACTION: {
      return produce(state, draft => {
        if (draft.listOptions?.page === 1) {
          draft.list.pop();
          draft.list = [action.payload, ...draft.list];
          if (draft.reports.earnings[action.payload.earningCurrency]) {
            draft.reports.earnings[action.payload.earningCurrency] +=
              action.payload.earning;
          }
        }
      });
    }

    case GET_EARNING: {
      return produce(state, draft => {
        const earnings = {};
        action.payload.forEach(e => {
          earnings[e.currency] = e.earning;
        });
        draft.reports.earnings = earnings;
      });
    }

    case GET_EARNING_COINS: {
      return produce(state, draft => {
        const earnings = {};
        action.payload.forEach(e => {
          earnings[e.coin] = e.earnings;
        });
        draft.reports.earningCoins = earnings;
      });
    }

    default: {
      return state;
    }
  }
};

export default transactionReducer;
