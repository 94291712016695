import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MinorCoinEnum } from 'src/enums/common';
import _ from 'lodash';
import { useCoins } from 'src/hooks/useCoins';
export const UserRoles = {
  DEVELOPER: 'developer',
  MAINTAINER: 'maintainer',
  ADMIN: 'admin',
  STANDARD: 'standard',
  REPORTER: 'reporter'
};

const Permission = ({ roles = [], children }) => {
  const selectedRole = useSelector(state => state.setting.selectedRole);
  return <>{roles.includes(selectedRole) && children}</>;
};

export default Permission;

export const useFilteredCoin = (all = false, arrayResult = false) => {
  const [coins, setCoins] = useState(arrayResult ? [] : {});
  const selectedRole = useSelector(state => state.setting.selectedRole);

  const { coins: filteredCoin } = useCoins();
  useEffect(() => {
    if (all) {
      if (arrayResult) {
        setCoins([
          ...Object.keys(MinorCoinEnum),
          ..._.sortBy(Object.keys(filteredCoin))
        ]);
      } else {
        setCoins({ ...MinorCoinEnum, filteredCoin });
      }
    } else {
      if (arrayResult) {
        setCoins(_.sortBy(Object.keys(filteredCoin)));
      } else {
        setCoins(filteredCoin);
      }
    }
  }, [selectedRole, all, arrayResult, filteredCoin]);
  return coins;
};
