/* eslint class-methods-use-this: "error" */
import io from 'socket.io-client';
import { store } from 'src';
import {
  INITIAL_BALANCES,
  UPDATE_BALANCES
} from 'src/store/actions/balancesActions';
import { ADD_INSUFFICIENT } from 'src/store/actions/transactionActions';
import {
  UPDATE_PARIBU_STATUS,
  UPDATE_KOINIM_STATUS,
  UPDATE_COINZO_STATUS
} from 'src/store/actions/jobsActions';
import { PanelSocketEvents } from '@cnbot/common';
import { UPDATE_COMPUTE_STATUS } from 'src/store/actions/settingActions';
// import { v1 as uuidv4 } from 'uuid';

export const SocketEvents = PanelSocketEvents;

class SocketUtil {
  close() {
    console.log('closing!!');
    this.socket && this.socket.close();
  }

  open() {
    console.log('opening!!');
    this.socket && this.socket.open();
  }

  connect() {
    // let socketBaseUril = '/';
    let socketBaseUril = 'https://v2.qtab.app';
    if (process.env.NODE_ENV !== 'production') {
      // socketBaseUril = 'http://localhost:1009';
    }
    this.socket = io(socketBaseUril, {
      query: {},
      transports: ['websocket']
    });

    this.socket.on('connect', this.onConnected);
    this.socket.on('disconnect', this.onDisconnected);
    this.events = {};

    this.socket.on(PanelSocketEvents.InitialConnectedClient, this.connected);
    this.socket.on(PanelSocketEvents.Ping, this.ping);
    this.socket.on(PanelSocketEvents.Balance, this.updatedBalance);
    this.socket.on(PanelSocketEvents.ParibuStatus, this.updateParibuStatus);
    this.socket.on(PanelSocketEvents.KoinimStatus, this.updateKoinimStatus);
    this.socket.on(PanelSocketEvents.CoinzoStatus, this.updateCoinzoStatus);
    this.socket.on(
      PanelSocketEvents.UpdatedComputeStatus,
      this.updatedComputeStatus
    );
    this.socket.on(
      PanelSocketEvents.InsufficientTransaction,
      this.updateInsufficientTransaction
    );
    this.socket.on(`exchange.status`, payload => {
      switch (payload.exchange) {
        case 'paribu':
          store.dispatch({
            type: UPDATE_PARIBU_STATUS,
            status: payload
          });
          break;
        case 'koinim':
          store.dispatch({
            type: UPDATE_KOINIM_STATUS,
            status: payload
          });
          break;
        case 'coinzo':
          store.dispatch({
            type: UPDATE_COINZO_STATUS,
            status: payload
          });
          break;
        default:
      }
    });
  }

  subscribe(key, fn) {
    if (this.socket && !this.events[key]) {
      this.socket.on(key, fn);
      this.events[key] = fn;
    }
  }

  unsubscribe(key, fn) {
    if (this.socket && this.events[key]) {
      this.socket.removeListener(key, fn);
      delete this.events[key];
    }
  }

  allUnsubscribe() {
    if (this.socket) {
      const events = Object.keys(this.events);
      events.forEach(event => {
        this.socket.removeListener(event, this.events[event]);
      });
    }
  }

  getSocket() {
    return this.socket;
  }

  onConnected = () => {
    console.log('connected!');
  };

  updatedBalance = data => {
    store.dispatch({
      type: UPDATE_BALANCES,
      exchange: data.exchange,
      balances: data.balances
    });
  };

  updatedComputeStatus = data => {
    store.dispatch({
      type: UPDATE_COMPUTE_STATUS,
      payload: {
        value: data.isEnable
      }
    });
  };

  updateParibuStatus = data => {
    store.dispatch({
      type: UPDATE_PARIBU_STATUS,
      isParibuEnabled: data.isEnabled
    });
  };

  updateKoinimStatus = data => {
    store.dispatch({
      type: UPDATE_KOINIM_STATUS,
      isKoinimEnabled: data.isEnabled
    });
  };

  updateCoinzoStatus = data => {
    store.dispatch({
      type: UPDATE_COINZO_STATUS,
      isCoinzoEnabled: data.isEnabled
    });
  };

  updateInsufficientTransaction = data => {
    store.dispatch({
      type: ADD_INSUFFICIENT,
      payload: data
    });
  };

  connected = data => {
    store.dispatch({
      type: INITIAL_BALANCES,
      balances: data.balances
    });

    store.dispatch({
      type: UPDATE_PARIBU_STATUS,
      status: data.paribuStatus
    });

    store.dispatch({
      type: UPDATE_KOINIM_STATUS,
      status: data.koinimStatus
    });

    store.dispatch({
      type: UPDATE_COINZO_STATUS,
      status: data.coinzoStatus
    });

    store.dispatch({
      type: UPDATE_COMPUTE_STATUS,
      payload: {
        value: data.computeStatus.status
      }
    });
  };

  onDisconnected = () => {
    console.log('disconnected!');
  };

  sendMessage = (event, data = {}) => {
    this.socket.emit(event, data);
  };
}

export default new SocketUtil();
