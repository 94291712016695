import React from 'react';

import {
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Avatar,
  makeStyles
} from '@material-ui/core';

import { getCoinLogo } from 'src/enums/common';

import { COINS_ICONS } from 'src/enums/common';
import { formatPrice } from 'src/utils/format';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  coinCell: {
    display: 'flex',
    alignItems: 'center'
  },
  coinLogo: {
    display: 'inline-block',
    width: 21,
    height: 21
  },
  coinIcon: {
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  coinLabel: {
    display: 'inline-block',
    marginLeft: 10
  },
  stickyCol: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.default
  }
}));

function CoinItem({ coin, exchanges, balances }) {
  const classes = useStyles();
  const unavailableBalance = useSelector(
    state => state.balances.unavailableBalance
  );
  if (!balances && Object.keys(balances).length === 0) {
    return null;
  }

  let total = 0;
  // eslint-disable-next-line
  let used = 0;
  let free = 0;

  return (
    <TableRow hover key={coin}>
      <TableCell
        style={{ paddingBottom: 5, paddingTop: 5 }}
        className={classes.stickyCol}
      >
        <div className={classes.coinCell}>
          {COINS_ICONS[coin] ? (
            <span className={classes.coinIcon}>{COINS_ICONS[coin]}</span>
          ) : (
            <Avatar
              alt={coin}
              className={classes.coinLogo}
              src={getCoinLogo(coin)}
            />
          )}

          <Typography
            variant="inherit"
            className={classes.coinLabel}
            color="textPrimary"
          >
            {coin === 'ETH_UNAVAILABLE' ? 'ETH' : coin}
            {coin === 'ETH_UNAVAILABLE' && (
              <Label color="error">{unavailableBalance?.ETH}</Label>
            )}
          </Typography>
        </div>
      </TableCell>
      {exchanges.map(exchange => {
        const balance = balances[exchange];

        if (!balance.coinBalance || !balance?.minorCoinBalance) {
          return null;
        }

        const budgetOfCoin =
          balance?.coinBalance[coin] || balance?.minorCoinBalance[coin];
        total += budgetOfCoin?.total || 0;
        used += budgetOfCoin?.used || 0;
        free += budgetOfCoin?.free || 0;
        return (
          <TableCell
            key={exchange}
            align="right"
            style={{ paddingBottom: 5, paddingTop: 5 }}
          >
            <Typography variant="caption" color="textPrimary">
              {formatPrice(
                budgetOfCoin?.total && budgetOfCoin.total > 0
                  ? budgetOfCoin.total
                  : 0
              )}
            </Typography>
            <Tooltip title={budgetOfCoin?.used.toFixed(2) || 0}>
              <Typography variant="h6" color="textPrimary">
                {formatPrice(
                  budgetOfCoin?.free && budgetOfCoin.free > 0
                    ? budgetOfCoin.free
                    : 0
                )}
              </Typography>
            </Tooltip>
          </TableCell>
        );
      })}
      <TableCell align="right" style={{ paddingBottom: 5, paddingTop: 5 }}>
        <Typography variant="caption" color="textPrimary">
          {formatPrice(total, coin === 'BTC' ? 4 : 2)}
        </Typography>
        <Typography variant="h6" color="textPrimary">
          {formatPrice(free, coin === 'BTC' ? 4 : 2)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default CoinItem;
