import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  Drawer,
  IconButton,
  Divider,
  SvgIcon,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { XCircle as XIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  editJob,
  getAllJobs,
  resetJobDetail
} from 'src/store/actions/jobsActions';
import { fetchAllMachine } from 'src/store/actions/machineAction';
import { formatterFormParity } from './helper';
import ParitySelection from './ParitySelection';

import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  }
}));

function EditJob({ open = false, onClose, job }) {
  const [isOpen, setOpen] = useState(open);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const accounts = useSelector(state => state.accounts.list);
  const jobDetail = useSelector(state => state.jobs.jobDetail);

  useEffect(() => {
    return () => {
      dispatch(resetJobDetail());
    };
  }, [dispatch]);

  const convertFormParties = parities => {
    const newParties = {};
    parities.forEach(e => {
      const replacedParity = e.replace('parity.', '');
      newParties[replacedParity] = [replacedParity];
    });
    return newParties;
  };

  const formik = useFormik({
    initialValues: {
      ...jobDetail,
      parities: convertFormParties(jobDetail.parities),
      accounts: jobDetail.accounts.map(e => e.id)
    },

    onSubmit: async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      const parities = formatterFormParity(
        values.parities,
        values.accounts.map(id => accounts[id].exchange)
      );

      try {
        const requestData = { accounts: values.accounts, parities };

        await editJob(jobDetail.id, requestData);
        await dispatch(fetchAllMachine());
        await dispatch(getAllJobs());
        enqueueSnackbar(`Edit the ${values.coin} Job!`, {
          variant: 'success'
        });
        onClose();
      } catch (error) {
        enqueueSnackbar(`An error is occured!`, {
          variant: 'error'
        });
        console.log(error);
        setStatus({ success: false });
        setErrors({ submit: error.message });
      }
    }
  });

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const isSelectedAccount = useCallback(
    id => {
      return formik.values?.accounts?.indexOf(id) > -1;
    },
    [formik]
  );

  const generatedDisplaySelectedAccounts = useCallback(
    selectedAccounts => {
      return selectedAccounts
        .map(selectedAccountId => accounts[selectedAccountId].exchange)
        .join(', ');
    },
    [accounts]
  );

  console.log(formik.values);

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
      classes={{ paper: classes.drawer }}
    >
      {jobDetail && (
        <form onSubmit={formik.handleSubmit}>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4" color="textPrimary">
                  Edit Job
                </Typography>
                <IconButton onClick={onClose}>
                  <SvgIcon fontSize="small">
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
              <Divider />
              <Box mt={2}>
                <form>
                  <Box p={2}>
                    <Grid container spacing={3}>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Coin"
                          name="coin"
                          value={formik.values.coin}
                          disabled
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel id="edit-checkbox-label">
                            Accounts
                          </InputLabel>
                          <Select
                            labelId="edit-checkbox-label"
                            id="edit-checkbox"
                            name="accounts"
                            value={formik.values?.accounts || []}
                            renderValue={generatedDisplaySelectedAccounts}
                            onChange={formik.handleChange}
                            multiple
                          >
                            {Object.values(accounts).map(account => (
                              <MenuItem key={account.id} value={account.id}>
                                <Checkbox
                                  checked={isSelectedAccount(account.id)}
                                />
                                <ListItemText primary={account.exchange} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <ParitySelection
                    formik={formik}
                    accounts={Object.values(accounts)}
                  />
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                    >
                      Edit Job
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </PerfectScrollbar>
        </form>
      )}
    </Drawer>
  );
}

export default EditJob;
