import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { routesPaths } from 'src/Routes';
import { Card, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import DataTable from 'src/components/DataTable';
import { useHistory, useParams } from 'react-router';
import Label from 'src/components/Label';
import { getTransactionDetail } from 'src/store/actions/transactionActions';
import moment from 'moment';

import { getTransactionStatusType } from './components/TransactionsTable';

import { COINS_ICONS, EXCHANGES_LOGO } from 'src/enums/common';
import { formatPrice } from 'src/utils/format';
import { useSelector } from 'react-redux';

export const transactionStatus = {
  completed: {
    text: 'Completed',
    color: 'success'
  },
  canceled: {
    text: 'Canceled',
    color: 'info'
  },
  insufficient: {
    text: 'Insufficient',
    color: 'info'
  },
  failed: {
    text: 'Failed',
    color: 'error'
  },
  // eslint-disable-next-line no-useless-computed-key
  ['in-progress']: {
    text: 'In Progress',
    color: 'warning'
  },
  pending: {
    text: 'Pending',
    color: 'warning'
  },
  success: {
    text: 'Success',
    color: 'success'
  }
};

export const orderTypeStatus = {
  buy: {
    text: 'BUY',
    color: 'success'
  },
  sell: {
    text: 'SELL',
    color: 'error'
  }
};

function getStatusType(statusType) {
  const { text, color } = transactionStatus[statusType];

  return <Label color={color}>{text}</Label>;
}

function getOrderType(statusType) {
  const { text, color } = orderTypeStatus[statusType];

  return <Label color={color}>{text}</Label>;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  exchangeImage: {
    height: 15,
    marginBottom: 10
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    display: 'flex',
    '& > svg': {
      width: 24,
      height: 24
    }
  }
}));

function TransactionDetail() {
  const classes = useStyles();
  const params = useParams();
  const [transaction, setTransaction] = useState();
  const history = useHistory();
  const roleRatio = useSelector(state => state.setting.selectedRatio);
  const userRole = useSelector(state => state.setting.selectedRole);

  const fetchTransactionDetail = async () => {
    const transactionDetailResponse = await getTransactionDetail(params.id);
    setTransaction(transactionDetailResponse);
  };

  if (userRole === 'report' || userRole === 'standard') {
    setTimeout(() => {
      history.push('');
    }, 500);
  }

  const roundPrice = price => {
    return parseFloat(price * roleRatio);
  };

  useEffect(() => {
    fetchTransactionDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Transactions',
      link: routesPaths.transactions
    },
    {
      label: 'Transaction Detail'
    }
  ];

  const columns = [
    {
      key: 'side',
      title: 'Side',
      renderCell: side => getOrderType(side)
    },
    {
      key: 'price',
      title: 'To Price'
    },
    {
      key: 'quantity',
      title: 'Quantity'
    },
    {
      key: 'earning',
      title: 'Earning',
      align: 'right',
      renderCell: e => roundPrice(e)
    },
    {
      key: 'earningCurrency',
      title: ''
    },
    {
      key: 'createdAt',
      title: ''
    },
    {
      key: 'status',
      title: 'Status',
      align: 'right',
      width: 200,
      renderCell: (status, row) => {
        return (
          <>
            {status === 'failed' ? (
              <div>
                {getStatusType(status)}
                <br />
                <Typography variant="body2" color="textPrimary">
                  {row?.orderErrorMessage}
                </Typography>
              </div>
            ) : (
              getStatusType(status)
            )}
          </>
        );
      }
    }
  ];

  const renderBalance = balance => {
    return (
      <>
        <Typography variant="caption" color="textPrimary">
          {formatPrice(balance?.total) || 0}
        </Typography>
        <Tooltip title={balance?.used.toFixed(2) || 0}>
          <Typography variant="h6" color="textPrimary">
            {formatPrice(balance?.free) || 0}
          </Typography>
        </Tooltip>
      </>
    );
  };

  const balancesColumns = [
    {
      key: 'coin',
      title: 'Coin',
      renderCell: coin => (
        <div className={classes.icon}>{COINS_ICONS[coin]}</div>
      )
    },
    {
      key: 'fromExchange',
      title: (
        <>
          {EXCHANGES_LOGO[transaction?.fromExchange] ||
            transaction?.fromExchange}
          <br />
          {Array.isArray(transaction?.logs.balances)
            ? transaction?.logs.balances.find(
                e => e.exchange === transaction?.fromExchange
              )?.date
            : transaction?.logs.balances[transaction?.fromExchange].date}
        </>
      ),
      renderCell: renderBalance
    },
    {
      key: 'toExchange',
      title: (
        <>
          {EXCHANGES_LOGO[transaction?.toExchange] || transaction?.toExchange}
          <br />
          {Array.isArray(transaction?.logs.balances)
            ? transaction?.logs.balances.find(
                e => e.exchange === transaction?.toExchange
              )?.date
            : transaction?.logs.balances[transaction?.toExchange].date}
        </>
      ),
      renderCell: renderBalance
    }
  ];

  const mappedBalance = () => {
    if (transaction) {
      const { balances } = transaction.logs;

      if (!balances) return [];

      const fromBalances = Array.isArray(transaction?.logs.balances)
        ? transaction?.logs.balances.find(
            e => e.exchange === transaction?.fromExchange
          )
        : transaction?.logs.balances[transaction?.fromExchange];

      // const fromBalances = balances.find(
      //   balance => balance.exchange === transaction.fromExchange
      // );

      console.log('fromBalances', fromBalances);

      const toBalances = Array.isArray(transaction?.logs.balances)
        ? transaction?.logs.balances.find(
            e => e.exchange === transaction?.toExchange
          )
        : transaction?.logs.balances[transaction?.toExchange];

      // const toBalances = balances.find(
      //   balance => balance.exchange === transaction.toExchange
      // );

      let coins = [];
      coins.push({
        coin: transaction.coin,
        fromExchange: fromBalances.coinBalance[transaction.coin],
        toExchange: toBalances.coinBalance[transaction.coin]
      });

      coins.push({
        coin: 'TRY',
        fromExchange: fromBalances.minorCoinBalance.TRY,
        toExchange: toBalances.minorCoinBalance.TRY
      });

      coins.push({
        coin: 'USDT',
        fromExchange: fromBalances.minorCoinBalance.USDT,
        toExchange: toBalances.minorCoinBalance.USDT
      });

      return coins;
    }

    return [];
  };

  const columnsBids = [
    {
      key: 'price',
      title: 'Price'
    },
    {
      key: 'quantity',
      title: 'Quantity'
    },
    {
      key: 'total',
      title: 'Total'
    }
  ];

  mappedBalance();

  return (
    <Page
      className={classes.root}
      title="Transaction Detail"
      breadcrumbs={breadcrumbs}
    >
      <Card style={{ marginBottom: 20 }}>
        <Grid alignItems="center" container justify="space-between">
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              Coin
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3" color="textPrimary">
                {transaction?.coin}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {transaction &&
                  moment(transaction.createdAt).format('DD.MM.YYYY')}
                <br />
                {transaction &&
                  moment(transaction.createdAt).format('HH:mm:ss')}
              </Typography>
              {transaction && getTransactionStatusType(transaction.status)}
            </div>
          </Grid>
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              From Exchange (To)
            </Typography>
            <div className={classes.valueContainer}>
              <div>
                <div className={classes.exchangeImage}>
                  {EXCHANGES_LOGO[transaction?.fromExchange]}
                </div>
              </div>
              <Typography variant="body2" color="textPrimary">
                {transaction?.fromPair}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {transaction?.commissionRate[0]}
              </Typography>
            </div>
          </Grid>
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              To Exchange (Sell)
            </Typography>
            <div className={classes.valueContainer}>
              <div className={classes.exchangeImage}>
                {EXCHANGES_LOGO[transaction?.toExchange]}
              </div>
              <Typography variant="body2" color="textPrimary">
                {transaction?.toPair}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {transaction?.commissionRate[1]}
              </Typography>
            </div>
          </Grid>
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              Earning
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3" color="textPrimary">
                {roundPrice(transaction?.earning).toFixed(2)}{' '}
                {transaction &&
                  transaction.earningCurrency &&
                  COINS_ICONS[transaction.earningCurrency]}
              </Typography>
              {/* <Label className={classes.label} color="success">
                +25%
              </Label> */}
            </div>
          </Grid>
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              Quantity
            </Typography>{' '}
            <div className={classes.valueContainer}>
              <Typography variant="body1" color="textPrimary">
                {transaction?.orders[0]?.quantity || '-'}
              </Typography>
              {/* <Label className={classes.label} color="success">
                +25%
              </Label> */}
            </div>
          </Grid>
          <Grid className={classes.item} item md={2} sm={6} xs={12}>
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
              color="textSecondary"
            >
              Ticker (Ask - Bid)
            </Typography>

            <div className={classes.valueContainer}>
              <Typography variant="body1" color="textPrimary">
                {transaction && transaction.logs?.tickers?.pair}
                <br />
                {transaction && transaction.logs?.tickers?.buy} -{' '}
                {transaction && transaction.logs?.tickers?.sell}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={3}>
        <Grid item lg={3} xl={4} xs={12}>
          <DataTable
            head="Ask (Buy)"
            columns={columnsBids}
            idProp="price"
            source={
              transaction?.logs?.books?.ask
                ? [transaction?.logs?.books?.ask]
                : []
            }
          />
          <br />
          <DataTable
            head="Bids (Sell)"
            idProp="price"
            columns={columnsBids}
            source={transaction?.logs?.books?.bids || []}
          />
        </Grid>
        <Grid item lg={9} xl={8} xs={12}>
          <DataTable
            head="Orders"
            columns={columns}
            source={transaction?.orders || []}
          />

          <br />

          <DataTable
            head="Balances"
            idProp="coin"
            columns={balancesColumns}
            source={mappedBalance()}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default TransactionDetail;
