import React, { useRef, useState } from 'react';
import {
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { MoreVertical as MoreIcon } from 'react-feather';
import Permission from './Permission';

function Item({ item, onSelect }) {
  return (
    <MenuItem
      key={item.key || item.label}
      onClick={() =>
        onSelect ? onSelect(item.key) : item.onClick && item.onClick()
      }
      disabled={item.disabled}
    >
      <ListItemIcon>
        <SvgIcon fontSize="small">{item.icon}</SvgIcon>
      </ListItemIcon>
      <ListItemText primary={item.label} />
    </MenuItem>
  );
}

export default function ActionMenu({ menuList = [], onSelect }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const moreRef = useRef(null);

  return (
    <>
      <IconButton onClick={() => setIsMenuOpen(true)} ref={moreRef}>
        <SvgIcon fontSize="small">
          <MoreIcon />
        </SvgIcon>
      </IconButton>

      <Menu
        anchorEl={moreRef.current}
        keepMounted
        elevation={1}
        onClose={() => setIsMenuOpen(false)}
        open={isMenuOpen}
      >
        {menuList.map(menuItem => {
          if (menuItem.permission) {
            return (
              <Permission key={menuItem.key} roles={menuItem.permission}>
                <Item item={menuItem} onSelect={onSelect} />
              </Permission>
            );
          }

          return (
            <Item key={menuItem.key} item={menuItem} onSelect={onSelect} />
          );
        })}
      </Menu>
    </>
  );
}
