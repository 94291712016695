import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Grid,
  Drawer,
  IconButton,
  FormHelperText,
  Divider,
  SvgIcon,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { XCircle as XIcon } from 'react-feather';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { CoinEnum } from '@cnbot/common';
import wait from 'src/utils/wait';
import { createJob, getAllJobs } from 'src/store/actions/jobsActions';
import { fetchAllMachine } from 'src/store/actions/machineAction';
import ParitySelection from './ParitySelection';
import { formatterFormParity } from './helper';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  }
}));

const newJobValidationSchema = Yup.object().shape({
  coin: Yup.string().required('Required'),
  accounts: Yup.array(Yup.string()).min(
    2,
    'You should select 2 accounts at least'
  )
});

function NewJob({ open = false, onClose }) {
  const [isOpen, setOpen] = useState(open);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const accounts = useSelector(state => state.accounts.list);
  const selectedCoins = Object.values(
    useSelector(state => state.jobs.coins)
  ).map(e => e.coin);

  const selecteableCoins = Object.values(CoinEnum).filter(
    coin => !selectedCoins.includes(coin)
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const formik = useFormik({
    initialValues: { coin: selecteableCoins[0], accounts: [], parities: {} },
    validationSchema: newJobValidationSchema,
    onSubmit: async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      const parities = formatterFormParity(
        values.parities,
        values.accounts.map(id => accounts[id].exchange)
      );
      try {
        await createJob({ ...values, parities });
        await dispatch(fetchAllMachine());
        await dispatch(getAllJobs());

        resetForm();
        setStatus({ success: true });
        await wait(300);

        onClose();
        enqueueSnackbar(`Created a Job for the ${values.coin}`, {
          variant: 'success'
        });
      } catch (error) {
        console.log(error);
        setStatus({ success: false });
        setErrors({ submit: error.message });
      }
    }
  });

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      classes={{ paper: classes.drawer }}
      anchor="right"
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              New Job
            </Typography>
            <IconButton onClick={onClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Divider />
          <Box mt={2}>
            {open && (
              <form onSubmit={formik.handleSubmit}>
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          formik.touched.coin && formik.errors.coin
                        )}
                        helperText={formik.touched.coin && formik.errors.coin}
                        size="small"
                        label="Coin"
                        name="coin"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={formik.values.coin}
                        variant="outlined"
                      >
                        {selecteableCoins.map(coin => (
                          <option key={coin} value={coin}>
                            {coin}
                          </option>
                        ))}
                        <option key="ARB" value="ARB">
                          ARB
                        </option>
                      </TextField>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        size="small"
                      >
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Account
                        </InputLabel>
                        <Select
                          error={Boolean(
                            formik.touched.accounts && formik.errors.accounts
                          )}
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          name="accounts"
                          value={formik.values.accounts}
                          onChange={formik.handleChange}
                          renderValue={selected => {
                            return selected
                              .map(
                                selectedItem =>
                                  Object.values(accounts).find(
                                    account => account.id === selectedItem
                                  ).exchange
                              )
                              .join(', ');
                          }}
                        >
                          {Object.values(accounts).map(account => (
                            <MenuItem key={account.id} value={account.id}>
                              <Checkbox
                                checked={
                                  formik.values.accounts.indexOf(account.id) >
                                  -1
                                }
                              />
                              <ListItemText primary={account.exchange} />
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.accounts && formik.errors.accounts && (
                          <FormHelperText error>
                            {formik.errors.accounts}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {formik.errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      {formik.errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <ParitySelection
                  formik={formik}
                  accounts={Object.values(accounts)}
                />
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Button
                    color="secondary"
                    disabled={
                      formik.isSubmitting ||
                      formatterFormParity(
                        formik.values.parities,
                        formik.values.accounts.map(id => accounts[id].exchange)
                      ).length < 2
                    }
                    type="submit"
                    variant="contained"
                  >
                    Create Job
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default NewJob;
