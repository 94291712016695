import React from 'react';
import {
  Box,
  Button,
  IconButton,
  SvgIcon,
  makeStyles,
  Collapse
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { X as CloseIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));

function ConfirmButton({
  buttonTitle = 'Delete',
  title = 'Warning',
  content = 'Do you approve the delete?',
  variant = 'outlined',
  color = 'white',
  disabled = false,
  confirmAction
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleClose();
    confirmAction && confirmAction(handleOpenError);
  };

  const handleOpenError = () => {
    setError(true);
  };

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <div className={classes.root}>
      <Box paddingRight={2} display="flex" justifyContent="flex-end">
        <Button
          color={color}
          disabled={disabled}
          variant={variant}
          onClick={handleOpen}
        >
          {buttonTitle}
        </Button>
      </Box>
      <Collapse in={open}>
        <Box marginLeft={2} marginRight={2}>
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={handleConfirm}>
                  CONFIRM
                </Button>
                <IconButton color="inherit" onClick={handleClose}>
                  <SvgIcon fontSize="small">
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              </>
            }
          >
            <AlertTitle>{title}</AlertTitle>
            {content}
          </Alert>
        </Box>
      </Collapse>
      <Collapse in={error}>
        <Box m={2} marginTop={0}>
          <Alert severity="error" onClose={handleCloseError}>
            API: Occurred an error!
          </Alert>
        </Box>
      </Collapse>
    </div>
  );
}

export default ConfirmButton;
