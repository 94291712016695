import api from 'src/enums/api';
import httpService from 'src/utils/httpService';

export const GET_ACCOUNTS = '@CNBOT/get-accounts';

export function fetchAllAccounts() {
  const request = httpService.makeRequest('get', api.account);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ACCOUNTS,
        payload: response
      })
    );
  };
}

export function createNewAccount(account) {
  const request = httpService.makeRequest('post', api.account, account);

  return dispatch => {
    request.then(response => dispatch(fetchAllAccounts()));
  };
}

export function updateAccount(id, account) {
  const request = httpService.makeRequest(
    'put',
    `${api.account}/${id}`,
    account
  );

  return dispatch => {
    request.then(response => dispatch(fetchAllAccounts()));
  };
}

export function deleteAccount(id) {
  const request = httpService.makeRequest('delete', `${api.account}/${id}`);

  return dispatch => {
    request.then(response => dispatch(fetchAllAccounts()));
  };
}
