/* eslint-disable react/no-array-index-key */
import React, { Suspense, Fragment, useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import SettingLayout from './layouts/SettingLayout';
import { useAuth0 } from '@auth0/auth0-react';
import TransferLayout from './layouts/TransferLayout';
import AssetsLayout from './layouts/AssetsLayout';
import DashboardPage from 'src/views/dashboard/Dashboard';
import TransactionPage from 'src/views/transactions/Transactions';
import TransactionDetailPage from 'src/views/transactions/TransactionDetail';
import ReportPage from 'src/views/graphs/Graphs';
import JobsPage from 'src/views/jobs/Jobs';
import AccountsPage from 'src/views/settings/Accounts';
import ConfigurationPage from 'src/views/settings/Configuration';
import BalancesPage from 'src/views/balances/BalancesPage';
import TransferDashboardPage from 'src/views/transfer/Dashboard';
import TransferJobsPage from 'src/views/transfer/Jobs';
import TransferAddressPage from 'src/views/transfer/Addresses';
import NotFoundPage from 'src/views/error/NotFound';

const LogoutComponent = () => {
  const { logout } = useAuth0();
  const init = async () => {
    await logout({ returnTo: window.location.origin });
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 500);
  };
  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <div />;
};

export const routesPaths = {
  app: '/app',
  logout: '/logout',
  dashboard: '/app/dashboard',
  reports: '/app/reports',
  transactions: '/app/transactions',
  transactionDetail: '/app/transaction/:id/detail',
  jobs: '/app/jobs',
  mining: '/app/mining',
  settings: {
    root: '/settings',
    accounts: '/settings/accounts',
    configuration: '/settings/configuration'
  },
  transfer: {
    root: '/transfer',
    jobs: '/transfer/jobs',
    address: '/transfer/address'
  },
  assets: {
    root: '/assets',
    balances: '/assets/balances',
    books: '/assets/books',
    tickers: '/assets/tickers'
  }
};

const routesConfig = [
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: DashboardPage
  },
  {
    exact: true,
    path: routesPaths.logout,
    component: LogoutComponent
  },
  {
    path: routesPaths.app,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: routesPaths.app,
        component: () => <Redirect to={routesPaths.dashboard} />
      },
      {
        exact: true,
        path: routesPaths.dashboard,
        component: DashboardPage
      },
      {
        exact: true,
        path: routesPaths.transactions,
        component: TransactionPage
      },
      {
        exact: true,
        path: routesPaths.transactionDetail,
        component: TransactionDetailPage
      },
      {
        exact: true,
        path: routesPaths.reports,
        component: ReportPage
      },
      {
        exact: true,
        path: routesPaths.jobs,
        component: JobsPage
      }
    ]
  },
  {
    path: routesPaths.settings.root,
    guard: AuthGuard,
    layout: SettingLayout,
    routes: [
      {
        exact: true,
        path: routesPaths.settings.accounts,
        component: AccountsPage
      },
      {
        exact: true,
        path: routesPaths.settings.configuration,
        component: ConfigurationPage
      }
    ]
  },
  {
    path: routesPaths.assets.root,
    guard: AuthGuard,
    layout: AssetsLayout,
    routes: [
      {
        exact: true,
        path: routesPaths.assets.balances,
        component: BalancesPage
      }
    ]
  },
  {
    path: routesPaths.transfer.root,
    guard: AuthGuard,
    layout: TransferLayout,
    routes: [
      {
        exact: true,
        path: routesPaths.transfer.root,
        component: TransferDashboardPage
      },
      {
        exact: true,
        path: routesPaths.transfer.jobs,
        component: TransferJobsPage
      },
      {
        exact: true,
        path: routesPaths.transfer.address,
        component: TransferAddressPage
      }
    ]
  },
  {
    exact: true,
    path: '/*',
    component: NotFoundPage
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
