const checkAccountParity = (exchanges, parity) => {
  const check = exchanges.find(exchange => {
    return parity.includes(exchange);
  });
  return !!check;
};

export const formatterFormParity = (parity, exchanges) => {
  const keyOfParity = Object.keys(parity);

  return keyOfParity.filter(
    e => parity[e].length > 0 && checkAccountParity(exchanges, parity[e][0])
  );
};
