import React from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Divider,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { XCircle as XIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  }
}));

function DrawerPage({ open = false, title, onClose, children }) {
  const classes = useStyles();

  return (
    <Drawer
      onClose={onClose}
      open={open}
      classes={{ paper: classes.drawer }}
      anchor="right"
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Divider />
          {open && <Box mt={2}>{children}</Box>}
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default DrawerPage;
