/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_TRANSFER_HISTORY,
  GET_TRANSFER_JOBS,
  GET_ADDRESSES
} from '../actions/transferActions';

const initialState = {
  list: [],
  listOptions: {},
  jobs: {},
  addresses: {}
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSFER_HISTORY: {
      return produce(state, draft => {
        // const { result, ...restOptions } = action.payload;
        // console.log(action.payload);
        draft.list = action.payload.rows;
        draft.listOptions = action.payload.pagination;
      });
    }

    case GET_TRANSFER_JOBS: {
      return produce(state, draft => {
        draft.jobs = {};
        action.payload.forEach(job => {
          draft.jobs[job.id] = job;
        });
      });
    }

    case GET_ADDRESSES: {
      return produce(state, draft => {
        draft.addresses = {};
        action.payload.forEach(account => {
          draft.addresses[account.id] = account;
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
