import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import { CoinEnum } from 'src/enums/common';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../components/Permission';
import StackedBarChart from '../../../components/StackedBarChart';
import { calculateUsdtProfit } from '../../../utils/calculate-usdt-profit';

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: '100%'
  }
}));
const ticker = 33;

function CoinBasedEarningStackedBar({ source }) {
  const classes = useStyles();
  const userRole = useSelector(state => state.setting.selectedRole);
  const replaceCoins = useSelector(state => state.setting.replaceCoins);

  const [currency, setCurrency] = useState('usdt');
  const _coins = Object.keys(CoinEnum);
  _coins.pop();
  let coins = [];

  if (userRole === UserRoles.REPORTER || userRole === UserRoles.STANDARD) {
    const fakeCoins = Object.keys(replaceCoins);
    _coins.forEach(coin => {
      if (!fakeCoins.includes(coin)) {
        coins.push(coin);
      }
    });
  } else {
    coins = _coins;
  }

  coins = coins.sort();

  useEffect(() => {
    setCurrency('usdt');
  }, []);

  const getEarningByCoin = selectedCoin => {
    const item = source.coins.find(e => e.coin === selectedCoin);

    return calculateUsdtProfit(item?.usdt, item?.try, source?.ticker || ticker);
  };

  const getFeeByCoin = selectedCoin =>
    source.coins.find(e => e.coin === selectedCoin)?.transferFee || 0;

  const performance = {
    thisWeek: {
      data: [],
      labels: []
    },
    thisMonth: {
      data: [],
      labels: []
    },
    thisYear: {
      earnings: coins.map(coin => (source ? getEarningByCoin(coin) : 0)),
      fees: coins.map(count => (source ? getFeeByCoin(count) : 0)),
      labels: coins
    }
  };

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader
        title={`Earning of ${
          source?.date
            ? moment(source.date)
                .add(1, 'day')
                .format('DD.MM.YYYY')
            : 'All Days'
        }`}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box height={375}>
            <StackedBarChart
              className={classes.chart}
              earnings={performance.thisYear.earnings}
              fees={performance.thisYear.fees}
              labels={performance.thisYear.labels}
              currency={` ${currency.toUpperCase()}`}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

export default CoinBasedEarningStackedBar;
