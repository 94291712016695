import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Divider,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  XCircle as XIcon,
  RefreshCw as RefreshIcon,
  Power as ConnectIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import httpService from 'src/utils/httpService';
import api from 'src/enums/api';
import Label from 'src/components/Label';
import { Skeleton } from '@material-ui/lab';
import LineItem from 'src/components/LineItem';
import { useSelector } from 'react-redux';
import { MachineStatus } from '@cnbot/common';
import { getAllJobs } from 'src/store/actions/jobsActions';
import { useDispatch } from 'react-redux';
import { fetchAllMachine } from 'src/store/actions/machineAction';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  rowCell: {
    width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  title: {
    width: '100px'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    paddingTop: 20
  }
}));

const status = {
  [MachineStatus.Stopping]: 'warning',
  [MachineStatus.Starting]: 'info',
  [MachineStatus.Active]: 'success',
  [MachineStatus.Passive]: 'error'
};

function Waiting({ show, children }) {
  if (show) return children;
  return (
    <>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </>
  );
}

function MachineDetailModal({ machineId, open = false, onClose, lastVersion }) {
  const classes = useStyles();

  const machine = useSelector(state => state.machine.list[machineId]);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getDetail = useCallback(() => {
    setData(null);
    httpService
      .makeRequest('get', api.machine + '/droplet/' + machineId)
      .then(res => setData(res))
      .catch(() => {
        enqueueSnackbar(`Error ${machineId} Machine Detail`, {
          variant: 'error'
        });
        onClose();
      });
  }, [enqueueSnackbar, setData, machineId, onClose]);

  useEffect(() => {
    if (open) {
      getDetail();
    }
    // eslint-disable-next-line
  }, [open]);

  const startDroplet = useCallback(() => {
    httpService
      .makeRequest('post', api.machine + '/start/' + machineId)
      .then(res => {
        enqueueSnackbar(`Start Droplet`, { variant: 'success' });
        getDetail();
      })
      .catch(() => {
        enqueueSnackbar(`Droplet Could Not Be Started`, { variant: 'error' });
      });
  }, [machineId, enqueueSnackbar, getDetail]);

  const stopDroplet = useCallback(() => {
    httpService
      .makeRequest('post', api.machine + '/stop/' + machineId)
      .then(res => {
        enqueueSnackbar(`Stop Droplet`, { variant: 'success' });
        getDetail();
      })
      .catch(() => {
        enqueueSnackbar(`Droplet Could Not Be Stopped`, { variant: 'error' });
      });
  }, [machineId, enqueueSnackbar, getDetail]);

  const restartDroplet = useCallback(() => {
    httpService
      .makeRequest('post', api.machine + '/restart/' + machineId)
      .then(res => {
        enqueueSnackbar(`Restart Droplet`, { variant: 'success' });
        getDetail();
      })
      .catch(() => {
        enqueueSnackbar(`Droplet Could Not Restart`, { variant: 'error' });
      });
  }, [machineId, enqueueSnackbar, getDetail]);

  const handleUpgradeMachine = useCallback(() => {
    httpService
      .makeRequest('get', api.machine + '/upgrade/droplet/' + machineId)
      .then(res => {
        enqueueSnackbar(`Upgrade Droplet`, { variant: 'success' });
        getDetail();
      })
      .catch(() => {
        enqueueSnackbar(`Droplet Could Not Restart`, { variant: 'error' });
      });
  }, [machineId, enqueueSnackbar, getDetail]);

  const destroyMachine = useCallback(() => {
    httpService
      .makeRequest('delete', api.machine + '/' + machineId)
      .then(async res => {
        enqueueSnackbar(`Destroyed Droplet!`, { variant: 'success' });
        await dispatch(fetchAllMachine());
        await dispatch(getAllJobs());
        onClose();
      })
      .catch(() => {
        enqueueSnackbar(`Droplet Could Not Restart`, { variant: 'error' });
      });
  }, [dispatch, machineId, enqueueSnackbar, onClose]);

  const startDisabled = 'passive' !== data?.status;
  const stopDisabled = 'active' !== data?.status;

  return (
    <Drawer
      onClose={onClose}
      open={open}
      classes={{ paper: classes.drawer }}
      anchor="right"
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              Machine Details
            </Typography>
            <div>
              <IconButton onClick={getDetail}>
                <SvgIcon fontSize="small">
                  <RefreshIcon />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={onClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </div>
          </Box>
          <Divider />
          <Box mt={2}>
            <Waiting show={data}>
              <LineItem name="Name" value={data?.name} />
              <LineItem name="Version" value={data?.imageVersion} />
              <LineItem name="Machine ID" value={data?.machineId} isCopy />
              <LineItem name="Droplet ID" value={machine?.dropletId} isCopy />
              <LineItem name="Public IP" value={data?.publicIP} isCopy />
              <LineItem
                name="Type"
                value={<Label color="info">{machine?.machineType}</Label>}
              />
              <LineItem
                name="Status"
                value={
                  <Label color={status[data?.status]}>{data?.status}</Label>
                }
              />
              <LineItem
                name="Connection"
                value={
                  <div className={classes.center}>
                    <SvgIcon fontSize="small" className={classes.button}>
                      <ConnectIcon
                        color={data?.connected ? 'green' : '#f44336'}
                      />
                    </SvgIcon>
                    {data?.connected ? 'connected' : 'disconnected'}
                  </div>
                }
              />
              <LineItem
                name="Image"
                value={data?.image?.name}
                extra={<Label color="info">{data?.image?.distribution}</Label>}
              />
              <Divider />
              <Typography
                className={classes.header}
                variant="h4"
                color="textPrimary"
              >
                Parameters
              </Typography>
              <Box mt={2}>
                {machine?.parametres &&
                  Object.keys(machine?.parametres).map(p => (
                    <LineItem
                      key={machine.id + p}
                      name={p}
                      value={machine?.parametres[p]}
                    />
                  ))}
              </Box>
              <Divider />
              <Typography
                className={classes.header}
                variant="h4"
                color="textPrimary"
              >
                Actions
              </Typography>
              <Box mt={2}>
                <Button
                  className={classes.button}
                  color="secondary"
                  disabled={startDisabled}
                  type="submit"
                  variant="contained"
                  onClick={startDroplet}
                >
                  Start
                </Button>
                <Button
                  className={classes.button}
                  color="secondary"
                  disabled={stopDisabled}
                  type="submit"
                  variant="contained"
                  onClick={stopDroplet}
                >
                  Stop
                </Button>
                <Button
                  color="secondary"
                  disabled={stopDisabled}
                  type="submit"
                  variant="contained"
                  onClick={restartDroplet}
                >
                  Restart
                </Button>
                <br />
                <br />
                {data?.imageVersion !== lastVersion &&
                  data?.status === 'active' && (
                    <Button
                      color="secondary"
                      disabled={data?.status !== 'active'}
                      type="submit"
                      variant="contained"
                      onClick={handleUpgradeMachine}
                    >
                      Upgrade to {lastVersion}
                    </Button>
                  )}
              </Box>
              <Box>
                <br />
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={destroyMachine}
                >
                  Destroy
                </Button>
              </Box>
            </Waiting>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default MachineDetailModal;
