import React, { useCallback, useState } from 'react';
import socket from 'src/utils/socket';
import {
  Button,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent
} from '@material-ui/core';
import LineItem from 'src/components/LineItem';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { PanelSocketEvents } from '@cnbot/common';
import moment from 'moment';

const exchangeList = ['paribu', 'coinzo'];

function ExchangeDialog(props) {
  const { onClose, exchange, open } = props;

  const exchanges = useSelector(state => state.jobs.exchange);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm">
      <DialogTitle>
        <Typography variant="h4" color="textPrimary">
          {exchange && exchanges[exchange].exchange?.toUpperCase()} STATUS
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <LineItem name="Exchange" value={exchange?.toUpperCase()} />

          <LineItem
            name="Status"
            value={
              <Label color={exchanges[exchange]?.enable ? 'success' : 'info'}>
                {exchange} {exchanges[exchange]?.enable ? 'Active' : 'Passive'}
              </Label>
            }
          />
          {exchanges[exchange]?.coin && (
            <LineItem
              name="Coin"
              value={exchanges[exchange]?.coin?.toUpperCase()}
            />
          )}
          {exchanges[exchange]?.disabledBy && (
            <LineItem
              name="Disabled By"
              value={exchanges[exchange]?.disabledBy?.toUpperCase()}
            />
          )}
          <LineItem
            name="Last Updated"
            value={
              <>
                {moment(exchanges[exchange]?.lastUpdated).format(
                  'DD.MM.YYYY HH:mm:ss'
                )}
              </>
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const ExchangeToolbar = () => {
  const [selectedExchange, setSelectedExchange] = useState(null);
  const exchanges = useSelector(state => state.jobs.exchange);

  const handleChangeStatus = useCallback(
    (exchange, checked) => {
      socket.sendMessage(PanelSocketEvents.UpdateExchangeStatus, {
        exchange: exchange,
        isEnable: checked
      });
    }, // eslint-disable-next-line
    [exchanges]
  );

  return (
    <div>
      {exchangeList.map(exchange => {
        return (
          <span key={exchange}>
            <Label
              color={exchanges[exchange]?.enable ? 'success' : 'info'}
              onClick={() => {
                setSelectedExchange(exchange);
              }}
            >
              {exchange} {exchanges[exchange]?.enable ? 'Active' : 'Passive'}
            </Label>
            <Button
              color="primary"
              variant={exchanges[exchange]?.enable ? 'contained' : 'outlined'}
              onClick={() => {
                return handleChangeStatus(
                  exchange,
                  !exchanges[exchange]?.enable
                );
              }}
            >
              {exchange}
            </Button>
          </span>
        );
      })}
      <ExchangeDialog
        exchange={selectedExchange}
        open={!!selectedExchange}
        onClose={() => setSelectedExchange(null)}
      />
    </div>
  );
};

export default ExchangeToolbar;
