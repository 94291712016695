import React, { useState } from 'react';
import {
  Button,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { Power as PowerIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import socket from 'src/utils/socket';
import { PanelSocketEvents } from '@cnbot/common';

const useStyles = makeStyles(theme => ({
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function SocketSwitch() {
  const classes = useStyles();

  const computeStatus = useSelector(state => state.setting.computeStatus);

  const [isDialog, setDialog] = useState(false);

  const closeDialog = () => {
    setDialog(false);
  };

  const openDialog = () => {
    setDialog(true);
  };

  const handleClick = () => {
    socket.sendMessage(PanelSocketEvents.UpdateComputeStatus, {
      isEnable: !computeStatus
    });
    closeDialog();
  };

  return (
    <>
      {computeStatus ? (
        <Button
          color="secondary"
          variant="contained"
          style={{ width: '100%' }}
          onClick={openDialog}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PowerIcon />
          </SvgIcon>
          On
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={openDialog}
          style={{ width: '100%' }}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PowerIcon />
          </SvgIcon>
          Off
        </Button>
      )}

      <Dialog open={isDialog} onClose={closeDialog}>
        <DialogTitle>COMPUTE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you confirm to turn {computeStatus ? 'off' : 'on'} the compute?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleClick} color="secondary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SocketSwitch;
