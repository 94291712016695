import api from 'src/enums/api';
import httpService from 'src/utils/httpService';
import wait from 'src/utils/wait';
import qs from 'query-string';

export const GET_TRANSFER_JOBS = '@CNBOT/get-transfer-jobs';
export const GET_TRANSFER_HISTORY = '@CNBOT/get-transfer-history';
export const GET_ADDRESSES = '@CNBOT/get-transfer-addresses';

export function fetchAllAddresses() {
  const request = httpService.makeRequest('get', `${api.transfer}/address`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ADDRESSES,
        payload: response
      })
    );
  };
}

export function fetchAllTransfers(options) {
  const request = httpService.makeRequest(
    'get',
    `${api.transfer}/history?${qs.stringify(options)}`
  );

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_TRANSFER_HISTORY,
        payload: response
      })
    );
  };
}

export function fetchAllTransferJobs() {
  const request = httpService.makeRequest('get', `${api.transfer}/job`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_TRANSFER_JOBS,
        payload: response
      })
    );
  };
}

export function createNewTransfer(transfer) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.transfer}/withdraw`, transfer);
    await wait(500);
    fetchAllTransfers()(dispatch);
  };
}

export function createNewTransferJob(jobs) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.transfer}/job`, jobs);
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function updateTransferJob(jobs) {
  return async dispatch => {
    await httpService.makeRequest('put', `${api.transfer}/job`, jobs);
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function deleteTransferJob(id) {
  return async dispatch => {
    await httpService.makeRequest('delete', `${api.transfer}/job/${id}`);
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function createTransferJobStart(id) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.transfer}/job/${id}/start`);
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function createTransferJobStop(id) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.transfer}/job/${id}/stop`);
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function createNewTransferJobAccount(account) {
  return async dispatch => {
    await httpService.makeRequest(
      'post',
      `${api.transfer}/job/addAccount`,
      account
    );
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function deleteTransferJobAccount(account) {
  return async dispatch => {
    await httpService.makeRequest(
      'post',
      `${api.transfer}/job/deleteAccount`,
      account
    );
    await wait(500);
    fetchAllTransferJobs()(dispatch);
  };
}

export function createNewAddress(account) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.transfer}/address`, account);
    await wait(500);
    fetchAllAddresses()(dispatch);
  };
}

export function updateAddress(account) {
  return async dispatch => {
    await httpService.makeRequest('put', `${api.transfer}/address`, account);
    await wait(500);
    fetchAllAddresses()(dispatch);
  };
}

export function deleteAddress(id) {
  return async dispatch => {
    await httpService.makeRequest('delete', `${api.transfer}/address/${id}`);
    await wait(500);
    fetchAllAddresses()(dispatch);
  };
}
