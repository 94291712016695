import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCommonSettings } from 'src/store/actions/settingActions';
import Page from 'src/components/Page';
import DataTable from 'src/components/DataTable';
import { routesPaths } from 'src/Routes';

import { Edit as EditIcon } from 'react-feather';
import { SvgIcon, IconButton } from '@material-ui/core';
import Permission, { UserRoles } from 'src/components/Permission';

import ConfigurationEdit from './configurationViews/component/configurationEdit';

import { useHistory } from 'react-router';

function Configuration() {
  const [
    isOpenConfigurationEditPanel,
    setIsOpenConfigurationEditPanel
  ] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const selectedRole = useSelector(state => state.setting.selectedRole);
  const commonSettings = useSelector(state => state.setting.commonSettings);
  const history = useHistory();
  const dispatch = useDispatch();

  console.log('commonSettings', commonSettings);

  useEffect(() => {
    dispatch(getCommonSettings());
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedRole !== UserRoles.DEVELOPER &&
      selectedRole !== UserRoles.MAINTAINER
    ) {
      history.push('/');
    }
  });

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Settings',
      link: routesPaths.settings.root
    },
    {
      label: 'Configuration',
      link: routesPaths.settings.accounts
    }
  ];

  const columns = [
    {
      key: 'key',
      title: 'Key'
    },
    {
      key: 'value',
      title: 'Value',

      renderCell: value => {
        if (typeof value === 'object') {
          return <div>{JSON.stringify(value, null, 4)}</div>;
        } else {
          return <div>{value}</div>;
        }
      }
    },
    {
      key: 'actions',
      title: 'Action',
      align: 'right',
      renderCell: (action, record) => (
        <IconButton
          onClick={() => {
            setIsOpenConfigurationEditPanel(true);
            setSelectedSetting(record);
          }}
        >
          <SvgIcon fontSize="small">
            <EditIcon />
          </SvgIcon>
        </IconButton>
      )
    }
  ];

  return (
    <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
      <Page title="Configuration" breadcrumbs={breadcrumbs}>
        <DataTable columns={columns} source={Object.values(commonSettings)} />
        {selectedSetting && (
          <ConfigurationEdit
            open={isOpenConfigurationEditPanel}
            onClose={() => {
              setIsOpenConfigurationEditPanel(false);
              setSelectedSetting(null);
            }}
            selectedSetting={selectedSetting}
          />
        )}
      </Page>
    </Permission>
  );
}

export default Configuration;
