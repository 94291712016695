import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AccountCell from 'src/components/AccountCell';

const useStyles = makeStyles(() => ({
  exchangeCell: {
    flex: '1'
  },
  divider: {
    margin: 5
  }
}));

function AddressCell({ address, coin = false, balance = false }) {
  const classes = useStyles();
  const addresses = useSelector(state => state.transfer.addresses);

  const value = addresses[address];
  return (
    <div className={classes.exchangeCell}>
      <h4>{value?.addressName || address}</h4>
      <Divider className={classes.divider} />
      <AccountCell
        account={value?.account}
        coin={coin && value?.coin}
        balance={balance && value?.addressName}
      />
    </div>
  );
}

export default AddressCell;
