import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { routesPaths } from 'src/Routes';
import { Button, SvgIcon, IconButton, makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { PlusCircle as PlusCircleIcon, Edit as EditIcon } from 'react-feather';
import DataTable from 'src/components/DataTable';

import { fetchAllAccounts } from 'src/store/actions/accountsActions';
import { EXCHANGES_LOGO } from 'src/enums/common';
import UpdateTokenModal from './accountViews/components/UpdateTokenModal';
import Permission, { UserRoles } from 'src/components/Permission';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  exchangeImage: {
    height: 30
  }
}));

function Accounts() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accounts = Object.values(useSelector(state => state.accounts.list));

  // const [account, setAccount] = useState(null);
  // const [isOpenNewUserPanel, setOpenNewUserPanel] = useState(false);
  const [isOpenUpdateTokenPanel, setOpenUpdateTokenPanel] = useState(false);

  useEffect(() => {
    dispatch(fetchAllAccounts());
  }, [dispatch]);

  const handleClickEditUser = account => {
    // setUser(account);
    // setOpenNewUserPanel(true);
  };

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Settings',
      link: routesPaths.settings.root
    },
    {
      label: 'Accounts',
      link: routesPaths.settings.accounts
    }
  ];

  const columns = [
    {
      key: 'exchange',
      title: 'Exchange',
      renderCell: exchange => EXCHANGES_LOGO[exchange]
    },
    {
      key: 'taker',
      title: 'Taker'
    },
    {
      key: 'maker',
      title: 'Maker'
    },
    {
      key: 'actions',
      title: 'Action',
      align: 'right',
      renderCell: (_user, record) => (
        <IconButton onClick={() => handleClickEditUser(record)}>
          <SvgIcon fontSize="small">
            <EditIcon />
          </SvgIcon>
        </IconButton>
      )
    }
  ];

  const openUpdateToken = () => {
    setOpenUpdateTokenPanel(true);
  };

  const closeUpdateToken = () => {
    setOpenUpdateTokenPanel(false);
  };

  const renderButton = () => (
    <>
      <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={openUpdateToken}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          Update Token
        </Button>
      </Permission>
    </>
  );

  return (
    <Page
      title="Accounts"
      breadcrumbs={breadcrumbs}
      rightContainer={renderButton()}
    >
      <DataTable columns={columns} source={accounts} />
      <UpdateTokenModal
        open={isOpenUpdateTokenPanel}
        onClose={closeUpdateToken}
      />
    </Page>
  );
}

export default Accounts;
