import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import LineChart from 'src/components/LineChart';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: '100%'
  }
}));

const ticker = 7.39;

function DailyBasedTotalEarning({ source }) {
  source.reverse();

  const classes = useStyles();
  const userRole = useSelector(state => state.setting.selectedRole);
  const isUSDTVersion = userRole === 'reporter';
  const [currency, setCurrency] = useState(isUSDTVersion ? 'usdt' : 'try');

  useEffect(() => {
    setCurrency(userRole === 'reporter' ? 'usdt' : 'try');
  }, [userRole]);

  const performance = {
    thisWeek: {
      data: [],
      labels: []
    },
    thisMonth: {
      data: [],
      labels: []
    },
    thisYear: {
      data: source.map(e =>
        !isUSDTVersion
          ? e.totalTry
          : e.totalUsdt + (e.totalTry / e.ticker || ticker)
      ),
      labels: source.map(e =>
        moment(e.date)
          .add(1, 'day')
          .format('MM.DD')
      )
    }
  };

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Daily Based Total Earning" />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box height={375}>
            <LineChart
              className={classes.chart}
              data={performance.thisYear.data}
              labels={performance.thisYear.labels}
              currency={` ${currency.toUpperCase()}`}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

export default DailyBasedTotalEarning;
