import React from 'react';
import PropTypes from 'prop-types';
import { Settings as SettingsIcon, User as UserIcon } from 'react-feather';
import { routesPaths } from 'src/Routes';
import SideBar from '../components/SideBar';

function SettingLayout({ children }) {
  const navConfig = [
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Accounts',
          icon: UserIcon,
          href: routesPaths.settings.accounts
        },
        {
          title: 'Configuration',
          icon: SettingsIcon,
          href: routesPaths.settings.configuration
        }
      ]
    }
  ];

  return <SideBar config={navConfig}>{children}</SideBar>;
}

SettingLayout.propTypes = {
  children: PropTypes.any
};

export default SettingLayout;
