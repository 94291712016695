/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { strongShadows } from './shadows';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfig = {
  palette: {
    type: 'dark',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)'
    },
    background: {
      default: '#282C34',
      dark: '#1c2025',
      paper: '#282C34'
    },
    primary: {
      main: '#C8165E'
    },
    secondary: {
      main: '#C8165E'
    },
    text: {
      primary: '#e6e5e8',
      secondary: '#adb0bb'
    }
  },
  shadows: strongShadows
};

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true
};

export function createTheme() {
  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, {
      direction: defaultSettings.direction
    })
  );

  if (defaultSettings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
