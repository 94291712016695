import React from 'react';
import { COINS_ICONS } from '../enums/common';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  coinIcon: {
    '& > svg': {
      width: 24,
      height: 24,
      margin: 5
    }
  },
  coinCell: {
    display: 'flex',
    alignItems: 'center'
  },
  coinLabel: {
    marginLeft: 5,
    paddingBottom: 3
  }
}));

function CoinCell({ coin, direction = 'row', amount = '' }) {
  const classes = useStyles();
  return (
    <div className={classes.coinCell} style={{ flexDirection: direction }}>
      <span className={classes.coinIcon}>{COINS_ICONS[coin]}</span>
      <Typography
        variant="inherit"
        className={classes.coinLabel}
        color="textPrimary"
      >
        {amount} {coin}
      </Typography>
    </div>
  );
}

export default CoinCell;
