import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, makeStyles } from '@material-ui/core';
import { formatPrice } from 'src/utils/format';
import { COINS_ICONS } from 'src/enums/common';
import { getTicker } from 'src/store/actions/balancesActions';

const useStyles = makeStyles(theme => ({
  root: {},
  currency: {
    marginLeft: 10,
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  }
}));

export default function EarningDisplay() {
  const classes = useStyles();
  const earnings = useSelector(state => state.transaction.reports.earnings);
  const ticker = useSelector(state => state.balances.ticker);

  const roleRatio = useSelector(state => state.setting.selectedRatio);
  const userRole = useSelector(state => state.setting.selectedRole);

  const keys = Object.keys(earnings);
  const dispatch = useDispatch();

  const isUSDVersion = userRole === 'reporter';

  useEffect(() => {
    dispatch(getTicker());
  }, [dispatch]);

  const totalEarning = () => {
    let total = 0;

    keys.forEach(currency => {
      if (currency === 'TRY') {
        total += ((earnings[currency] ?? 0) * roleRatio) / ticker.ask;
      } else {
        total += (earnings[currency] ?? 0) * roleRatio;
      }
    });

    return total.toFixed(2);
  };

  const getEarnings = (r = roleRatio) => {
    return (
      keys &&
      keys.map(currency => {
        return (
          <Typography
            key={currency}
            variant="body2"
            style={{ fontWeight: 'bold' }}
            color="inherit"
            align="right"
          >
            {formatPrice(earnings[currency] * r)}
            <span className={classes.currency}>{COINS_ICONS[currency]}</span>
          </Typography>
        );
      })
    );
  };

  return (
    <div>
      {!isUSDVersion ? (
        getEarnings(roleRatio)
      ) : ticker ? (
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold' }}
          color="inherit"
          align="right"
        >
          Profit: {totalEarning()}{' '}
          <span className={classes.currency}>{COINS_ICONS.USDT}</span>
        </Typography>
      ) : (
        getEarnings(roleRatio)
      )}
    </div>
  );
}
