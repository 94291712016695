import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Activity as MenuIcon } from 'react-feather';
import { X as CloseIcon } from 'react-feather';
import {
  Card,
  SvgIcon,
  makeStyles,
  IconButton,
  Drawer,
  CardHeader
} from '@material-ui/core';
import BalanceBox from '../../../views/balances/component/BalanceBox';

const useStyles = makeStyles(theme => ({
  root: {},
  drawer: {
    width: 1240,
    maxWidth: '100%'
  },
  scroll: {
    paddingBottom: 70
  }
}));

function Balances({ className, open = false, onClose, ...rest }) {
  const classes = useStyles();

  let balances = useSelector(state => state.balances.data);
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  if (!balances) {
    return null;
  }

  const exchanges = Object.keys(balances);

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpen(!isOpen)}>
        <SvgIcon fontSize="small">
          <MenuIcon />
        </SvgIcon>
      </IconButton>
      {exchanges.length > 0 && (
        <Drawer
          onClose={() => setOpen(false)}
          open={isOpen}
          classes={{ paper: classes.drawer }}
          anchor="left"
          variant="temporary"
        >
          <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
              title="Balances"
              action={
                <IconButton color="inherit" onClick={() => setOpen(!isOpen)}>
                  <SvgIcon fontSize="small">
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              }
            />
            <PerfectScrollbar className={classes.scroll}>
              <BalanceBox balances={balances} />
            </PerfectScrollbar>
          </Card>
        </Drawer>
      )}
    </>
  );
}

export default Balances;
