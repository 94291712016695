import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';

import BalanceBox from './component/BalanceBox';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  stickyCol: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.default
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  body: {
    marginTop: 15
  }
}));

function BooksModule() {
  const classes = useStyles();

  let balances = useSelector(state => state.balances.data);

  return (
    <Page className={classes.root}>
      <BalanceBox balances={balances} />
    </Page>
  );
}

export default BooksModule;
