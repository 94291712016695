import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Divider,
  SvgIcon,
  Typography,
  makeStyles,
  Grid,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { XCircle as XIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import wait from 'src/utils/wait';
import * as Yup from 'yup';
import { updateToken } from 'src/store/actions/settingActions';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  }
}));

const accounts = [
  'btcturk_access_token',
  'btcturk_refresh_token',
  'paribu_access_token',
  'koinim_access_token',
  'coinzo_access_token'
];

function UpdateTokenModal({ open = false, onClose }) {
  const [isRefreshToken, isSetRefreshToken] = useState(false);

  const newJobValidationSchema = isRefreshToken
    ? Yup.object().shape({
      key: Yup.string().required('Required'),
      accessToken: Yup.string()
        .max(1500, 'Must be longer than 1500 characters')
        .required('Required'),
      refreshToken: Yup.string()
        .max(1500, 'Must be longer than 1500 characters')
        .required('Required')
    })
    : Yup.object().shape({
      key: Yup.string().required('Required'),
      value: Yup.string()
        .min(5, 'Must be longer than 5 characters')
        .required('Required')
    });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Drawer
      onClose={onClose}
      open={open}
      classes={{ paper: classes.drawer }}
      anchor="right"
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              Token Update
            </Typography>
            <IconButton onClick={onClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Divider />
          <Box mt={2}>
            {open && (
              <Formik
                initialValues={{ key: accounts[0], value: undefined }}
                validationSchema={newJobValidationSchema}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  if (isRefreshToken) values.value = null;
                  try {
                    await updateToken(values);

                    enqueueSnackbar(`${values.key} token updated`, {
                      variant: 'success'
                    });

                    resetForm();
                    setStatus({ success: true });
                    await wait(500);

                    onClose();
                  } catch (error) {
                    console.log(error);
                    setStatus({ success: false });
                    setErrors({ submit: error.message });
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box p={2}>
                      <Grid container spacing={3}>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.key && errors.key)}
                            helperText={touched.key && errors.key}
                            size="small"
                            label="Account"
                            name="key"
                            select
                            SelectProps={{ native: true }}
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value === 'btcturk_refresh_token') {
                                isSetRefreshToken(true);
                              } else {
                                isSetRefreshToken(false);
                              }
                            }}
                            value={values.key}
                            variant="outlined"
                          >
                            {accounts.map(acc => (
                              <option key={acc} value={acc}>
                                {acc}
                              </option>
                            ))}
                          </TextField>
                        </Grid>

                        {isRefreshToken ? (
                          <>
                            <Grid item md={12} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                error={Boolean(
                                  touched.accessToken && errors.accessToken
                                )}
                                helperText={
                                  touched.accessToken && errors.accessToken
                                }
                                size="small"
                                label="Access Token"
                                name="accessToken"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.accessToken}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                error={Boolean(
                                  touched.refreshToken && errors.refreshToken
                                )}
                                helperText={
                                  touched.refreshToken && errors.refreshToken
                                }
                                size="small"
                                label="Refresh Token"
                                name="refreshToken"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.refreshToken}
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid item md={12} sm={12} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(touched.value && errors.value)}
                              helperText={touched.value && errors.value}
                              size="small"
                              label="Token"
                              name="value"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.value}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box p={2} display="flex" justifyContent="flex-end">
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default UpdateTokenModal;
