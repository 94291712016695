/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_JOBS,
  GET_JOBS_DETAILS,
  READY_JOB,
  STARTED_JOB,
  STOPPED_JOB,
  DELETED_JOB,
  JobStatus,
  UPDATE_PARIBU_STATUS,
  UPDATE_KOINIM_STATUS,
  UPDATE_COINZO_STATUS,
  GET_JOB_DETAIL,
  RESET_JOB_DETAIL
} from 'src/store/actions/jobsActions';

const initialState = {
  coins: {},
  jobDetail: null,
  exchange: {
    paribu: null,
    koinim: null,
    coinzo: null
  }
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS: {
      return produce(state, draft => {
        action.payload.forEach(job => {
          draft.coins[job.coin] = job;
        });
      });
    }

    case GET_JOBS_DETAILS: {
      return produce(state, draft => {
        Object.keys(draft).forEach(coin => {
          delete draft.coins[coin];
        });
        action.payload.forEach(job => {
          draft.coins[job.coin] = job;
        });
      });
    }

    case READY_JOB: {
      return produce(state, draft => {
        draft.coins[action.payload.coin].status = JobStatus.Ready;
      });
    }

    case STARTED_JOB: {
      return produce(state, draft => {
        draft.coins[action.payload.coin].status = JobStatus.Started;
      });
    }

    case STOPPED_JOB: {
      return produce(state, draft => {
        draft.coins[action.payload.coin].status = JobStatus.Stopped;
      });
    }

    case DELETED_JOB: {
      return produce(state, draft => {
        delete draft.coins[action.payload.coin];
      });
    }

    case UPDATE_PARIBU_STATUS: {
      return produce(state, draft => {
        draft.exchange.paribu = action.status;
      });
    }

    case UPDATE_KOINIM_STATUS: {
      return produce(state, draft => {
        draft.exchange.koinim = action.status;
      });
    }

    case UPDATE_COINZO_STATUS: {
      return produce(state, draft => {
        draft.exchange.coinzo = action.status;
      });
    }

    case GET_JOB_DETAIL: {
      return produce(state, draft => {
        draft.jobDetail = action.payload;
      });
    }

    case RESET_JOB_DETAIL: {
      return produce(state, draft => {
        draft.jobDetail = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default jobReducer;
