import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';

const config = {
  auth0: {
    domain: 'qtabapp.eu.auth0.com',
    clientId: 'aB18FdMpsbGGt4bHzsliif03eBMSmBOm',
    audience: 'https://qtabapp.eu.auth0.com/api/v2/'
  }
};

function Auth({ children }) {
  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      audience={config.auth0.audience}
      redirectUri={window.location.origin}
      scope={'openid profile email'}
    >
      {children}
    </Auth0Provider>
  );
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
