import jwtDecode from 'jwt-decode';
import httpService from 'src/utils/httpService';

const localStorageKey = 'cnbot_accessToken';

class AuthService {
  accessToken = null;

  setAxiosInterceptors = ({ onLogout, accessToken }) => {
    this.handleAuthentication(accessToken);
    // httpService.getInstance().interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response && error.response.status === 401) {
    //       this.setSession(null);

    //       if (onLogout) {
    //         // onLogout({ returnTo: window.location.origin });
    //         // this.logout = onLogout();
    //       }
    //     }

    //     return Promise.reject(error);
    //   }
    // );
  };

  handleAuthentication(accessToken) {
    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.accessToken = accessToken;
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = async (email, password) => {
    // const result = await httpService.makeRequest('post', api.login, {
    //   email,
    //   password
    // });
    // this.setSession(result.accessToken);
    // return jwtDecode(result.accessToken);
  };

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      httpService.getInstance().defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(localStorageKey);
      delete httpService.getInstance().defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => this.accessToken;

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
