/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_ACCOUNTS } from '../actions/accountsActions';

const initialState = {
  list: {}
};

// const ac = {
//   id:
//   taker:
//   maker:
//   exchange:
//   status:
// }

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS: {
      return produce(state, draft => {
        action.payload.forEach(account => {
          draft.list[account.id] = account;
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
