import api from 'src/enums/api';
import httpService from 'src/utils/httpService';

export const GET_ALL_MACHINES = '@CNBOT/fetch-all-machines';
export const PING_MACHINE_ACTION_TYPE = '@CNBOT/ping-machine';
export const GET_ALL_DROPLETS = '@CNBOT/fetch-all-droplets';
export const UPDATED_MACHINE = '@CNBOT/updated-machine';

export function fetchAllMachine() {
  const request = httpService.makeRequest('get', api.machine);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ALL_MACHINES,
        payload: response
      })
    );
  };
}

export function fetchAllDroplets() {
  const request = httpService.makeRequest('get', `${api.machine}/droplets`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ALL_DROPLETS,
        payload: response
      })
    );
  };
}

export function getMachineDetail(id) {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.machine}/${id}/detail`
    );

    dispatch({
      type: UPDATED_MACHINE,
      payload: response
    });
  };
}
