import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tabs,
  Tab,
  Divider,
  Box
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MinorCoinEnum, COINS_ICONS } from 'src/enums/common';
import { formatPrice } from 'src/utils/format';
import LineChart from 'src/components/LineChart';
import MultiSelect from 'src/components/MultiSelect';
import { useFilteredCoin } from 'src/components/Permission';

const useStyles = makeStyles(() => ({
  root: {},
  icon: {
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  chart: {
    paddingTop: 20,
    height: 250
  },
  tabHeaderCell: {
    paddingTop: 30
  }
}));

const localStorageSelectedCoinsKey = 'cbot-ls-daily-profit-coins';

// const coins = Object.values(CoinEnum);
// coins.pop(); // delete ETH_UNAVAILABLE

export default function EarningByCoins() {
  const classes = useStyles();
  const earnings = useSelector(state => state.transaction.reports.earningCoins);
  const roleRatio = useSelector(state => state.setting.selectedRatio);

  const [currentTab, setCurrentTab] = useState(MinorCoinEnum.TRY);
  const [chartData, setChartData] = useState({});
  const coins = useFilteredCoin(true, true);
  const [selectedCoins, setSelectedCoins] = useState(coins);

  useEffect(() => {
    setSelectedCoins(coins);
  }, [coins]);

  useEffect(() => {
    let data = {};
    coins.forEach(coin => {
      const val = earnings[coin] && earnings[coin][currentTab] * roleRatio;
      if (val) {
        data[coin] = val;
      }
    });
    setChartData(data);
  }, [earnings, roleRatio, currentTab, coins]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabsChange = (event, value) => setCurrentTab(value);

  const onChangeSelectedCoin = value => setSelectedCoins(value);

  return (
    <Card>
      <Tabs
        onChange={handleTabsChange}
        textColor="secondary"
        value={currentTab}
      >
        {Object.values(MinorCoinEnum).map(tab => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <Divider />
      <LineChart
        className={classes.chart}
        data={Object.values(chartData)}
        labels={Object.keys(chartData)}
        currency={` ${MinorCoinEnum[currentTab]}`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box display="flex" flexDirection="row" alignItems="center">
                <MultiSelect
                  id={localStorageSelectedCoinsKey}
                  name="Filter"
                  items={coins}
                  selectedItems={selectedCoins}
                  onChange={onChangeSelectedCoin}
                  clear="all"
                  save
                />
              </Box>
            </TableCell>
            {Object.values(MinorCoinEnum).map(coin => {
              return (
                <TableCell
                  key={coin}
                  align="right"
                  className={classes.tabHeaderCell}
                >
                  {COINS_ICONS[coin]}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedCoins.map(coin => {
            if (coin === 'ETH_UNAVAILABLE') {
              return <></>;
            }
            return (
              <TableRow key={coin}>
                <TableCell>
                  <div style={{ display: 'flex' }} className={classes.icon}>
                    {COINS_ICONS[coin]}{' '}
                    <span style={{ marginLeft: 10 }}>{coin}</span>
                  </div>
                </TableCell>
                {Object.values(MinorCoinEnum).map(minorCoin => {
                  return (
                    <TableCell key={minorCoin} align="right">
                      {(earnings[coin] &&
                        formatPrice(earnings[coin][minorCoin] * roleRatio)) ||
                        '0.00'}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
}
