import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, Button, SvgIcon, Switch } from '@material-ui/core';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { getAllJobs, getJobDetail } from 'src/store/actions/jobsActions';
import JobTable from './components/JobTable';
import NewJob from './components/NewJob';
import Permission from 'src/components/Permission';
import { UserRoles } from 'src/components/Permission';
import { green } from '@material-ui/core/colors';
import EditJob from './components/EditJob';
import ExchangeToolbar from 'src/components/ExchangeToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'inline-block'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function JobsModule() {
  const classes = useStyles();
  const [isOpenNewJobPanel, setIsOpenNewJobPanel] = useState(false);
  const [isOpenEditJobPanel, setIsOpenEditJobPanel] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllJobs());
  }, [dispatch]);

  const handleShowDetail = useCallback(event => {
    setShowDetail(event.target.checked);
  }, []);

  const handleSelectJob = useCallback(
    async job => {
      await dispatch(getJobDetail(job.id));
      setIsOpenEditJobPanel(true);
      setSelectedJob(job);
    },
    [dispatch]
  );

  return (
    <Page
      className={classes.root}
      title={
        <>
          <span>Jobs</span>
          <Switch checked={showDetail} onChange={handleShowDetail} />
        </>
      }
      rightContainer={
        <>
          <div className={classes.wrapper}>
            <ExchangeToolbar />
          </div>
          <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsOpenNewJobPanel(true)}
            >
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <PlusCircleIcon />
              </SvgIcon>
              New Job
            </Button>
          </Permission>
        </>
      }
    >
      <NewJob
        open={isOpenNewJobPanel}
        onClose={() => {
          setIsOpenNewJobPanel(false);
        }}
      />
      {isOpenEditJobPanel && (
        <EditJob
          open={isOpenEditJobPanel}
          job={selectedJob}
          onClose={() => {
            setIsOpenEditJobPanel(false);
            setSelectedJob(null);
          }}
        />
      )}
      <JobTable showDetail={showDetail} onSelectJob={handleSelectJob} />
    </Page>
  );
}

export default JobsModule;
