import React, { useState } from 'react';
import {
  ListItemText,
  makeStyles,
  MenuItem,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Copy as CopyIcon } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  rowCell: {
    width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  title: {
    minWidth: 100,
    paddingRight: 20
  }
}));

function LineItem({ name, value, extra, isCopy }) {
  const classes = useStyles();
  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 1000);
  };

  const item = (
    <MenuItem className={classes.rowCell}>
      <Typography className={classes.title} variant="h5" color="textSecondary">
        {name}
      </Typography>
      <ListItemText
        primary={copy ? 'Copied..' : value}
        secondary={!copy && extra}
      />
      {isCopy && (
        <SvgIcon fontSize="small">
          <CopyIcon />
        </SvgIcon>
      )}
    </MenuItem>
  );

  if (isCopy) {
    return (
      <CopyToClipboard text={value} onCopy={onCopy}>
        {item}
      </CopyToClipboard>
    );
  }

  return item;
}

export default LineItem;
