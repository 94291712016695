import React from 'react';
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import wait from 'src/utils/wait';
import { CoinEnum, MinorCoinEnum } from 'src/enums/common';
import DrawerPage from 'src/components/DrawerPage';
import Select from 'src/components/Select';
import CoinCell from 'src/components/CoinCell';
import { createNewTransferJob } from 'src/store/actions/transferActions';

const newJobValidationSchema = Yup.object().shape({
  coin: Yup.string().required('Required'),
  totalCoinAmounts: Yup.string().required('Required'),
  minValue: Yup.string().required('Required'),
  maxValue: Yup.string().required('Required')
});

function NewTransferJob({ open = false, onClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      coin: '',
      totalCoinAmounts: '',
      minValue: '',
      maxValue: ''
    },
    validationSchema: newJobValidationSchema,
    onSubmit: async values => {
      try {
        await dispatch(createNewTransferJob(values));

        enqueueSnackbar(`Created Job for the ${values.coin}`, {
          variant: 'success'
        });
        formik.resetForm();
        formik.setStatus({ success: true });
        await wait(500);

        onClose();
      } catch (error) {
        formik.setStatus({ success: false });
        formik.setErrors({ submit: error.message });
      }
    }
  });

  const coins = [MinorCoinEnum.USDT, ...Object.values(CoinEnum)];
  coins.pop(); // delete ETH_UNAVAILABLE

  return (
    <DrawerPage onClose={onClose} open={open} title="Create Transfer Job">
      <form onSubmit={formik.handleSubmit}>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="coin"
                name="Coin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.coin}
                error={formik.touched.coin && formik.errors.coin}
                items={Object.values(coins)}
                renderCell={coin => <CoinCell coin={coin} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.totalCoinAmounts &&
                    formik.errors.totalCoinAmounts
                )}
                helperText={
                  formik.touched.totalCoinAmounts &&
                  formik.errors.totalCoinAmounts
                }
                size="small"
                label="Total Amount"
                name="totalCoinAmounts"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.totalCoinAmounts}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.minValue && formik.errors.minValue
                )}
                helperText={formik.touched.minValue && formik.errors.minValue}
                size="small"
                label="Min Limit"
                name="minValue"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.minValue}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.maxValue && formik.errors.maxValue
                )}
                helperText={formik.touched.maxValue && formik.errors.maxValue}
                size="small"
                label="Max Limit"
                name="maxValue"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.maxValue}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        {formik.errors.submit && (
          <Box mt={3}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            disabled={formik.isSubmitting}
            type="submit"
            variant="contained"
          >
            Create Job
          </Button>
        </Box>
      </form>
    </DrawerPage>
  );
}

export default NewTransferJob;
