import React from 'react';
import { Parity } from '../../../enums/common';

import {
  Box,
  MenuItem,
  Checkbox,
  ListItemText,
  FormLabel,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formLabel: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  }
}));

function ParitySelection({ formik, accounts }) {
  const classes = useStyles();
  return (
    <Box>
      {formik.values.accounts.map(id => {
        const checkItems = [];
        const account = accounts.find(account => account.id === id);
        const minorParities = Parity[account.exchange];

        minorParities.forEach(minorParity => {
          checkItems.push({
            exchange: account.exchange,
            parity: `${formik.values.coin}/${minorParity}`
          });
        });

        return checkItems.map(e => (
          <MenuItem
            key={`${e.exchange}|${e.parity}`}
            value={`${e.exchange}|${e.parity}`}
          >
            <FormLabel className={classes.formLabel}>
              <Checkbox
                htmlFor
                name={`parities.${e.exchange}|${e.parity}`}
                checked={
                  formik.values.parities[`${e.exchange}|${e.parity}`]
                    ?.length === 1
                }
                value={`${e.exchange}|${e.parity}`}
                onChange={formik.handleChange}
              />

              <ListItemText primary={`${e.exchange} - ${e.parity}`} />
            </FormLabel>
          </MenuItem>
        ));
      })}
    </Box>
  );
}

export default ParitySelection;
