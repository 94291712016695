import React, { useCallback, useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DailyBasedTotalEarning from './components/DailyBasedTotalEarning';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReports } from 'src/store/actions/reportActions';
import DataTable from 'src/components/DataTable';
import moment from 'moment';
import { CoinEnum, COINS_ICONS } from 'src/enums/common';
import { formatPrice } from 'src/utils/format';
import { DateRange, getDateRange } from '@cnbot/common';
import Permission, { UserRoles } from '../../components/Permission';
import { useStyles } from './graph.style';
import CoinBasedEarningStackedBar from './components/CoinBasedEarningStackedBar';
import { calculateUsdtProfit } from '../../utils/calculate-usdt-profit';

function Graphs() {
  const classes = useStyles();

  const [selectedReport, setSelectedReport] = useState();
  const [totalProfit, setTotalProfit] = useState(0);
  const dateRange = getDateRange(DateRange.YEAR);
  const [range, setRange] = useState(DateRange.YEAR);
  const [startDate, setStartDate] = useState(moment(dateRange.startDate));
  const [endDate, setEndDate] = useState(moment(dateRange.endDate));
  const dispatch = useDispatch();
  const report = useSelector(state => state.report);
  const userRole = useSelector(state => state.setting.selectedRole);
  const userMetadata = useSelector(state => state.setting.userMetadata);
  const replaceCoins = useSelector(state => state.setting.replaceCoins);

  useEffect(() => {
    dispatch(fetchReports(range));
    // eslint-disable-next-line
  }, [dispatch]);

  const coins = Object.keys(CoinEnum);
  coins.pop();

  const getEarningByCoin = (source, selectedCoin) => {
    const defaultResult = {
      coin: selectedCoin,
      failedOrderCount: 0,
      successOrderCount: 0,
      transacitonCount: 0,
      try: 0,
      usdt: 0,
      transferFee: 0
    };
    return source.coins.find(e => e.coin === selectedCoin) || defaultResult;
  };

  const getProfitList = () => {
    return report.list.map(e => {
      return {
        ...e,
        totalTry: e.totalTry * e.ratio[userRole],
        totalUsdt: e.totalUsdt * e.ratio[userRole],
        coins: e.coins.map(coin => {
          return {
            ...coin,
            try: coin.try * e.ratio[userRole],
            usdt: coin.usdt * e.ratio[userRole]
          };
        })
      };
    });
  };

  const reportList = getProfitList();

  const totalCoins = () => {
    const totalEarning = {};
    reportList.forEach(row => {
      coins.forEach(coin => {
        const earningByCoin = getEarningByCoin(row, coin);

        if (earningByCoin) {
          if (totalEarning[coin]) {
            totalEarning[coin] = {
              coin,
              try: totalEarning[coin].try + earningByCoin.try,
              usdt: totalEarning[coin].usdt + earningByCoin.usdt,
              transferFee:
                totalEarning[coin].transferFee +
                (earningByCoin.transferFee || 0)
            };
          } else {
            totalEarning[coin] = {
              coin,
              try: earningByCoin.try,
              usdt: earningByCoin.usdt,
              transferFee: earningByCoin.transferFee || 0
            };
          }
        } else {
          totalEarning[coin] = {
            coin,
            try: 0,
            usdt: 0,
            transferFee: 0
          };
        }
      });
    });

    if (userRole !== UserRoles.REPORTER && userRole !== UserRoles.STANDARD) {
      return Object.values(totalEarning);
    }
    Object.keys(replaceCoins).forEach(coin => {
      const secureValue = totalEarning[coin];
      const oldValue = totalEarning[replaceCoins[coin]];
      // debugger;
      if (oldValue) {
        const fakeValue = {
          coin: replaceCoins[coin],
          try: oldValue.try + secureValue.try,
          usdt: oldValue.usdt + secureValue.usdt
        };
        if (totalEarning[replaceCoins[coin]]) {
          totalEarning[replaceCoins[coin]] = fakeValue;
        }
        // delete earning[coin];
      }
      delete totalEarning[coin];
    });

    return Object.values(totalEarning);
  };

  const handleSelectReport = e => {
    setSelectedReport(e);
  };

  const feeColumn = {
    key: 'totalTransferFeeUsdt',
    title: 'Fee',
    align: 'right',
    width: 120,
    renderCell: fee => (
      <span className={classes.earningCurrencyCell}>
        {fee ? formatPrice(fee) : '--'}{' '}
        {!!fee && (
          <span className={classes.earningCurrency}>{COINS_ICONS.USDT}</span>
        )}
      </span>
    )
  };

  const profitColumn = {
    key: '',
    title: 'Profit',
    align: 'right',
    width: 120,
    renderCell: (_, record) => {
      const totalEarning = record.totalUsdt + record.totalTry / record.ticker;
      const profit = totalEarning - record.totalTransferFeeUsdt;
      return (
        <span className={classes.earningCurrencyCell}>
          {profit ? formatPrice(profit) : '--'}{' '}
          {!!profit && (
            <span className={classes.earningCurrency}>{COINS_ICONS.USDT}</span>
          )}
        </span>
      );
    }
  };

  const columns = [
    {
      key: 'date',
      title: 'Date',
      align: 'left',
      width: 200,
      renderCell: date => {
        return (
          <>
            {moment(date).format('DD.MM.YYYY ddd')} <br />
          </>
        );
      }
    },
    {
      key: 'totalUsdt',
      title: 'USDT',
      align: 'right',
      width: 120,
      renderCell: (earning, record) => (
        <span className={classes.earningCurrencyCell}>
          {formatPrice(earning + record.totalTry / record.ticker)}{' '}
          <span className={classes.earningCurrency}>{COINS_ICONS.USDT}</span>
        </span>
      )
    },
    ...(userRole === UserRoles.MAINTAINER ? [feeColumn, profitColumn] : []),
    ...(!userMetadata.role.includes('maintainer')
      ? []
      : [
          {
            key: 'ratio',
            title: 'Ratio',
            align: 'right',
            width: 50,
            renderCell: ratio => {
              return <span>{ratio[userRole]}</span>;
            }
          },
          {
            key: 'ticker',
            title: '$',
            align: 'right',
            width: 50
          }
        ])
  ];

  const handleClickRow = row => {
    if (selectedReport?.id === row.id) {
      handleSelectReport();
    } else {
      handleSelectReport(row);
    }
  };

  const handleChangeDate = () => {
    dispatch(fetchReports(null, startDate, endDate));
  };
  const handleDateRange = e => {
    const newRange = e.target.value;
    setRange(newRange);
    const dateRange = getDateRange(newRange);
    setStartDate(moment(dateRange.startDate));
    setEndDate(moment(dateRange.endDate));
    if (newRange !== 'custom') {
      dispatch(fetchReports(newRange));
    }
  };
  const renderFilter = () => {
    return (
      <Grid container spacing={1} className={classes.filterContainer}>
        <Grid item>
          <TextField
            size="small"
            label="Date Range"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            value={range}
            onChange={handleDateRange}
          >
            {Object.values(DateRange).map(value => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
            <option key="custom" value="custom">
              Custom
            </option>
          </TextField>
        </Grid>
        {range === 'custom' && (
          <>
            <Grid item>
              <KeyboardDatePicker
                size="small"
                label="Start Date"
                variant="inline"
                disableToolbar
                format="DD.MM.YYYY"
                inputVariant="outlined"
                views={['month', 'date']}
                value={startDate}
                onChange={date => setStartDate(date)}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                size="small"
                label="End Date"
                variant="inline"
                disableToolbar
                format="DD.MM.YYYY"
                inputVariant="outlined"
                views={['month', 'date']}
                value={endDate}
                minDate={startDate}
                onChange={date => setEndDate(date)}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleChangeDate}>Filter</Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const getTotalProfitByDateRange = useCallback(() => {
    let profit = 0;
    report.list.forEach(item => {
      profit += calculateUsdtProfit(item.totalUsdt, item.totalTry, item.ticker);
    });
    return profit;
  }, [report]);

  useEffect(() => {
    setTotalProfit(getTotalProfitByDateRange());
  }, [getTotalProfitByDateRange, setTotalProfit]);

  const renderTotalEarning = () => {
    return (
      <Grid container spacing={1}>
        <Grid item>{renderFilter()}</Grid>
        <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
          <Grid item>
            <Typography
              variant="body2"
              style={{ fontWeight: 'bold' }}
              color="inherit"
              align="right"
              className={classes.totalProfit}
            >
              <span className={classes.totalProfit}>
                <span>Total Profit:</span>
                <span>
                  <span>
                    {formatPrice(totalProfit)}{' '}
                    <span className={classes.currency}>{COINS_ICONS.USDT}</span>
                  </span>
                </span>
              </span>
            </Typography>
          </Grid>
        </Permission>
      </Grid>
    );
  };

  return (
    <Page
      className={classes.root}
      title={`Reports - <${moment(startDate).format('DD-MM-YYYY')} | ${moment(
        endDate
      ).format('DD-MM-YYYY')}>`}
      rightContainer={report && report.list && renderTotalEarning()}
    >
      <Grid container spacing={3}>
        <Grid item lg={4} xl={4} xs={12}>
          <DataTable
            columns={columns}
            source={reportList}
            handleClickRow={handleClickRow}
            selectedRow={selectedReport?.id}
          />
        </Grid>
        <Grid item lg={8} xl={8} xs={12}>
          <Grid item lg={12} xl={12} xs={12}>
            {(userRole === UserRoles.MAINTAINER ||
              userRole === UserRoles.DEVELOPER) && (
              <CoinBasedEarningStackedBar
                source={selectedReport || { coins: totalCoins() }}
              />
            )}
          </Grid>
          <br />
          {(userRole === 'maintainer' || userRole === 'developer') && (
            <Grid item lg={12} xl={12} xs={12}>
              <DailyBasedTotalEarning
                source={JSON.parse(JSON.stringify(reportList))}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Graphs;
