import React, { useEffect, useState } from 'react';
import { Button, IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import DataTable from 'src/components/DataTable';
import Page from 'src/components/Page';
import { routesPaths } from 'src/Routes';
import Label from 'src/components/Label';
import Permission, { UserRoles } from 'src/components/Permission';
import {
  ArrowRight as ArrowRightIcon,
  PlusCircle as PlusCircleIcon
} from 'react-feather';
import CoinCell from 'src/components/CoinCell';
import NewTransferJob from './components/NewTransferJob';
import { useDispatch, useSelector } from 'react-redux';
import TransferJobDetail from './components/TransferJobDetail';
import {
  fetchAllAddresses,
  fetchAllTransferJobs
} from 'src/store/actions/transferActions';
import { fetchAllAccounts } from 'src/store/actions/accountsActions';
import AddressCell from './components/AddressCell';

const useStyles = makeStyles(theme => ({
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  account: {
    marginTop: 10
  }
}));

function Jobs() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState(undefined);
  const [isOpenNewJob, setOpenNewJob] = useState(false);
  const [isOpenJobDetail, setOpenJobDetail] = useState(false);

  const jobs = Object.values(useSelector(state => state.transfer.jobs));

  useEffect(() => {
    dispatch(fetchAllAccounts());
    dispatch(fetchAllAddresses());
    dispatch(fetchAllTransferJobs());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialValues) {
      openJobDetail();
    }
  }, [initialValues]);

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Transfer',
      link: routesPaths.transfer.root
    },
    {
      label: 'Jobs',
      link: routesPaths.transfer.jobs
    }
  ];

  const columns = [
    {
      key: 'coin',
      title: 'Coin',
      align: 'center',
      renderCell: coin => <CoinCell coin={coin} direction="column" />
    },
    {
      key: 'totalCoinAmounts',
      title: 'Total Coin Amount'
    },
    {
      key: 'minValue',
      title: 'Min Limit',
      align: 'center'
    },
    {
      key: 'maxValue',
      title: 'Max Limit',
      align: 'center'
    },
    {
      key: 'accounts',
      title: 'Accounts',
      renderCell: acc => {
        if (!acc || acc.length === 0) {
          return [];
        }

        return acc.map(a => (
          <div key={a.addressId} className={classes.account}>
            <AddressCell address={a.addressId} />
            <br />
          </div>
        ));
      }
    },
    {
      key: 'status',
      title: 'Status',
      align: 'center',
      renderCell: status => {
        return (
          // TODO : color
          <Label color="info">{status}</Label>
        );
      }
    },
    {
      key: 'action',
      title: 'Details',
      align: 'right',
      renderCell: (btn, row) => {
        return (
          <IconButton onClick={() => setInitialValues(row)}>
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        );
      }
    }
  ];

  const openNewJob = () => {
    setOpenNewJob(true);
  };

  const closeNewJob = () => {
    setOpenNewJob(false);
  };

  const openJobDetail = () => {
    setOpenJobDetail(true);
  };

  const closeJobDetail = () => {
    setOpenJobDetail(false);
    setInitialValues(undefined);
  };

  const renderButton = () => (
    <>
      <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={openNewJob}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Job
        </Button>
      </Permission>
    </>
  );

  return (
    <Page
      title="Transfer Jobs"
      breadcrumbs={breadcrumbs}
      rightContainer={renderButton()}
    >
      <NewTransferJob open={isOpenNewJob} onClose={closeNewJob} />
      {isOpenJobDetail && (
        <TransferJobDetail
          open={isOpenJobDetail}
          onClose={closeJobDetail}
          initialValues={initialValues}
        />
      )}
      <DataTable columns={columns} source={jobs} />
    </Page>
  );
}

export default Jobs;
