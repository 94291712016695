import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  Drawer,
  IconButton,
  FormHelperText,
  Divider,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import { XCircle as XIcon } from 'react-feather';
import { postUpdate } from 'src/store/actions/settingActions';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  editor: {
    '&>div': {
      minHeight: '300px',
      '&>.jsoneditor': {
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        '&>.jsoneditor-outer': {
          flex: 1,
          '&>.jsoneditor-tree': {
            minHeight: '237px'
          }
        }
      }
    }
  }
}));

function ConfigurationEdit({ open = false, onClose, selectedSetting }) {
  const [isOpen, setOpen] = useState(open);
  const classes = useStyles();
  const [value, setValue] = useState(selectedSetting.value);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleEditorChange = useCallback(data => {
    setValue(data);
  }, []);

  const handleChangeValue = useCallback(event => {
    setValue(event.target.value);
  }, []);

  const handleClick = async () => {
    let formattedValue;
    if (typeof value === 'object') {
      formattedValue = value;
    } else {
      formattedValue = parseFloat(value);
    }
    await dispatch(postUpdate(selectedSetting.key, formattedValue));
    enqueueSnackbar(`"${selectedSetting.key}" is updated!`, {
      variant: 'success'
    });
    onClose();
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  console.log(value);

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      classes={{ paper: classes.drawer }}
      anchor="right"
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              Configuration
            </Typography>
            <IconButton onClick={onClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={2}>
            <form>
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12} xs={12}>
                    {selectedSetting && (
                      <TextField
                        fullWidth
                        value={selectedSetting.key}
                        disabled={true}
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    {selectedSetting && typeof value === 'object' && (
                      <div className={classes.editor}>
                        <Editor
                          value={value}
                          onChange={handleEditorChange}
                          ace={ace}
                          theme="ace/theme/github"
                        />
                      </div>
                    )}
                    <Divider />
                    {selectedSetting &&
                      (typeof value === 'number' ||
                        typeof value === 'string') && (
                        <TextField
                          fullWidth
                          value={value}
                          onChange={handleChangeValue}
                          variant="outlined"
                          type="number"
                        />
                      )}
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <FormHelperText></FormHelperText>
              </Box>

              <Box p={2} display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={handleClick}
                  variant="contained"
                >
                  Configuration
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default ConfigurationEdit;
