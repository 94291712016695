import httpService from 'src/utils/httpService';
import api from 'src/enums/api';
import qs from 'query-string';
export const GET_ALL_TRANSACTIONS = '@cnbot/fetch-all-transactions';
export const GET_ALL_FAIL_TRANSACTIONS = '@cnbot/fetch-all-fail-transactions';
export const GET_ALL_ORDER_FAILED = '@cnbot/fetch-all-order-failed';
export const GET_EARNING = '@cnbot/fetch-transaction-earning';
export const GET_EARNING_COINS = '@cnbot/fetch-transaction-earning-coins';
export const ADD_NEW_TRANSACTION = '@cnbot/add-new-transaction';
export const INITIAL_INSUFFICIENTS = '@cnbot/initial-insufficient-transactions';
export const ADD_INSUFFICIENT = '@cnbot/add-insufficient-transactions';

export function getAllTransactions(options) {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.transaction}?${qs.stringify(options)}`
    );

    dispatch({
      type: GET_ALL_TRANSACTIONS,
      payload: response
    });
  };
}

export function getAllFailTransactions(options) {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.transaction}?${qs.stringify(options)}`
    );

    dispatch({
      type: GET_ALL_FAIL_TRANSACTIONS,
      payload: response
    });
  };
}

export async function getTransactionDetail(id) {
  return await httpService.makeRequest(
    'get',
    `${api.transaction}/${id}/detail`
  );
}

export function getEarnings(range = 'day', status = 'completed') {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.transaction}/earning?status=${status}&range=${range}`
    );

    dispatch({
      type: GET_EARNING,
      payload: response
    });
  };
}

export function getEarningCoins() {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.transaction}/earning-coins?&range=day&status=completed`
    );

    dispatch({
      type: GET_EARNING_COINS,
      payload: response
    });
  };
}

export function addTransaction(transaction) {
  return async dispatch => {
    dispatch({
      type: ADD_NEW_TRANSACTION,
      payload: transaction
    });
  };
}

export function getOrderFailed(options) {
  return async dispatch => {
    try {
      const response = await httpService.makeRequest(
        'get',
        `/transaction/orders?${qs.stringify({
          ...options,
          status: 'failed'
        })}`
      );

      dispatch({
        type: GET_ALL_ORDER_FAILED,
        payload: response
      });
    } catch (e) {}
  };
}
