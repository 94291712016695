import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Select,
  makeStyles,
  FormControl,
  Divider
} from '@material-ui/core';

import { routesPaths } from 'src/Routes';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedRole } from 'src/store/actions/settingActions';
import {
  BookOpen as BookOpenIcon,
  Play as PlayIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  LogOut as LogOutIcon
} from 'react-feather';
import { SvgIcon } from '@material-ui/core';
import Permission, { UserRoles } from 'src/components/Permission';
import SocketSwitch from './SocketSwitch';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 180
  },
  roles: {
    // margin: '0 20px 0 10px'
  },
  svgIcon: {
    marginRight: '10px',
    fontSize: 'medium'
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const { user, logout } = useAuth0();
  const roles = useSelector(state => state.setting.userMetadata.role);
  const selectedRole = useSelector(state => state.setting.selectedRole);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout({ returnTo: window.location.origin });
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const handleChangeRole = e => {
    dispatch(updateSelectedRole(e.target.value));
  };

  const renderRoles = () => {
    return (
      <FormControl
        className={classes.roles}
        variant="outlined"
        size="small"
        style={{ width: '100%' }}
      >
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={selectedRole}
          onChange={handleChangeRole}
        >
          {roles.map(role => (
            <MenuItem key={role} value={role}>
              {role.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Box display="flex">
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Hidden smDown>
          <Avatar
            alt={user.name}
            className={classes.avatar}
            src={user.picture}
          />
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem>
          <SocketSwitch />
        </MenuItem>
        <MenuItem>{roles.length > 1 && renderRoles()}</MenuItem>
        <MenuItem component={RouterLink} to={routesPaths.settings.accounts}>
          <SvgIcon className={classes.svgIcon}>
            <UserIcon />
          </SvgIcon>
          Accounts
        </MenuItem>
        <Divider />

        <MenuItem component={RouterLink} to={routesPaths.assets.balances}>
          <SvgIcon className={classes.svgIcon}>
            <BookOpenIcon />
          </SvgIcon>
          Assets
        </MenuItem>

        <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
          <MenuItem component={RouterLink} to={routesPaths.jobs}>
            <SvgIcon className={classes.svgIcon}>
              <PlayIcon />
            </SvgIcon>
            Jobs
          </MenuItem>
        </Permission>
        <Divider />
        <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
          <MenuItem
            component={RouterLink}
            to={routesPaths.settings.configuration}
          >
            <SvgIcon className={classes.svgIcon}>
              <SettingsIcon />
            </SvgIcon>
            Configuration
          </MenuItem>
          <Divider />
        </Permission>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <SvgIcon className={classes.svgIcon}>
            <LogOutIcon />
          </SvgIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Account;
