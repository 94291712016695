import React, { useCallback, useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { Button, Grid, makeStyles, SvgIcon } from '@material-ui/core';
import { routesPaths } from 'src/Routes';
import DataTable from 'src/components/DataTable';
import Withdraw from './components/Withdraw';
import Label from 'src/components/Label';
import moment from 'moment';
import CoinCell from 'src/components/CoinCell';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshCw } from 'react-feather';
import {
  fetchAllTransferJobs,
  fetchAllTransfers
} from 'src/store/actions/transferActions';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import AccountCell from 'src/components/AccountCell';
import httpService from 'src/utils/httpService';
import api from 'src/enums/api';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  exchangeImage: {
    height: 30
  }
}));

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pageOptions = qs.parse(location.search);

  const transfers = useSelector(state => state.transfer.list);
  const paginationOptions = useSelector(state => state.transfer.listOptions);
  const [queueList, setQueueList] = useState([]);

  const fetchQueue = useCallback(async () => {
    const queue = await httpService.makeRequest('get', `${api.transfer}/queue`);
    setQueueList(queue);
  }, []);

  useEffect(() => {
    fetchQueue();
    if (pageOptions.page) {
      dispatch(fetchAllTransfers(pageOptions));
    }
    dispatch(fetchAllTransferJobs());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pageOptions = qs.parse(location.search);

    if (paginationOptions.page !== pageOptions.page) {
      dispatch(fetchAllTransfers(pageOptions));
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Transfer',
      link: routesPaths.transfer.root
    }
  ];

  const queueListColumns = [
    {
      key: 'requestedDate',
      title: 'Date',
      renderCell: createdAt => (
        <>
          {moment(createdAt).format('DD.MM.YYYY')} <br />
          {moment(createdAt).format('HH:mm:ss')}
        </>
      )
    },

    {
      key: 'coin',
      title: 'Coin',
      renderCell: (j, row) => <CoinCell coin={row.address.coin} />
    },
    {
      key: 'account',
      title: 'From',
      renderCell: acc => <AccountCell account={acc} />
    },
    {
      key: 'to',
      title: 'to',
      renderCell: (j, row) => <AccountCell account={row.address.account} />
    },
    {
      key: 'amount',
      title: 'Amount'
    }
  ];

  const columns = [
    {
      key: 'triggeredDate',
      title: 'Date',
      renderCell: createdAt => (
        <>
          {moment(createdAt).format('DD.MM.YYYY')} <br />
          {moment(createdAt).format('HH:mm:ss')}
        </>
      )
    },
    {
      key: 'transferJob',
      title: 'Coin',
      renderCell: (j, row) => <CoinCell coin={row.coin} />
    },
    {
      key: 'from',
      title: 'From',
      renderCell: acc => <AccountCell account={acc} />
    },
    {
      key: 'to',
      title: 'To',
      renderCell: acc => <AccountCell account={acc} />
    },
    {
      key: 'amount',
      title: 'Amount'
    },
    {
      key: 'externalId',
      title: 'Withdraw',
      renderCell: (externalId, record) => {
        const label =
          externalId && externalId !== 'undefined' && record.status !== 'error'
            ? 'success'
            : 'error';
        return <Label color={label}>{label}</Label>;
      }
    },
    {
      key: 'hash',
      title: 'Deposit',
      renderCell: (hash, record) => {
        const statusLabel =
          record.status !== 'completed' && record.status !== 'error'
            ? 'pending'
            : 'success';
        return (
          <Label color={statusLabel === 'pending' ? 'info' : 'success'}>
            {statusLabel}
          </Label>
        );
      }
    },
    {
      key: 'transferType',
      title: 'Type',
      renderCell: status => <Label>{status}</Label>
    },
    {
      key: 'fee',
      title: 'Fee'
    },
    {
      key: 'feeUsdt',
      title: 'FeeUsdt'
    }
  ];

  if (!pageOptions.page) {
    setTimeout(() => {
      history.push({
        pathname: routesPaths.transfer.root,
        search: `?${qs.stringify({ ...pageOptions, page: 1 })}`
      });
    }, 300);
  }

  const handlePageChange = (e, pageNumber) => {
    const newOptions = { page: pageNumber + 1 };
    history.push({
      pathname: routesPaths.transfer.root,
      search: `?${qs.stringify(newOptions)}`
    });
    //TODO: open?
    //dispatch(fetchAllTransfers(newOptions));
  };
  const renderButton = () => (
    <>
      <Button
        color="inherit"
        variant="contained"
        className={classes.action}
        onClick={() => {
          fetchAllTransfers({ page: 1 });
          fetchQueue();
          history.replace(`${location.pathname}?page=1`);
        }}
      >
        <SvgIcon fontSize="small" className={classes.actionIcon}>
          <RefreshCw />
        </SvgIcon>
        Refresh
      </Button>
    </>
  );

  return (
    <Page
      title="Transfer Dashboard"
      breadcrumbs={breadcrumbs}
      rightContainer={renderButton()}
    >
      <Grid container spacing={3}>
        <Grid item lg={4} xl={4} xs={12}>
          <Withdraw onSubmit={() => fetchQueue()} />
        </Grid>
        <Grid item lg={8} xl={8} xs={12}>
          <div></div>
          {queueList.length > 0 && (
            <DataTable
              head="Transfer Queue"
              columns={queueListColumns}
              source={queueList}
            />
          )}
          <DataTable
            head="Transfer History"
            columns={columns}
            source={transfers}
            pagination={
              paginationOptions && {
                ...paginationOptions,
                page: paginationOptions.selectedPage,
                onChangePage: handlePageChange
              }
            }
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default Dashboard;
