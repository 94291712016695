import React, { useEffect } from 'react';
import { makeStyles, IconButton, SvgIcon, Typography } from '@material-ui/core';

import { routesPaths } from 'src/Routes';

import { useHistory, useLocation } from 'react-router';

import { Link as RouterLink } from 'react-router-dom';
import DataTable from 'src/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderFailed } from 'src/store/actions/transactionActions';
import Label from 'src/components/Label';
import moment from 'moment';
import { EXCHANGES_LOGO, COINS_ICONS } from 'src/enums/common';
import qs from 'query-string';
import { ArrowRight as ArrowRightIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  exchangeImage: {
    height: 15
  },
  coinIcon: {
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  commissionRate: {
    display: 'block',
    fontSize: 10
  },
  coinCell: {
    display: 'flex',
    alignItems: 'center'
  },
  coinLabel: {
    marginLeft: 10
  },
  earningCurrencyCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  earningCurrency: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 5,
    '& > svg': {
      width: 16,
      height: 'auto'
    }
  },
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  }
}));

export const transactionStatus = {
  completed: {
    text: 'Completed',
    color: 'success'
  },
  test: {
    text: 'Test',
    color: 'info'
  },
  insufficient: {
    text: 'Insufficient',
    color: 'info'
  },
  failed: {
    text: 'Failed',
    color: 'error'
  },
  // eslint-disable-next-line no-useless-computed-key
  ['in-progress']: {
    text: 'In Progress',
    color: 'warning'
  },
  success: {
    text: 'Success',
    color: 'success'
  }
};

export function getTransactionStatusType(statusType) {
  const { text, color } = transactionStatus[statusType];

  return <Label color={color}>{text}</Label>;
}

function OrderFailedTable({ options }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const ordersFailed = useSelector(state => state.transaction.orderFailed);
  const paginationOptions = useSelector(state => state.transaction.listOptions);

  // const roleRatio = useSelector(state => state.setting.selectedRatio);
  // const userRole = useSelector(state => state.setting.selectedRole);

  // const roundPrice = price => {
  //   return price * roleRatio;
  // };

  useEffect(() => {
    const pageOptions = qs.parse(location.search);

    if (paginationOptions.page !== pageOptions.page) {
      dispatch(getOrderFailed(pageOptions));
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getOrderFailed(options));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = [
    {
      key: 'createdAt',
      title: 'Created At',
      align: 'left',
      width: 120,
      renderCell: (createdAt, record) => {
        return (
          <>
            {moment(createdAt).format('DD.MM.YYYY')} <br />
            {moment(createdAt).format('HH:mm:ss')}
          </>
        );
      }
    },
    {
      key: 'coin',
      title: 'Coin',
      renderCell: coin => {
        return (
          <div className={classes.coinCell}>
            <span className={classes.coinIcon}>{COINS_ICONS[coin]}</span>
            <Typography
              variant="inherit"
              className={classes.coinLabel}
              color="textPrimary"
            >
              {coin}
            </Typography>
          </div>
        );
      }
    },
    {
      key: 'exchange',
      title: 'Exchange',
      renderCell: (exchange, record) => (
        <div className={classes.exchangeCell}>{EXCHANGES_LOGO[exchange]}</div>
      )
    },
    {
      key: 'pair',
      title: 'Pair'
    },
    {
      key: 'quantity',
      title: 'Quantity'
    },
    {
      key: 'price',
      title: 'Price'
    },
    {
      key: 'side',
      title: 'Side'
    },
    {
      key: 'status',
      title: 'Status',
      align: 'right',
      renderCell: status => getTransactionStatusType(status)
    },
    {
      key: 'orderErrorMessage',
      title: 'Message',
      width: 300
    },
    {
      key: 'transaction',
      title: '',
      align: 'right',
      renderCell: (transaction, row) => {
        return (
          <IconButton
            component={RouterLink}
            to={`/app/transaction/${transaction}/detail`}
          >
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        );
      }
    }
  ];

  const handlePageChange = (e, pageNumber) => {
    const newOptions = { ...options, page: pageNumber + 1 };
    history.push({
      pathname: routesPaths.transactions,
      search: `?${qs.stringify(newOptions)}`
    });
    dispatch(getOrderFailed(newOptions));
  };

  return (
    <DataTable
      columns={columns}
      source={ordersFailed}
      pagination={
        paginationOptions && {
          ...paginationOptions,
          onChangePage: handlePageChange
        }
      }
    />
  );
}

export default OrderFailedTable;
