/* eslint-disable no-param-reassign */
import produce from 'immer';
import { MinorCoinEnum } from 'src/enums/common';
import {
  GET_TICKER,
  GET_TICKERS,
  GET_TOTAL_BALANCES,
  INITIAL_BALANCES,
  UPDATE_BALANCES,
  GET_EXTRA_BALANCE,
  GET_TOTAL_HOLDINGS,
  GET_UNAVAILABLE_BALANCE
} from '../actions/balancesActions';

// const defaultBalance = { free: 0, used: 0, total: 0 };
// const emptyBalances = {
//   lastUpdated: '2020-10-15T16:03:34.892Z',
//   commissionRatio: { taker: 0.00125, maker: 0.00125 },
//   coinBalance: {
//     BTC: defaultBalance,
//     XRP: defaultBalance,
//     NEO: defaultBalance,
//     HOT: defaultBalance,
//     TTR: defaultBalance,
//     USD: defaultBalance,
//     ETH: defaultBalance,
//     XLM: defaultBalance,
//     LTC: defaultBalance,
//     LINK: defaultBalance,
//     BNB: defaultBalance,
//     DASH: defaultBalance,
//     ATOM: defaultBalance,
//     XTZ: defaultBalance
//   },
//   minorCoinBalance: {
//     TRY: defaultBalance,
//     USDT: defaultBalance
//   }
// };

const initialState = {
  data: {},
  extraBalance: {},
  totalHolding: 0,
  unavailableBalance: {},
  ticker: 0,
  tickers: {},
  totalBalances: 0
};

const balancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTRA_BALANCE: {
      return produce(state, draft => {
        draft.extraBalance = action.payload;
      });
    }

    case GET_TOTAL_HOLDINGS: {
      return produce(state, draft => {
        draft.totalHolding = action.payload;
      });
    }

    case GET_UNAVAILABLE_BALANCE: {
      return produce(state, draft => {
        draft.unavailableBalance = action.payload;
      });
    }

    case INITIAL_BALANCES: {
      const { balances } = action;

      return produce(state, draft => {
        const keys = Object.keys(balances);
        // draft.data.huobi = emptyBalances;
        // draft.data.okex = emptyBalances;
        // draft.data.bitexen = emptyBalances;

        keys.forEach(key => {
          draft.data[key] = balances[key];
        });

        const extraExchanges = Object.keys(state.extraBalance);
        extraExchanges.forEach(extraExchange => {
          if (draft.data[extraExchange]) {
            const coins = Object.keys(state.extraBalance[extraExchange]);
            coins.forEach(coin => {
              const extraCoin = draft.extraBalance[extraExchange];
              if (Object.values(MinorCoinEnum).includes(coin)) {
                draft.data[extraExchange].minorCoinBalance[coin].total +=
                  extraCoin[coin];
                draft.data[extraExchange].minorCoinBalance[coin].free +=
                  extraCoin[coin];
              } else {
                draft.data[extraExchange].coinBalance[coin].total +=
                  extraCoin[coin];
                draft.data[extraExchange].coinBalance[coin].free +=
                  extraCoin[coin];
              }
            });
          }
        });
      });
    }

    case UPDATE_BALANCES: {
      const { balances, exchange } = action;

      return produce(state, draft => {
        draft.data[exchange] = balances;

        if (state.extraBalance[exchange]) {
          const coins = Object.keys(state.extraBalance[exchange]);
          coins.forEach(coin => {
            const extraCoin = draft.extraBalance[exchange];
            if (Object.values(MinorCoinEnum).includes(coin)) {
              draft.data[exchange].minorCoinBalance[coin].total +=
                extraCoin[coin];
              draft.data[exchange].minorCoinBalance[coin].free +=
                extraCoin[coin];
            } else {
              draft.data[exchange].coinBalance[coin].total += extraCoin[coin];
              draft.data[exchange].coinBalance[coin].free += extraCoin[coin];
            }
          });
        }
      });
    }

    case GET_TICKER: {
      return produce(state, draft => {
        draft.ticker = action.payload;
      });
    }

    case GET_TICKERS: {
      return produce(state, draft => {
        draft.tickers[action.payload.exchange] = action.payload.tickers;
      });
    }

    case GET_TOTAL_BALANCES: {
      return produce(state, draft => {
        draft.totalBalances = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default balancesReducer;
