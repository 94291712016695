import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Play as PlayIcon,
  Trash2 as DeleteIcon,
  Pause as PauseIcon,
  Edit as EditIcon
} from 'react-feather';
import DataTable from 'src/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import MachineDetailCell from 'src/views/jobs/components/MachineDetailCell';
import {
  startJob,
  stopJob,
  deleteJob,
  JobStatus,
  getAllJobs
} from 'src/store/actions/jobsActions';
import ActionMenu from 'src/components/ActionMenu';
import Label from 'src/components/Label';
import socket, { SocketEvents } from 'src/utils/socket';
import {
  getMachineDetail,
  fetchAllMachine
} from 'src/store/actions/machineAction';
import { COINS_ICONS, EXCHANGES_ICON, EXCHANGES_LOGO } from 'src/enums/common';
import { UserRoles } from 'src/components/Permission';
import MultiSelect from 'src/components/MultiSelect';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {},
  exchangeImage: {
    height: 15,
    display: 'block',
    '&:last-child': {
      marginBottom: 0
    }
  },
  coinCell: {
    display: 'flex',
    padding: '10px 0'
  },
  exchangeCoin: {
    '& > svg': {
      height: 22,
      width: 'auto',
      marginRight: 10
    }
  },
  exchangeIcon: {
    display: 'inline-block',
    '& > svg': {
      height: 22,
      width: 'auto',
      marginRight: 10
    }
  },
  jobRow: {
    verticalAlign: 'top'
  },
  status: {
    marginTop: 15
  },
  account: {
    marginTop: 10
  }
}));

function JobAction({ job, onSelectJob }) {
  const dispatch = useDispatch();

  const actionMenuList = [
    {
      label: `Start ${job.coin}`,
      key: 'start',
      icon: <PlayIcon />,
      onClick: () => dispatch(startJob(job.id)),
      disabled:
        job.status === JobStatus.Started ||
        job.status === JobStatus.PreparingMachines
    },
    {
      label: `Stop ${job.coin}`,
      key: 'stop',
      icon: <PauseIcon />,
      onClick: () => dispatch(stopJob(job.id)),
      disabled:
        job.status === JobStatus.Stopped ||
        job.status === JobStatus.Ready ||
        job.status === JobStatus.PreparingMachines
    },
    {
      label: `Edit ${job.coin}`,
      key: 'edit',
      icon: <EditIcon />,
      onClick: () => {
        onSelectJob(job);
      }
    },
    {
      label: `Delete ${job.coin}`,
      key: 'delete',
      icon: <DeleteIcon />,
      onClick: async () => {
        await dispatch(deleteJob(job.id));
        await dispatch(fetchAllMachine());
        await dispatch(getAllJobs());
      },
      disabled: job.status === JobStatus.PreparingMachines,
      permission: UserRoles.DEVELOPER
    }
  ];
  return <ActionMenu key={job.id} menuList={actionMenuList} />;
}

const localStorageSelectedCoinsKey = 'cbot-ls-jobs-coins';

function JobTable({ showDetail, setIsOpenEditJobPanel, onSelectJob }) {
  const classes = useStyles();
  const jobs = useSelector(state => state.jobs.coins);
  const dispatch = useDispatch();

  const [selectedCoins, setSelectedCoins] = useState([]);

  useEffect(() => {
    setSelectedCoins(Object.keys(jobs));
  }, [jobs]);

  const subscribeChangeMachine = data => {
    if (data.machineId) {
      dispatch(getMachineDetail(data.machineId));
    }
  };

  useEffect(() => {
    socket.subscribe(SocketEvents.ChangedMachineStatus, subscribeChangeMachine);
    return () => {
      socket.unsubscribe(
        SocketEvents.ChangedMachineStatus,
        subscribeChangeMachine
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSelectedCoin = value => setSelectedCoins(value);

  const columns = [
    {
      key: 'coin',
      title: 'Coin',
      width: '180px',
      variant: 'head',
      renderCell: coin => {
        return (
          <div className={classes.coinCell}>
            <span className={classes.exchangeCoin}>{COINS_ICONS[coin]}</span>
            <span>{coin}</span>
          </div>
        );
      },
      renderTitle: () => (
        <MultiSelect
          id={localStorageSelectedCoinsKey}
          name="Coins"
          items={Object.keys(jobs)}
          selectedItems={selectedCoins}
          onChange={onChangeSelectedCoin}
          clear="empty"
        />
      )
    },
    {
      key: 'accounts',
      title: 'Accounts',
      renderCell: accounts => {
        return accounts.map(account => {
          return (
            <div
              key={account.exchange}
              className={`${classes.account} ${
                !showDetail ? classes.exchangeIcon : ''
              }`}
            >
              {showDetail
                ? EXCHANGES_LOGO[account.exchange]
                : EXCHANGES_ICON[account.exchange]}
            </div>
          );
        });
      }
    },
    ...(showDetail
      ? [
          {
            key: 'books',
            title: 'Books',
            renderCell: (book, job) => {
              return job.machines.map(machine => {
                return (
                  machine.machineType === 'books' && (
                    <MachineDetailCell
                      key={machine.id}
                      machineId={machine.id}
                    />
                  )
                );
              });
            }
          }
        ]
      : []),
    ...(showDetail
      ? [
          {
            key: 'transaction',
            title: 'Transaction',
            renderCell: (book, job) => {
              return job.machines.map(machine => {
                return (
                  machine.machineType === 'transaction' && (
                    <MachineDetailCell
                      key={machine.id}
                      machineId={machine.id}
                    />
                  )
                );
              });
            }
          }
        ]
      : []),
    {
      key: 'status',
      title: 'Status',
      align: 'right',
      renderCell: status => {
        return (
          <Label
            className={classes.status}
            color={status === 'started' ? 'success' : 'error'}
          >
            {status}
          </Label>
        );
      }
    },
    {
      key: 'action',
      title: 'Action',
      width: '40px',
      align: 'right',
      renderCell: (book, job) => {
        return (
          <JobAction
            job={job}
            onSelectJob={onSelectJob}
            setIsOpenEditJobPanel={setIsOpenEditJobPanel}
          />
        );
      }
    }
  ];

  const sortedJobs = _.orderBy(jobs, ['coin'], ['asc']);

  return (
    <DataTable
      columns={columns}
      rowClassName={classes.jobRow}
      size={showDetail ? 'medium' : 'small'}
      source={Object.values(sortedJobs).filter(j =>
        selectedCoins.includes(j.coin)
      )}
    />
  );
}

export default JobTable;
