import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import SplashScreen from 'src/components/SplashScreen';
import authService from 'src/services/authService';
import {
  fetchRoleRatio,
  fetchExtraBalance,
  fetchTotalHolding,
  fetchReplaceCoins,
  fetchUnavailableBalance,
  addUserMetadata,
  updateRoleRatio,
  updateReplaceCoins
} from 'src/store/actions/settingActions';
import {
  updateExtraBalance,
  updateUnavailableBalance,
  updateTotalHolding
} from 'src/store/actions/balancesActions';
import { fetchAllAccounts } from 'src/store/actions/accountsActions';
import socket from 'src/utils/socket';

function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(true);
  const [isReadyInit, setIsReadyInit] = useState(false);
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    error
  } = useAuth0();

  const setSettings = async () => {
    const responseRoleRatio = await fetchRoleRatio();
    const responseExtraBalance = await fetchExtraBalance();
    const responseTotalHolding = await fetchTotalHolding();
    const responseReplaceCoins = await fetchReplaceCoins();
    const responseUnavailableaBalance = await fetchUnavailableBalance();

    dispatch(fetchAllAccounts());
    dispatch(updateRoleRatio(responseRoleRatio?.value));
    dispatch(updateTotalHolding(responseTotalHolding?.value));
    dispatch(updateExtraBalance(responseExtraBalance?.value));
    dispatch(updateReplaceCoins(responseReplaceCoins?.value));
    dispatch(updateUnavailableBalance(responseUnavailableaBalance?.value));

    dispatch(addUserMetadata(user));
    socket.connect();
    setTimeout(() => {
      setIsReadyInit(true);
    }, 500);
  };

  useEffect(() => {
    const initAuth = async () => {
      if (isAuthenticated && !isLoading) {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://qtabapp.eu.auth0.com/api/v2/',
          scope: 'openid profile email'
        });

        authService.handleAuthentication(accessToken);
        setTimeout(() => {
          setWaiting(false);
        }, 500);
      }
    };
    if (isAuthenticated && !waiting && !isReadyInit) {
      setSettings();
    }

    initAuth();
  }, [dispatch, isAuthenticated, isLoading, waiting]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error && !isLoading) {
    return 'Opps';
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isAuthenticated && !isLoading) {
    return loginWithRedirect();
  }

  if (!isReadyInit) {
    return <SplashScreen />;
  }

  return !waiting && children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
