import React, { useEffect } from 'react';
import {
  Grid,
  IconButton,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  FormControl
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Circle as CircleIcon,
  Trash as ClearIcon
} from 'react-feather';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  selectItem: {
    marginRight: 10
  }
}));

function MultiSelect({
  id,
  name,
  items = [],
  selectedItems = [],
  onChange,
  save = false,
  clear = 'none'
}) {
  const classes = useStyles();

  useEffect(() => {
    if (save && id && onChange) {
      onChange(localStorage.getItem(id)?.split(',') || selectedItems);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = event => {
    const value = event.target.value;
    const result = items.filter(item => value.indexOf(item) > -1);
    if (save && id) {
      if (result.length > 0) {
        localStorage.setItem(id, result.join(','));
      } else {
        localStorage.removeItem(id);
      }
    }
    onChange && onChange(result);
  };

  const handleClear = () => {
    if (save && id) {
      localStorage.removeItem(id);
    }
    onChange && onChange(clear === 'all' ? items : []);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <FormControl variant="outlined" size="small">
          <Select
            id={id}
            multiple
            value={selectedItems}
            displayEmpty
            renderValue={() => name || id || ''}
            onChange={handleOnChange}
          >
            {items.map(name => (
              <MenuItem key={name} value={name}>
                <SvgIcon fontSize="small" className={classes.selectItem}>
                  {selectedItems.indexOf(name) > -1 ? (
                    <CheckCircleIcon color="green" />
                  ) : (
                    <CircleIcon />
                  )}
                </SvgIcon>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {clear !== 'none' && (
          <IconButton color="inherit" onClick={handleClear}>
            <SvgIcon fontSize="small">
              <ClearIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

MultiSelect.propTypes = {
  clear: PropTypes.oneOf(['empty', 'all', 'none'])
};

export default MultiSelect;
