import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Breadcrumbs,
  Link
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    },
    paddingBottom: theme.spacing(2)
  },
  title: {
    marginBottom: 20
  }
}));

const Page = forwardRef(
  (
    { title, breadcrumbs, maxWidth = false, rightContainer, children, ...rest },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div ref={ref} {...rest}>
        <Container maxWidth={maxWidth} className={classes.container}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              {breadcrumbs && (
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs.map((item, index) =>
                    index !== breadcrumbs.length - 1 ? (
                      <Link
                        key={item.label}
                        variant="body1"
                        color="inherit"
                        to={item.link}
                        component={RouterLink}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <Typography
                        variant="body1"
                        key={`${item.label}-s`}
                        color="textPrimary"
                      >
                        {item.label}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              )}
              <Typography
                variant="h3"
                className={classes.title}
                color="textPrimary"
              >
                {title}
              </Typography>
            </Grid>
            {rightContainer && <Grid item>{rightContainer}</Grid>}
          </Grid>
          {children}
        </Container>
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array
};

export default Page;
