import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { EXCHANGES_LOGO } from 'src/enums/common';
import CoinItem from './CoinItem';
import { useFilteredCoin } from 'src/components/Permission';
import MultiSelect from 'src/components/MultiSelect';
import { useSelector } from 'react-redux';
import { UserRoles } from 'src/components/Permission';

const useStyles = makeStyles(theme => ({
  root: {},
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  stickyCol: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.default
  },
  tabCell: {
    paddingBottom: 5,
    paddingTop: 5
  }
}));

const defaultCoinVal = { free: 0, used: 0, total: 0 };

const localStorageSelectedCoinsKey = 'cbot-ls-balance-coins';
const allowExchanges = ['binance', 'paribu', 'koinim', 'coinzo', 'btcturk'];

function BalanceBox({ balances }) {
  const classes = useStyles();

  const coins = useFilteredCoin(true, true);
  const userRole = useSelector(state => state.setting.selectedRole);
  const [selectedCoins, setSelectedCoins] = useState(coins);

  useEffect(() => {
    setSelectedCoins(coins);
  }, [coins]);

  if (!balances) {
    return null;
  }

  let exchanges = Object.keys(balances);
  if (userRole === UserRoles.DEVELOPER || userRole === UserRoles.MAINTAINER) {
    exchanges = exchanges.filter(e => allowExchanges.includes(e));
    exchanges = exchanges.sort();
  }

  const onChangeSelectedCoin = value => setSelectedCoins(value);

  return (
    <Box width="100%">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyCol}>
              <MultiSelect
                id={localStorageSelectedCoinsKey}
                name="Coins"
                items={coins}
                selectedItems={selectedCoins}
                onChange={onChangeSelectedCoin}
                clear="all"
                save
              />
            </TableCell>
            {exchanges.map(exchange => (
              <TableCell
                key={exchange}
                align="right"
                className={classes.tabCell}
              >
                {balances[exchange] && (
                  <>
                    <Tooltip
                      placement="right"
                      title={
                        <span>
                          {balances[exchange]?.commissionRatio?.taker}/
                          {balances[exchange]?.commissionRatio?.maker}
                        </span>
                      }
                    >
                      <Typography variant="h6" color="textPrimary">
                        <span className={classes.exchangeCell}>
                          {EXCHANGES_LOGO[exchange] || exchange}
                        </span>
                      </Typography>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            ))}
            <TableCell align="right" className={classes.tabCell}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedCoins.map(coin => {
            return (
              <CoinItem
                key={coin || defaultCoinVal}
                coin={coin || defaultCoinVal}
                exchanges={exchanges}
                balances={balances}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

export default BalanceBox;
