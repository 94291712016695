/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_REPORTS } from 'src/store/actions/reportActions';

const initialState = {
  totalTRY: 0,
  totalUSDT: 0,
  list: []
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS: {
      return produce(state, draft => {
        draft.list = action.payload.list;
        draft.totalTRY = action.payload.totalTRY;
        draft.totalUSDT = action.payload.totalUSDT;
      });
    }

    default: {
      return state;
    }
  }
};

export default reportReducer;
