import React from 'react';
import PropTypes from 'prop-types';
import {
  DollarSign as TransferIcon,
  Activity as DashboardIcon,
  MapPin as AddressesIcon
} from 'react-feather';
import { routesPaths } from 'src/Routes';
import SideBar from '../components/SideBar';

function TransferLayout({ children }) {
  const navConfig = [
    {
      subheader: 'Transfer',
      items: [
        {
          title: 'Dashboard',
          icon: DashboardIcon,
          href: routesPaths.transfer.root
        },
        {
          title: 'Transfer Jobs',
          icon: TransferIcon,
          href: routesPaths.transfer.jobs
        },
        {
          title: 'Addresses',
          icon: AddressesIcon,
          href: routesPaths.transfer.address
        }
      ]
    }
  ];

  return <SideBar config={navConfig}>{children}</SideBar>;
}

TransferLayout.propTypes = {
  children: PropTypes.any
};

export default TransferLayout;
