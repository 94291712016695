import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  IconButton,
  SvgIcon,
  Typography,
  Tooltip
} from '@material-ui/core';
import { routesPaths } from 'src/Routes';
import { useHistory, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import DataTable from 'src/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTransactions } from 'src/store/actions/transactionActions';
import Label from 'src/components/Label';
import moment from 'moment';
import { EXCHANGES_LOGO, COINS_ICONS } from 'src/enums/common';
import qs from 'query-string';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { useCoins } from 'src/hooks/useCoins';

const useStyles = makeStyles(theme => ({
  root: {},
  exchangeImage: {
    height: 15
  },
  coinIcon: {
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  commissionRate: {
    display: 'block',
    fontSize: 10
  },
  coinCell: {
    display: 'flex',
    alignItems: 'center'
  },
  coinLabel: {
    marginLeft: 10
  },
  earningCurrencyCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  earningCurrency: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 5,
    '& > svg': {
      width: 16,
      height: 'auto'
    }
  },
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  }
}));

export const transactionStatus = {
  completed: {
    text: 'Completed',
    color: 'success'
  },
  canceled: {
    text: 'Canceled',
    color: 'info'
  },
  test: {
    text: 'Test',
    color: 'info'
  },
  insufficient: {
    text: 'Insufficient',
    color: 'info'
  },
  failed: {
    text: 'Failed',
    color: 'error'
  },
  // eslint-disable-next-line no-useless-computed-key
  ['in-progress']: {
    text: 'In Progress',
    color: 'warning'
  },
  success: {
    text: 'Success',
    color: 'success'
  },
  error: {
    text: 'Error',
    color: 'error'
  }
};

export function getTransactionStatusType(statusType) {
  const { text, color } = transactionStatus[statusType];

  return <Label color={color}>{text}</Label>;
}

function TransactionsTable({ options }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const transactions = useSelector(state => state.transaction.list);
  const paginationOptions = useSelector(state => state.transaction.listOptions);

  const { coinFormat, pairFormat } = useCoins();

  const roleRatio = useSelector(state => state.setting.selectedRatio);
  const userRole = useSelector(state => state.setting.selectedRole);

  const [highlightId, setHighlightId] = useState([]);

  const roundPrice = price => {
    return price * roleRatio;
  };

  useEffect(() => {
    if (location.pathname !== '/app/dashboard') {
      const pageOptions = qs.parse(location.search);

      if (paginationOptions.page !== pageOptions.page) {
        dispatch(getAllTransactions(pageOptions));
      }
    }
  }, [location.search, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAllTransactions(options));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHighlightId(transactions?.filter(i => i.newFlag).map(i => i.id));
  }, [transactions]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      key: 'createdAt',
      title: 'Created At',
      align: 'left',
      width: 120,
      renderCell: (createdAt, record) => {
        return (
          <>
            {moment(createdAt).format('DD.MM.YYYY')} <br />
            {moment(createdAt).format('HH:mm:ss')}
          </>
        );
      }
    },
    {
      key: 'coin',
      title: 'Coin',
      renderCell: coin => {
        return (
          <div className={classes.coinCell}>
            <span className={classes.coinIcon}>
              {COINS_ICONS[coinFormat(coin)]}
            </span>
            <Typography
              variant="inherit"
              className={classes.coinLabel}
              color="textPrimary"
            >
              {coinFormat(coin)}
            </Typography>
          </div>
        );
      }
    },
    {
      key: 'fromExchange',
      title: 'From',
      renderCell: (exchange, record) => (
        <Tooltip title={record.commissionRate[0]} placement="top" arrow>
          <div className={classes.exchangeCell}>
            {EXCHANGES_LOGO[exchange]}
            <span className={classes.commissionRate}>
              {pairFormat(record.fromPair)}
            </span>
          </div>
        </Tooltip>
      )
    },
    {
      key: 'toExchange',
      title: 'To',
      renderCell: (exchange, record) => (
        <Tooltip title={record.commissionRate[1]} placement="top" arrow>
          <div className={classes.exchangeCell}>
            {EXCHANGES_LOGO[exchange]}
            <span className={classes.commissionRate}>
              <span className={classes.commissionRate}>
                {pairFormat(record.toPair)}
              </span>
            </span>
          </div>
        </Tooltip>
      )
    },
    {
      key: 'quantity',
      title: 'Quantity'
    },
    {
      key: 'earning',
      title: 'Earning',
      align: 'right',
      renderCell: (e, record) => {
        return (
          <span className={classes.earningCurrencyCell}>
            {roundPrice(e)?.toFixed(2)}{' '}
            <span className={classes.earningCurrency}>
              {COINS_ICONS[record.earningCurrency]}
            </span>
          </span>
        );
      }
    }
  ];

  if (userRole !== 'reporter' && userRole !== 'standard') {
    columns.push({
      key: 'btn',
      title: '',
      align: 'right',
      renderCell: (btn, row) => {
        return (
          <IconButton
            component={RouterLink}
            to={`/app/transaction/${row.id}/detail`}
          >
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        );
      }
    });
  }

  const handlePageChange = (e, pageNumber) => {
    const newOptions = { ...options, page: pageNumber + 1 };
    history.push({
      pathname: routesPaths.transactions,
      search: `?${qs.stringify(newOptions)}`
    });
    dispatch(getAllTransactions(newOptions));
  };

  return (
    <DataTable
      columns={columns}
      source={transactions}
      highlightedRow={highlightId}
      highlightColor="warning-overlay"
      pagination={
        paginationOptions && {
          ...paginationOptions,
          onChangePage: handlePageChange
        }
      }
    />
  );
}

export default TransactionsTable;
