import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CoinEnum } from 'src/enums/common';
import _ from 'lodash';

import { UserRoles } from 'src/components/Permission';

export const useCoins = () => {
  const [coins, setCoins] = useState(CoinEnum);
  const selectedRole = useSelector(state => state.setting.selectedRole);
  const replaceCoins = useSelector(state => state.setting.replaceCoins);

  useEffect(() => {
    let filteredCoin = CoinEnum;
    if (
      selectedRole === UserRoles.REPORTER ||
      selectedRole === UserRoles.STANDARD
    ) {
      const replacingCoins = Object.keys(replaceCoins);
      const allCoins = Object.keys(CoinEnum);

      filteredCoin = allCoins
        .filter(key => !replacingCoins.includes(key))
        .reduce((obj, key) => {
          obj[key] = CoinEnum[key];
          return obj;
        }, {});
    }
    const orderedCoins = {};

    _(filteredCoin)
      .keys()
      .sort()
      .each(function(key) {
        orderedCoins[key] = filteredCoin[key];
      });

    setCoins(orderedCoins);
  }, [setCoins, selectedRole, replaceCoins]);

  const pairFormat = useCallback(
    pair => {
      let formattedPair = pair;
      if (
        selectedRole === UserRoles.REPORTER ||
        selectedRole === UserRoles.STANDARD
      ) {
        const replacingCoins = Object.keys(replaceCoins);

        replacingCoins.forEach(coin => {
          if (pair.includes(coin)) {
            formattedPair = pair.replace(coin, replaceCoins[coin]);
          }
        });
      }
      return formattedPair;
    },
    [selectedRole, replaceCoins]
  );

  const coinFormat = useCallback(
    coin => {
      if (
        selectedRole === UserRoles.REPORTER ||
        selectedRole === UserRoles.STANDARD
      ) {
        const replacingCoins = Object.keys(replaceCoins);

        if (replacingCoins.includes(coin)) {
          return replaceCoins[coin];
        }
      }
      return coin;
    },
    [selectedRole, replaceCoins]
  );

  return { coins, pairFormat, coinFormat };
};
