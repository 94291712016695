import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  earningCurrencyCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  earningCurrency: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 5,
    '& > svg': {
      width: 16,
      height: 'auto'
    }
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  currency: {
    marginLeft: 10,
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  totalProfit: {
    color: '#ffffff',
    display: 'flex',
    '& > span': {
      marginLeft: 10
    }
  }
}));
