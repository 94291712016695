import React from 'react';
import { EXCHANGES_LOGO, MinorCoinEnum } from 'src/enums/common';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CoinCell from './CoinCell';
import { formatPrice } from 'src/utils/format';

const useStyles = makeStyles(theme => ({
  exchangeCell: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  row: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    paddingTop: 3,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: 3
  },
  commissionRate: {
    display: 'block',
    fontSize: 10
  }
}));

function AccountCell({ account, coin, balance }) {
  const classes = useStyles();
  const accounts = useSelector(state => state.accounts.list);
  const balances = useSelector(state => state.balances.data);

  const value = accounts[account];
  const balanceValue = balances[value?.exchange];

  return (
    <div className={classes.row}>
      <div className={classes.exchangeCell}>
        {EXCHANGES_LOGO[value?.exchange]}
        <span className={classes.commissionRate}>
          {value?.name ? value?.name : value?.exchange}
        </span>
      </div>
      {coin && (
        <>
          <Box width={5} height={1} />
          <CoinCell
            coin={coin}
            amount={
              balance &&
              formatPrice(
                MinorCoinEnum[coin]
                  ? balanceValue?.minorCoinBalance[coin]?.free
                  : balanceValue?.coinBalance[coin]?.free
              )
            }
          />
        </>
      )}
    </div>
  );
}

export default AccountCell;
