import React from 'react';
import { ReactComponent as TRY } from 'src/assets/coins/try.svg';
import { ReactComponent as USDT } from 'src/assets/coins/usdt.svg';
import { ReactComponent as BNB } from 'src/assets/coins/bnb.svg';

import { ReactComponent as BTC } from 'src/assets/coins/btc.svg';
import { ReactComponent as ETH } from 'src/assets/coins/eth.svg';
import { ReactComponent as XLM } from 'src/assets/coins/xlm.svg';
import { ReactComponent as XRP } from 'src/assets/coins/xrp.svg';
import { ReactComponent as NEO } from 'src/assets/coins/neo.svg';
import { ReactComponent as HOT } from 'src/assets/coins/hot.svg';
import { ReactComponent as LTC } from 'src/assets/coins/ltc.svg';
import { ReactComponent as LINK } from 'src/assets/coins/link.svg';
import { ReactComponent as DASH } from 'src/assets/coins/dash.svg';
import { ReactComponent as ATOM } from 'src/assets/coins/atom.svg';
import { ReactComponent as XTZ } from 'src/assets/coins/xtz.svg';
import { ReactComponent as MATIC } from 'src/assets/coins/matic.svg';
import { ReactComponent as ARB } from 'src/assets/coins/arb.svg';
import { ReactComponent as FLOKI } from 'src/assets/coins/floki.svg';
import { ReactComponent as SUI } from 'src/assets/coins/sui.svg';

import { ReactComponent as ADA } from 'src/assets/coins/ada.svg';
import { ReactComponent as AVAX } from 'src/assets/coins/avax.svg';
import { ReactComponent as DOT } from 'src/assets/coins/dot.svg';
import { ReactComponent as BTT } from 'src/assets/coins/btt.svg';
import { ReactComponent as CHZ } from 'src/assets/coins/chz.svg';
import { ReactComponent as DOGE } from 'src/assets/coins/doge.svg';
import { ReactComponent as RVN } from 'src/assets/coins/rvn.svg';
import { ReactComponent as TRX } from 'src/assets/coins/trx.svg';
import { ReactComponent as MKR } from 'src/assets/coins/mkr.svg';
import { ReactComponent as UNI } from 'src/assets/coins/uni.svg';
import { ReactComponent as SHIB } from 'src/assets/coins/shib.svg';
import { ReactComponent as ETC } from 'src/assets/coins/etc.svg';
import { ReactComponent as MINA } from 'src/assets/coins/mina.svg';
import { ReactComponent as SOL } from 'src/assets/coins/sol.svg';

import { ReactComponent as DUMMY } from 'src/assets/coins/dummy.svg';

import { ReactComponent as BTCTURK } from 'src/assets/exchanges/btcturk.svg';
import { ReactComponent as BTCTURK_ICON } from 'src/assets/exchanges/btcturk-icon.svg';
import { ReactComponent as BINANCE } from 'src/assets/exchanges/binance.svg';
import { ReactComponent as BINANCE_ICON } from 'src/assets/exchanges/binance-icon.svg';
import { ReactComponent as BITTREX } from 'src/assets/exchanges/bittrex.svg';
import { ReactComponent as PARIBU } from 'src/assets/exchanges/paribu.svg';
import { ReactComponent as KOINIM } from 'src/assets/exchanges/koinim.svg';
import { ReactComponent as COINZO } from 'src/assets/exchanges/coinzo.svg';
import { ReactComponent as PARIBU_ICON } from 'src/assets/exchanges/paribu-icon.svg';
import { ReactComponent as KOINIM_ICON } from 'src/assets/exchanges/koinim-icon.svg';
import { ReactComponent as COINZO_ICON } from 'src/assets/exchanges/coinzo-icon.svg';
import { ReactComponent as POLONIEX } from 'src/assets/exchanges/poloniex.svg';
import { ReactComponent as BITEXEN } from 'src/assets/exchanges/bitexen.svg';
import { Exchange } from '@cnbot/common';

export const EXCHANGES_LOGO = {
  btcturk: <BTCTURK />,
  binance: <BINANCE />,
  bittrex: <BITTREX />,
  paribu: <PARIBU />,
  koinim: <KOINIM />,
  coinzo: <COINZO />,
  poloniex: <POLONIEX />,
  bitexen: <BITEXEN />
};

export const EXCHANGES_ICON = {
  btcturk: <BTCTURK_ICON />,
  binance: <BINANCE_ICON />,
  bittrex: <BITTREX />,
  paribu: <PARIBU_ICON />,
  koinim: <KOINIM_ICON />,
  coinzo: <COINZO_ICON />,
  poloniex: <POLONIEX />,
  bitexen: <BITEXEN />
};

export const COINS_ICONS = {
  TRY: <TRY />,
  USDT: <USDT />,
  BNB: <BNB />,
  BTC: <BTC />,
  ETH: <ETH />,
  XLM: <XLM />,
  XRP: <XRP />,
  NEO: <NEO />,
  HOT: <HOT />,
  LTC: <LTC />,
  LINK: <LINK />,
  DASH: <DASH />,
  ATOM: <ATOM />,
  XTZ: <XTZ />,
  ADA: <ADA />,
  TRX: <TRX />,
  DOT: <DOT />,
  RVN: <RVN />,
  BTTC: <BTT />,
  DOGE: <DOGE />,
  CHZ: <CHZ />,
  AVAX: <AVAX />,
  MATIC: <MATIC />,
  FLOKI: <FLOKI />,
  SUI: <SUI />,
  ARB: <ARB />,

  MKR: <MKR />,
  UNI: <UNI />,
  SHIB: <SHIB />,

  MINA: <MINA />,
  SOL: <SOL />,

  LUNA: <DUMMY />,
  LUNC: <DUMMY />,

  ICP: <DUMMY />,
  MANTA: <DUMMY />,
  STRK: <DUMMY />,
  BONK: <DUMMY />,
  PEPE: <DUMMY />,
  ARKM: <DUMMY />,
  XAI: <DUMMY />,

  ETC: <ETC />
};

export const CoinEnum = {
  BNB: 'BNB',
  BTC: 'BTC',
  ETH: 'ETH',
  XLM: 'XLM',
  XRP: 'XRP',
  NEO: 'NEO',
  HOT: 'HOT',
  LTC: 'LTC',
  LINK: 'LINK',
  DASH: 'DASH',
  ATOM: 'ATOM',
  XTZ: 'XTZ',

  ADA: 'ADA',
  TRX: 'TRX',
  DOT: 'DOT',
  RVN: 'RVN',
  BTTC: 'BTTC',
  DOGE: 'DOGE',
  CHZ: 'CHZ',
  AVAX: 'AVAX',
  MKR: 'MKR',
  UNI: 'UNI',
  SHIB: 'SHIB',
  MATIC: 'MATIC',
  FLOKI: 'FLOKI',
  SUI: 'SUI',
  ARB: 'ARB',

  SOL: 'SOL',
  MINA: 'MINA',
  LUNA: 'LUNA',
  LUNC: 'LUNA',
  ICP: 'ICP',
  MANTA: 'MANTA',
  STRK: 'STRK',
  BONK: 'BONK',
  PEPE: 'PEPE',
  ARKM: 'ARKM',
  XAI: 'XAI',

  ETC: 'ETC'
};

export const MinorCoinEnum = {
  TRY: 'TRY',
  USDT: 'USDT'
};

export const Exchanges = {
  btcturk: 'btcturk',
  binance: 'binance',
  bittrex: 'bittrex'
};

export const CoinName = {
  [CoinEnum.BTC]: 'bitcoin',
  [CoinEnum.ETH]: 'ethereum',
  [CoinEnum.XLM]: 'stellar',
  [CoinEnum.XRP]: 'ripple',
  [CoinEnum.NEO]: 'neo',
  [CoinEnum.MATIC]: 'matic',
  [CoinEnum.FLOKI]: 'floki',
  [CoinEnum.SUI]: 'sui',
  [CoinEnum.ARB]: 'arb',
  [CoinEnum.HOT]: 'holo',
  [CoinEnum.LTC]: 'litecoin',
  [CoinEnum.LINK]: 'chainlink',
  [CoinEnum.BNB]: 'binance coin',
  [CoinEnum.DASH]: 'dash',
  [CoinEnum.ATOM]: 'atomic coin',
  [CoinEnum.XTZ]: 'tezos',

  [CoinEnum.ADA]: 'tezos',
  [CoinEnum.TRX]: 'tezos',
  [CoinEnum.DOT]: 'tezos',
  [CoinEnum.RVN]: 'tezos',
  [CoinEnum.BTTC]: 'tezos',
  [CoinEnum.DOGE]: 'tezos',
  [CoinEnum.CHZ]: 'tezos',
  [CoinEnum.AVAX]: 'tezos',

  [CoinEnum.MKR]: 'tezos',
  [CoinEnum.UNI]: 'tezos',

  [CoinEnum.ICP]: 'tezos',

  [CoinEnum.MANTA]: 'tezos',
  [CoinEnum.STRK]: 'tezos',
  [CoinEnum.BONK]: 'tezos',
  [CoinEnum.PEPE]: 'tezos',
  [CoinEnum.ARKM]: 'tezos',
  [CoinEnum.XAI]: 'tezos',

  [MinorCoinEnum.TRY]: 'trias',
  [MinorCoinEnum.USDT]: 'tether'
};

export const getCoinLogo = coin =>
  `https://raw.githubusercontent.com/dziungles/cryptocurrency-logos/master/coins/32x32/${CoinName[
    coin
  ].replace(' ', '-')}.png`;

export const Parity = {
  [Exchange.Binance]: ['USDT', 'TRY'],
  [Exchange.Btcturk]: ['USDT', 'TRY'],
  [Exchange.Paribu]: ['TRY'],
  [Exchange.Coinzo]: ['TRY'],
  [Exchange.Koinim]: ['TRY'],
  [Exchange.Bittrex]: ['USDT'],
  [Exchange.Poloniex]: ['USDT']
};
