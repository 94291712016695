let basePath = '/api/';

export default {
  login: `${basePath}auth/login`,
  loginHealth: `${basePath}auth/health`,
  common: `${basePath}common`,
  job: `${basePath}task`,
  user: `${basePath}user`,
  account: `${basePath}account`,
  machine: `${basePath}machine`,
  transaction: `${basePath}transaction`,
  order: `${basePath}order`,
  report: `${basePath}report`,
  setting: `${basePath}setting`,
  transfer: `${basePath}transfer`
};
