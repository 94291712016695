import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  TextField,
  IconButton,
  SvgIcon,
  Collapse
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import wait from 'src/utils/wait';
import { CoinEnum, MinorCoinEnum } from 'src/enums/common';
import DrawerPage from 'src/components/DrawerPage';
import Select from 'src/components/Select';
import CoinCell from 'src/components/CoinCell';
import {
  createTransferJobStart,
  createTransferJobStop,
  deleteTransferJob,
  deleteTransferJobAccount,
  updateTransferJob
} from 'src/store/actions/transferActions';
import AddAccount from './AddAccount';
import DataTable from 'src/components/DataTable';
import AddressCell from './AddressCell';
import ConfirmButton from 'src/components/ConfirmButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trash as DeleteIcon, X as CloseIcon } from 'react-feather';

const newJobValidationSchema = Yup.object().shape({
  coin: Yup.string().required('Required'),
  totalCoinAmounts: Yup.number().required('Required'),
  minValue: Yup.number().required('Required'),
  maxValue: Yup.number().required('Required')
});

function TransferJobDetail({ open = false, onClose, initialValues }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { accounts, status, ...values } = initialValues;

  const [isDeleted, setDeletedId] = useState(null);
  const [accountList, setAccounts] = useState(accounts || []);

  const addresses = useSelector(state => state.transfer.addresses)[isDeleted];

  const formik = useFormik({
    initialValues: values,
    validationSchema: newJobValidationSchema,
    onSubmit: async values => {
      try {
        await dispatch(updateTransferJob(values));

        enqueueSnackbar(`Created Job for the ${values.coin}`, {
          variant: 'success'
        });
        formik.resetForm();
        formik.setStatus({ success: true });
        await wait(500);

        onClose();
      } catch (error) {
        formik.setStatus({ success: false });
        formik.setErrors({ submit: error.message });
      }
    }
  });

  const coins = [MinorCoinEnum.USDT, ...Object.values(CoinEnum)];
  coins.pop(); // delete ETH_UNAVAILABLE

  const columns = [
    {
      key: 'addressId',
      title: 'Address',
      renderCell: acc => <AddressCell address={acc} />
    },
    {
      key: 'expectationRatio',
      title: 'Ratio',
      align: 'center'
    },
    {
      key: 'delete',
      title: 'Delete',
      align: 'right',
      renderCell: (id, row) => (
        <IconButton onClick={() => setDeletedId(row.addressId)}>
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      )
    }
  ];

  const handleDelete = async onError => {
    try {
      await dispatch(deleteTransferJob(initialValues.id));
      enqueueSnackbar(`Created Job for the ${initialValues.coin}`, {
        variant: 'success'
      });
      await wait(500);
      onClose();
    } catch (e) {
      onError();
    }
  };

  const handleDeleteAccount = async () => {
    try {
      clearAddressId();
      await dispatch(
        deleteTransferJobAccount({ id: initialValues.id, addressId: isDeleted })
      );
      enqueueSnackbar(`Deleted Job Account for the ${addresses?.addressName}`, {
        variant: 'success'
      });
      setAccounts(accountList.filter(acc => acc.addressId !== isDeleted));
    } catch (error) {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: error.message });
    }
  };

  const handleJobStart = async () => {
    try {
      clearAddressId();
      await dispatch(createTransferJobStart(initialValues.id));
      enqueueSnackbar(`Started Job for the ${initialValues.coin}`, {
        variant: 'success'
      });
      await wait(500);
      onClose();
    } catch (error) {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: error.message });
    }
  };

  const handleJobStop = async () => {
    try {
      clearAddressId();
      await dispatch(createTransferJobStop(initialValues.id));
      enqueueSnackbar(`Stopped Job for the ${initialValues.coin}`, {
        variant: 'success'
      });
      await wait(500);
      onClose();
    } catch (error) {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: error.message });
    }
  };

  const clearAddressId = () => setDeletedId(null);
  //TODO: status ne olacak
  const isStarted = status !== 'started';
  const handleAdd = values => setAccounts([...accountList, values]);

  return (
    <DrawerPage onClose={onClose} open={open} title="Update Transfer Job">
      <form onSubmit={formik.handleSubmit}>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="coin"
                name="Coin"
                disabled
                value={formik.values.coin}
                items={[formik.values.coin]}
                renderCell={coin => <CoinCell coin={coin} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.totalCoinAmounts &&
                    formik.errors.totalCoinAmounts
                )}
                helperText={
                  formik.touched.totalCoinAmounts &&
                  formik.errors.totalCoinAmounts
                }
                size="small"
                label="Total Amount"
                name="totalCoinAmounts"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.totalCoinAmounts}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.minValue && formik.errors.minValue
                )}
                helperText={formik.touched.minValue && formik.errors.minValue}
                size="small"
                label="Min Limit"
                name="minValue"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.minValue}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.maxValue && formik.errors.maxValue
                )}
                helperText={formik.touched.maxValue && formik.errors.maxValue}
                size="small"
                label="Max Limit"
                name="maxValue"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.maxValue}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        {formik.errors.submit && (
          <Box mt={3}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            disabled={!isStarted || accountList.length < 2}
            color="secondary"
            variant="contained"
            style={{ marginRight: 20 }}
            onClick={handleJobStart}
          >
            Start
          </Button>
          <Button
            disabled={isStarted}
            color="secondary"
            variant="contained"
            style={{ marginRight: 20 }}
            onClick={handleJobStop}
          >
            Stop
          </Button>
          <Button
            color="secondary"
            disabled={formik.isSubmitting}
            type="submit"
            variant="contained"
          >
            Update Job
          </Button>
        </Box>
      </form>
      <ConfirmButton
        buttonTitle="Delete Job"
        content="Do you approve the delete Job?"
        confirmAction={handleDelete}
      />
      <Box m={2}>
        {isStarted && (
          <AddAccount
            jobs={{ ...initialValues, accounts: accountList }}
            handleAdd={handleAdd}
          />
        )}
        <Box marginTop={2}>
          <Collapse in={Boolean(isDeleted)}>
            <Box marginBottom={1}>
              <Alert
                severity="warning"
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={handleDeleteAccount}
                    >
                      CONFIRM
                    </Button>
                    <IconButton color="inherit" onClick={clearAddressId}>
                      <SvgIcon fontSize="small">
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  </>
                }
              >
                <AlertTitle>Warning</AlertTitle>
                Do you approve the delete?
                <br />({addresses?.addressName})
              </Alert>
            </Box>
          </Collapse>
          <DataTable
            idProp="addressId"
            columns={columns}
            source={accountList}
          />
        </Box>
      </Box>
    </DrawerPage>
  );
}

export default TransferJobDetail;
