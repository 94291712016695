import moment from 'moment';
import api from 'src/enums/api';
import httpService from 'src/utils/httpService';

export const GET_REPORTS = '@CNBOT/fetch-reports';

export function fetchReports(range, start, end) {
  let request;
  if (range) {
    request = httpService.makeRequest('get', `${api.report}?range=${range}`);
  } else if (start && end) {
    request = httpService.makeRequest(
      'get',
      `${api.report}?start=${moment(start).format('YYYY-MM-DD')}&end=${moment(
        end
      ).format('YYYY-MM-DD')}`
    );
  } else {
    request = httpService.makeRequest('get', `${api.report}?range=year`);
  }

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_REPORTS,
        payload: response
      })
    );
  };
}
