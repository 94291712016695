import api from 'src/enums/api';
import httpService from 'src/utils/httpService';

export const GET_ROLE_RATIO = '@CNBOT/fetch-setting-role_ratio';
export const SET_USER_META = '@CNBOT/set-user-metadata';
export const UPDATE_SELECTED_ROLE = '@CNBOT/update-selected-role';
export const GET_REPLACE_COINS = '@CNBOT/fetch-setting-replace_coins';

export const GET_COMMON_SETTINGS = '@CNBOT/setting-common_settings';
export const UPDATE_SETTING = '@CNBOT/setting-update';
export const UPDATE_COMPUTE_STATUS = '@CNBOT/setting-update-compute-status';

const localStorageUserKey = 'cnbot_ls_user';
const localStorageRoleKey = () =>
  localStorage.getItem(localStorageUserKey) + '_cnbot_ls_role';

export function updateReplaceCoins(replaceCoins) {
  return dispatch => {
    dispatch({
      type: GET_REPLACE_COINS,
      payload: replaceCoins
    });
  };
}

export async function fetchExtraBalance() {
  return await httpService.makeRequest(
    'get',
    `${api.setting}?key=extra_balance`
  );
}

export async function fetchTotalHolding() {
  return await httpService.makeRequest(
    'get',
    `${api.setting}?key=total_holding`
  );
}

export async function fetchReplaceCoins() {
  return await httpService.makeRequest(
    'get',
    `${api.setting}?key=replace_coin`
  );
}

export async function fetchUnavailableBalance() {
  return await httpService.makeRequest(
    'get',
    `${api.setting}?key=unavailable_balance`
  );
}

export async function updateToken(data) {
  await httpService.makeRequest('post', `${api.setting}/update`, data);
}

export async function fetchRoleRatio() {
  return await httpService.makeRequest('get', `${api.setting}?key=role_ratio`);
}
export function updateRoleRatio(roleRatio) {
  return dispatch => {
    dispatch({
      type: GET_ROLE_RATIO,
      payload: roleRatio
    });
  };
}

export function addUserMetadata(user) {
  localStorage.setItem(localStorageUserKey, user.sub);

  const localRole = localStorage.getItem(
    localStorageRoleKey(localStorage.getItem(localStorageUserKey))
  );

  const namespace = 'https://qtabapp/user_metadata';
  const meta = user[namespace];

  const role =
    localRole && meta.role.includes(localRole) ? localRole : meta.role[0];
  return dispatch => {
    dispatch({
      type: SET_USER_META,
      payload: { meta, role }
    });
  };
}

export function updateSelectedRole(role) {
  localStorage.setItem(localStorageRoleKey(), role);
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_ROLE,
      payload: role
    });
  };
}

export function getCommonSettings() {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.setting}/common`
    );

    dispatch({
      type: GET_COMMON_SETTINGS,
      payload: response
    });
  };
}

export function postUpdate(key, value, isSecure = false) {
  return async dispatch => {
    await httpService.makeRequest('post', `${api.setting}/update`, {
      key,
      value,
      isSecure
    });
    dispatch({
      type: UPDATE_SETTING,
      payload: { key, value }
    });
  };
}
