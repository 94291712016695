import React, { useEffect, useState } from 'react';
import { Button, IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import DataTable from 'src/components/DataTable';
import Page from 'src/components/Page';
import { routesPaths } from 'src/Routes';
import Permission, { UserRoles } from 'src/components/Permission';
import {
  ArrowRight as ArrowRightIcon,
  PlusCircle as PlusCircleIcon
} from 'react-feather';
import NewAddress from './components/NewAddress';
import { useDispatch, useSelector } from 'react-redux';
import CoinCell from 'src/components/CoinCell';
import AccountCell from 'src/components/AccountCell';
import { fetchAllAddresses } from 'src/store/actions/transferActions';

const useStyles = makeStyles(theme => ({
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Addresses() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isOpenNewAddress, setOpenNewAddress] = useState(false);
  const [initialValues, setInitialValues] = useState(undefined);

  const addresses = Object.values(
    useSelector(state => state.transfer.addresses)
  );

  useEffect(() => {
    dispatch(fetchAllAddresses());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialValues) {
      openNewAddress();
    }
  }, [initialValues]);

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Transfer',
      link: routesPaths.transfer.root
    },
    {
      label: 'Jobs',
      link: routesPaths.transfer.jobs
    }
  ];

  const columns = [
    {
      key: 'addressName',
      title: 'Name'
    },
    {
      key: 'coin',
      title: 'Coin',
      renderCell: coin => <CoinCell coin={coin} />
    },
    {
      key: 'network',
      title: 'Network',
      renderCell: coin => <CoinCell coin={coin} />
    },
    {
      key: 'asset',
      title: 'Asset',
      renderCell: coin => <CoinCell coin={coin} />
    },
    {
      key: 'account',
      title: 'Account',
      renderCell: account => <AccountCell account={account} />
    },
    {
      key: 'address',
      title: 'Address'
    },
    {
      key: 'memo',
      title: 'Memo'
    },
    {
      key: 'destinationTag',
      title: 'Destination Tag'
    },
    {
      key: 'id',
      title: 'Action',
      align: 'right',
      renderCell: (btn, row) => {
        return (
          <IconButton onClick={() => setInitialValues(row)}>
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        );
      }
    }
  ];

  const openNewAddress = () => {
    setOpenNewAddress(true);
  };

  const closeNewAddress = () => {
    setOpenNewAddress(false);
    setInitialValues(undefined);
  };

  const renderButton = () => (
    <>
      <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={openNewAddress}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Address
        </Button>
      </Permission>
    </>
  );

  return (
    <Page
      title="Addresses"
      breadcrumbs={breadcrumbs}
      rightContainer={renderButton()}
    >
      {isOpenNewAddress && (
        <NewAddress
          open={isOpenNewAddress}
          onClose={closeNewAddress}
          initialValues={initialValues}
        />
      )}
      <DataTable columns={columns} source={addresses} />
    </Page>
  );
}

export default Addresses;
