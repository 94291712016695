import React from 'react';
import PropTypes from 'prop-types';
import {
  CreditCard as BalancesIcon,
  Layers as BooksIcon,
  Database as TickersIcon
} from 'react-feather';
import { routesPaths } from 'src/Routes';
import SideBar from '../components/SideBar';

function AssetsLayout({ children }) {
  const navConfig = [
    {
      subheader: 'Assets',
      items: [
        {
          title: 'Balances',
          icon: BalancesIcon,
          href: routesPaths.assets.balances
        },
        {
          title: 'Books',
          icon: BooksIcon,
          href: routesPaths.assets.books
        },
        {
          title: 'Tickers',
          icon: TickersIcon,
          href: routesPaths.assets.tickers
        }
      ]
    }
  ];

  return <SideBar config={navConfig}>{children}</SideBar>;
}

AssetsLayout.propTypes = {
  children: PropTypes.any
};

export default AssetsLayout;
