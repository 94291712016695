import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import { getTickers, getTotalBalance } from 'src/store/actions/balancesActions';
import { formatPrice } from 'src/utils/format';
import { COINS_ICONS } from 'src/enums/common';
// import Permission from 'src/components/Permission';
// import { UserRoles } from 'src/components/Permission';

const useStyles = makeStyles(theme => ({
  root: {},
  currency: {
    marginLeft: 10,
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  test: {
    background: 'rgba(0,0,0,0.3)',
    padding: '10px 15px'
  },
  dashboard: {
    background: 'rgba(0,0,0,0.3)',
    padding: '3px 15px',
    color: '#fff'
  },
  wrapper: {
    display: 'flex'
  }
}));

const TotalBalance = ({ isDashboard }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // const balances = useSelector(state => state.balances.data);
  const tickers = useSelector(state => state.balances.tickers);
  const totalBalances = useSelector(state => state.balances.totalBalances);
  const totalHolding = useSelector(state => state.balances.totalHolding);

  // const exchanges = Object.keys(balances);
  // const tickerExchanges = Object.keys(tickers);

  useEffect(() => {
    dispatch(getTickers('paribu'));
    dispatch(getTickers('btcturk'));
    dispatch(getTotalBalance());
  }, [dispatch]);

  const BTC_USDT =
    tickers['btcturk'] && tickers['btcturk']['BTC/USDT']
      ? tickers['btcturk']['BTC/USDT'].bid
      : 0;

  // DON'T REMOVE
  // const getTickerValue = (coin, minorCoin) => {
  //   const parity = `${coin}/${minorCoin}`;
  //   let price = 0;
  //   tickerExchanges.forEach(tickerExchange => {
  //     if (tickers[tickerExchange] && tickers[tickerExchange][parity]) {
  //       if (price < tickers[tickerExchange][parity].ask) {
  //         price = tickers[tickerExchange][parity].ask;
  //       }
  //     }
  //   });
  //   return price;
  // };

  // const getTotal = () => {
  //   const coinsTotal = {};

  //   exchanges.forEach(exchange => {
  //     const { coinBalance, minorCoinBalance } = balances[exchange];
  //     const coins = Object.keys(coinBalance);
  //     const coinsMinor = Object.keys(minorCoinBalance);
  //     coins.forEach(coin => {
  //       if (coinsTotal[coin]) {
  //         coinsTotal[coin] += coinBalance[coin].total;
  //       } else {
  //         coinsTotal[coin] = coinBalance[coin].total;
  //       }
  //     });

  //     coinsMinor.forEach(coin => {
  //       if (minorCoinBalance[coin]?.total) {
  //         if (coinsTotal[coin]) {
  //           coinsTotal[coin] += minorCoinBalance[coin]?.total;
  //         } else {
  //           coinsTotal[coin] = minorCoinBalance[coin]?.total;
  //         }
  //       }
  //     });
  //   });

  //   const coins = Object.keys(coinsTotal);
  //   let totalUSDT = 0;
  //   // let totalTRY = 0;
  //   coins.forEach(coin => {
  //     const totalAmountOfCoin = coinsTotal[coin];

  //     if (coin === 'TRY') {
  //       const priceUSDT = getTickerValue('USDT', 'TRY');

  //       totalUSDT += totalAmountOfCoin / priceUSDT;
  //       // totalTRY += totalAmountOfCoin * priceTRY;
  //     } else if (coin === 'USDT') {
  //       const priceUSDT = 1;

  //       totalUSDT += totalAmountOfCoin * priceUSDT;
  //     } else {
  //       const priceUSDT = getTickerValue(coin, 'USDT');

  //       totalUSDT += totalAmountOfCoin * priceUSDT;
  //     }
  //   });

  //   return totalUSDT && formatPrice(totalUSDT);
  // };

  return (
    <div className={classes.wrapper}>
      <div className={isDashboard ? classes.dashboard : classes.test}>
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold' }}
          color="inherit"
          align="right"
        >
          Holdings: <span className={classes.currency}>$ </span>{' '}
          {formatPrice(totalHolding)}
        </Typography>
      </div>
      <div className={isDashboard ? classes.dashboard : classes.test}>
        {BTC_USDT && (
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold' }}
            color="inherit"
            align="right"
          >
            <span className={classes.currency}> {COINS_ICONS.BTC} </span>
            {formatPrice(totalBalances / BTC_USDT)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TotalBalance;
