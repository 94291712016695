import api from 'src/enums/api';
import httpService from 'src/utils/httpService';
import { TaskStatus } from '@cnbot/common';
import { fetchAllMachine } from './machineAction';
import wait from 'src/utils/wait';

export const GET_JOBS = '@cnbot/get-jobs';
export const GET_JOBS_DETAILS = '@cnbot/get-jobs-details';
export const CREATE_JOBS = '@cnbot/create-job';
export const START_JOBS = '@cnbot/start-job';
export const STOP_JOBS = '@cnbot/stop-job';
export const GET_JOB_DETAIL = '@cnbot/fetch-job-detail';
export const RESET_JOB_DETAIL = '@cnbot/reset-job-detail';

export const READY_JOB = '@cnbot/ready-job';
export const STARTED_JOB = '@cnbot/started-job';
export const STOPPED_JOB = '@cnbot/stopped-job';
export const DELETED_JOB = '@cnbot/deleted-job';

export const UPDATE_PARIBU_STATUS = '@cnbot/update-paribu-status';
export const UPDATE_KOINIM_STATUS = '@cnbot/update-koinim-status';
export const UPDATE_COINZO_STATUS = '@cnbot/update-coinzo-status';

export const JobStatus = TaskStatus;

export function getAllJobs() {
  const request = httpService.makeRequest('get', api.job);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_JOBS,
        payload: response
      })
    );
  };
}

export function getAllJobsDetails() {
  return async dispatch => {
    const response = await httpService.makeRequest('get', `${api.job}/details`);
    dispatch({
      type: GET_JOBS_DETAILS,
      payload: response
    });
  };
}

export function getJobDetail(id) {
  return async dispatch => {
    const response = await httpService.makeRequest('get', `${api.job}/${id}`);
    dispatch({
      type: GET_JOB_DETAIL,
      payload: response
    });
  };
}

export function resetJobDetail(id) {
  return dispatch => {
    dispatch({
      type: RESET_JOB_DETAIL
    });
  };
}

export async function createJob(data) {
  await httpService.makeRequest('post', api.job, data);
}

export async function editJob(id, data) {
  await httpService.makeRequest('put', `${api.job}/${id}`, data);
}

export function deleteJob(jobId) {
  return async dispatch => {
    await httpService.makeRequest('delete', `${api.job}/${jobId}`);
    await wait(1000);
    fetchAllMachine()(dispatch);
    getAllJobs()(dispatch);
  };
}

export function startJob(jobId) {
  const request = httpService.makeRequest('put', `${api.job}/${jobId}/start`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: START_JOBS,
        payload: response
      })
    );
  };
}

export function stopJob(jobId) {
  const request = httpService.makeRequest('put', `${api.job}/${jobId}/stop`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: STOP_JOBS,
        payload: response
      })
    );
  };
}

export function readyJob(data) {
  return dispatch => {
    dispatch({
      type: READY_JOB,
      payload: data
    });
  };
}

export function startedJob(data) {
  return dispatch => {
    dispatch({
      type: STARTED_JOB,
      payload: data
    });
  };
}

export function stoppedJob(data) {
  return dispatch => {
    dispatch({
      type: STOPPED_JOB,
      payload: data
    });
  };
}

export function deletedJob(data) {
  return dispatch => {
    dispatch({
      type: DELETED_JOB,
      payload: data
    });
  };
}
