import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { routesPaths } from 'src/Routes';
import {
  Box,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  TextField,
  Grid
} from '@material-ui/core';
import TransactionsTable from './components/TransactionsTable';
import FailTransactionsTable from './components/FailTransactionsTable';
import OrderFailedTable from './components/OrderFailedTable';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useCoins } from 'src/hooks/useCoins';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  filterContainer: {
    justifyContent: 'flex-end'
  }
}));

function Transaction() {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const { coins } = useCoins();

  const pageOptions = qs.parse(location.search);

  const breadcrumbs = [
    {
      label: 'Home',
      link: routesPaths.dashboard
    },
    {
      label: 'Transactions',
      link: routesPaths.transactions
    }
  ];

  const tabs = [
    { value: 'completed', label: 'Success Transactions' },
    { value: 'canceled', label: 'Canceled Transactions' },
    { value: 'error', label: 'Fail Transactions' },
    { value: 'order-failed', label: 'Order Failed' }
  ];

  if (!pageOptions.status) {
    setTimeout(() => {
      history.push({
        pathname: routesPaths.transactions,
        search: `?${qs.stringify({ ...pageOptions, status: 'completed' })}`
      });
    }, 300);
  }

  const [currentTab, setCurrentTab] = useState(pageOptions.status);
  const [date, setDate] = useState(moment());
  const [selectedCoin, setSelectedCoin] = useState(pageOptions.coin);

  useEffect(() => {
    const pageOptions = qs.parse(location.search);
    console.log(pageOptions);
    if (currentTab !== pageOptions.status) {
      setCurrentTab(pageOptions.status);
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabsChange = (event, value) => {
    const pageOptions = qs.parse(location.search);

    history.push({
      pathname: routesPaths.transactions,
      search: `?${qs.stringify({ ...pageOptions, status: value, page: 1 })}`
    });

    setCurrentTab(value);
  };

  const handleChangeCoin = event => {
    const pageOptions = qs.parse(location.search);
    setSelectedCoin(event.target.value);

    history.push({
      pathname: routesPaths.transactions,
      search: `?${qs.stringify({
        ...pageOptions,
        coin:
          event.target.value === 'All Coins' ? undefined : event.target.value,
        page: 1
      })}`
    });
  };

  const handleChangeDate = () => {
    console.log(date);
    history.push({
      pathname: routesPaths.transactions,
      search: `?${qs.stringify({
        ...pageOptions,
        date: date.format('YYYY.MM.DD'),
        page: 1
      })}`
    });
  };

  const renderFilter = () => {
    return (
      <Grid container spacing={1} className={classes.filterContainer}>
        <Grid item>
          <TextField
            size="small"
            label="Coin"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            onChange={handleChangeCoin}
            value={selectedCoin}
          >
            <option key="all" value={undefined}>
              All Coins
            </option>
            {Object.values(coins).map(coin => (
              <option key={coin} value={coin}>
                {coin}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            size="small"
            label="Date"
            variant="inline"
            disableToolbar
            format="DD.MM.YYYY"
            inputVariant="outlined"
            views={['month', 'date']}
            value={date}
            onClose={handleChangeDate}
            onChange={date => setDate(date)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Page
      className={classes.root}
      title="Transactions"
      breadcrumbs={breadcrumbs}
      rightContainer={renderFilter()}
    >
      <Box mt={3}>
        {pageOptions.status && currentTab ? (
          <>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              variant="scrollable"
              textColor="secondary"
              value={currentTab}
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />
            <Box mt={3}>
              {currentTab === 'completed' && (
                <TransactionsTable options={pageOptions} />
              )}
              {currentTab === 'canceled' && (
                <TransactionsTable options={pageOptions} />
              )}
              {currentTab === 'error' && (
                <FailTransactionsTable options={pageOptions} />
              )}
              {currentTab === 'order-failed' && (
                <OrderFailedTable options={pageOptions} />
              )}
            </Box>
          </>
        ) : (
          ''
        )}
      </Box>
    </Page>
  );
}

export default Transaction;
