import httpService from 'src/utils/httpService';
import api from 'src/enums/api';

export const GET_TICKER = '@cnbot/get-ticker';
export const GET_TICKERS = '@cnbot/get-tickers';
export const GET_TOTAL_BALANCES = '@cnbot/get-all-balances';

export const INITIAL_BALANCES = '@CNBOT/balance-initilaze';
export const UPDATE_BALANCES = '@CNBOT/updated-balance';

export const GET_EXTRA_BALANCE = '@CNBOT/fetch-setting-extra_balance';
export const GET_TOTAL_HOLDINGS = '@CNBOT/fetch-setting-total-holdings';
export const GET_UNAVAILABLE_BALANCE =
  '@CNBOT/fetch-setting-unavailable_balance';

export function updateExtraBalance(extraBalance) {
  return dispatch => {
    dispatch({
      type: GET_EXTRA_BALANCE,
      payload: extraBalance
    });
  };
}

export function updateTotalHolding(holding) {
  return dispatch => {
    dispatch({
      type: GET_TOTAL_HOLDINGS,
      payload: holding
    });
  };
}

export function updateUnavailableBalance(unavailableaBalance) {
  return dispatch => {
    dispatch({
      type: GET_UNAVAILABLE_BALANCE,
      payload: unavailableaBalance
    });
  };
}

export function getTicker() {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.common}/ticker?parity=USDT_TRY`
    );

    dispatch({
      type: GET_TICKER,
      payload: response
    });
  };
}

export function getTickers(exchange) {
  return async dispatch => {
    const response = await httpService.makeRequest(
      'get',
      `${api.common}/tickers?exchange=${exchange}`
    );

    dispatch({
      type: GET_TICKERS,
      payload: response
    });
  };
}

export function getTotalBalance() {
  return async dispatch => {
    try {
      const response = await httpService.makeRequest(
        'get',
        `${api.account}/balance/total`
      );

      dispatch({
        type: GET_TOTAL_BALANCES,
        payload: response
      });
    } catch (e) {}
  };
}
