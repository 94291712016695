import React from 'react';
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import wait from 'src/utils/wait';
import { CoinEnum, MinorCoinEnum } from 'src/enums/common';
import DrawerPage from 'src/components/DrawerPage';
import {
  createNewAddress,
  deleteAddress,
  updateAddress
} from 'src/store/actions/transferActions';
import AccountCell from 'src/components/AccountCell';
import Select from 'src/components/Select';
import CoinCell from 'src/components/CoinCell';
import ConfirmButton from 'src/components/ConfirmButton';
import { TransferNetworkEnum } from '@cnbot/common';

const newAddressValidationSchema = Yup.object().shape({
  addressName: Yup.string().required('Required'),
  coin: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  address: Yup.string().required('Required')
});

function NewAddress({
  open = false,
  onClose,
  initialValues = {
    id: undefined,
    addressName: '',
    coin: '',
    account: '',
    address: '',
    network: '',
    asset: '',
    memo: '',
    destinationTag: ''
  }
}) {
  const isNew = !initialValues?.id;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const accounts = useSelector(state => state.accounts.list);

  const formik = useFormik({
    initialValues,
    validationSchema: newAddressValidationSchema,
    onSubmit: async values => {
      try {
        if (isNew) {
          await dispatch(createNewAddress(values));
        } else {
          await dispatch(updateAddress(values));
        }
        enqueueSnackbar(
          `${isNew ? 'Created' : 'Update'} Address for the ${
            values.addressName
          }`,
          {
            variant: 'success'
          }
        );
        formik.resetForm();
        formik.setStatus({ success: true });
        await wait(500);

        onClose();
      } catch (error) {
        console.log(error);
        formik.setStatus({ success: false });
        formik.setErrors({ submit: error.message });
      }
    }
  });

  const coins = [MinorCoinEnum.USDT, ...Object.values(CoinEnum)];
  coins.pop(); // delete ETH_UNAVAILABLE

  const deleteAddressAction = async () => {
    try {
      await dispatch(deleteAddress(initialValues.id));
      enqueueSnackbar(`Deleted Address for the ${initialValues.addressName}`, {
        variant: 'success'
      });
      await wait(500);
      onClose();
    } catch (error) {
      console.log(error);
      formik.setStatus({ success: false });
      formik.setErrors({ submit: error.message });
    }
  };

  return (
    <DrawerPage
      onClose={onClose}
      open={open}
      title={isNew ? 'New Address' : 'Update Address'}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.addressName && formik.errors.addressName
                )}
                helperText={
                  formik.touched.addressName && formik.errors.addressName
                }
                size="small"
                label="Name"
                name="addressName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.addressName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="coin"
                name="Coin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.coin}
                error={formik.touched.coin && formik.errors.coin}
                items={Object.values(coins)}
                renderCell={coin => <CoinCell coin={coin} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="network"
                name="Network"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.network}
                error={formik.touched.network && formik.errors.network}
                items={Object.values(TransferNetworkEnum)}
                renderCell={coin => <CoinCell coin={coin} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="asset"
                name="Asset"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.asset}
                error={formik.touched.asset && formik.errors.asset}
                items={Object.values(TransferNetworkEnum)}
                renderCell={coin => <CoinCell coin={coin} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Select
                id="account"
                name="Account"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.account}
                error={formik.touched.account && formik.errors.account}
                items={Object.keys(accounts)}
                renderCell={acc => <AccountCell account={acc} />}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.touched.address && formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                size="small"
                label="Address"
                name="address"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.touched.memo && formik.errors.memo)}
                helperText={formik.touched.memo && formik.errors.memo}
                size="small"
                label="Memo"
                name="memo"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.memo}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                error={Boolean(
                  formik.touched.destinationTag && formik.errors.destinationTag
                )}
                helperText={
                  formik.touched.destinationTag && formik.errors.destinationTag
                }
                size="small"
                label="Destination Tag"
                name="destinationTag"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.destinationTag}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        {formik.errors.submit && (
          <Box mt={3}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            disabled={formik.isSubmitting}
            type="submit"
            variant="contained"
          >
            {isNew ? 'Create' : 'Update'} Address
          </Button>
        </Box>
      </form>
      {!isNew && (
        <ConfirmButton
          buttonTitle="Delete Address"
          content="Do you approve the delete Address?"
          confirmAction={deleteAddressAction}
        />
      )}
    </DrawerPage>
  );
}

export default NewAddress;
