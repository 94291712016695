import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { X as CloseIcon } from 'react-feather';
import { Copy as CopyIcon } from 'react-feather';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Link,
  Drawer,
  Card,
  Badge,
  CardHeader
} from '@material-ui/core';
import TotalBalance from './TotalBalance';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fetchAllMachine } from 'src/store/actions/machineAction';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Menu as MenuIcon } from 'react-feather';
import { routesPaths } from 'src/Routes';
import Account from './Account';
import EarningDisplay from './EarningDisplay';
import { fetchAllAccounts } from 'src/store/actions/accountsActions';
import { useSnackbar } from 'notistack';
import socket, { SocketEvents } from 'src/utils/socket';
import { ReactComponent as LogoIcon } from 'src/assets/logo.svg';
import {
  startedJob,
  readyJob,
  getAllJobs,
  stoppedJob
} from 'src/store/actions/jobsActions';
import {
  addTransaction,
  getEarnings,
  getEarningCoins
} from 'src/store/actions/transactionActions';
import Balances from './Balances';
import authService from 'src/services/authService';
import { UserRoles } from 'src/components/Permission';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.default
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(4)
    }
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    display: 'inline-flex',
    '&>svg': {
      width: 'auto',
      height: 26
    }
  },
  currency: {
    '& > svg': {
      height: 16,
      width: 'auto'
    }
  },
  drawer: {
    width: '100%',
    maxWidth: '100%'
  },
  menuLink: {
    padding: '10px 20px'
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleRatio = useSelector(state => state.setting.selectedRatio);
  const userRole = useSelector(state => state.setting.selectedRole);
  const replaceCoins = useSelector(state => state.setting.replaceCoins);
  let balances = useSelector(state => state.balances.data);
  const [isOpenMobileMenu, setMobileNavOpen] = useState(false);

  const exchanges = Object.keys(balances);
  let btcTotal = 0;
  let ethTotal = 0;

  exchanges.forEach(e => {
    btcTotal = btcTotal + balances[e].coinBalance['BTC'].total;
    ethTotal = ethTotal + balances[e].coinBalance['ETH'].total;
  });

  const { enqueueSnackbar } = useSnackbar();

  const subscribeCreatedTask = data => {
    dispatch(getAllJobs());
    enqueueSnackbar(`"${data.coin}" job is created!"`, {
      variant: 'success'
    });
  };

  const subscribeStartedTask = data => {
    dispatch(startedJob(data));
    enqueueSnackbar(`"${data.coin}" job is started!"`, {
      variant: 'success'
    });
  };

  const subscribeStoppedTask = data => {
    dispatch(stoppedJob(data));
    enqueueSnackbar(`"${data.coin}" job is stopped!"`, {
      variant: 'success'
    });
  };

  const subscribeReadyTask = data => {
    dispatch(readyJob(data));
    enqueueSnackbar(`"${data.coin}" job is ready!"`, {
      variant: 'success'
    });
  };

  const subscribeCreatedNewTransaction = data => {
    if (
      Object.keys(replaceCoins).includes(data.coin) &&
      userRole === UserRoles.REPORTER
    ) {
      return;
    }
    const earning = data.earning.toFixed(2) * roleRatio;
    dispatch(addTransaction({ ...data, newFlag: true }));

    enqueueSnackbar(`New: "${data.coin}": ${earning} ${data.earningCurrency}`, {
      variant: data.status === 'insufficient' ? 'info' : 'success',
      autoHideDuration: 1000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    });
  };

  const handleCopyAccessToken = () => {
    enqueueSnackbar(`Access Token is copied!`, {
      variant: 'info',
      autoHideDuration: 500,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  };

  useEffect(() => {
    socket.subscribe(SocketEvents.CreatedTask, subscribeCreatedTask);
    socket.subscribe(SocketEvents.StartedTask, subscribeStartedTask);
    socket.subscribe(SocketEvents.StoppedTask, subscribeStoppedTask);
    socket.subscribe(SocketEvents.ReadyTask, subscribeReadyTask);
    socket.subscribe(
      SocketEvents.CreatedNewTransaction,
      subscribeCreatedNewTransaction
    );
    return () => {
      socket.unsubscribe(SocketEvents.CreatedTask, subscribeCreatedTask);
      socket.unsubscribe(SocketEvents.StartedTask, subscribeStartedTask);
      socket.unsubscribe(SocketEvents.StoppedTask, subscribeStoppedTask);
      socket.unsubscribe(SocketEvents.ReadyTask, subscribeReadyTask);
      socket.unsubscribe(
        SocketEvents.CreatedNewTransaction,
        subscribeCreatedNewTransaction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchAllMachine());
    dispatch(fetchAllAccounts());
    dispatch(getAllJobs());
    dispatch(getEarnings());
    dispatch(getEarningCoins());
  }, [dispatch]);

  const menuList = [
    {
      label: 'Dashboard',
      link: routesPaths.dashboard
    },
    {
      label: 'Transactions',
      link: routesPaths.transactions
    },
    ...(userRole === UserRoles.DEVELOPER || userRole === UserRoles.MAINTAINER
      ? [
          {
            label: 'Transfer',
            link: routesPaths.transfer.root
          }
        ]
      : []),
    {
      label: 'Reports',
      link: routesPaths.reports
    }
  ];

  const mobilMenuList = [
    {
      label: 'Dashboard',
      link: routesPaths.dashboard
    },
    {
      label: 'Balances',
      link: routesPaths.assets.balances
    },
    {
      label: 'Books',
      link: routesPaths.assets.books
    },
    {
      label: 'Tickers',
      link: routesPaths.assets.tickers
    },
    {
      label: 'Reports',
      link: routesPaths.reports
    },
    {
      label: 'Transactions',
      link: routesPaths.transactions
    },

    ...(userRole !== 'reporter' && userRole !== 'standard'
      ? [
          {
            label: 'Jobs',
            link: routesPaths.jobs
          }
        ]
      : []),

    ...(userRole === UserRoles.DEVELOPER || userRole === UserRoles.MAINTAINER
      ? [
          {
            label: 'Transfer Dashboard',
            link: routesPaths.transfer.root
          },
          {
            label: 'Transfer Jobs',
            link: routesPaths.transfer.jobs
          },
          {
            label: 'Addresses',
            link: routesPaths.transfer.address
          }
        ]
      : []),
    {
      label: 'Accounts',
      link: routesPaths.settings.accounts
    },
    {
      label: 'Logout',
      link: routesPaths.logout
    }
  ];

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Balances />
        <Box>
          <Link
            className={classes.logo}
            color="textSecondary"
            component={RouterLink}
            to={routesPaths.dashboard}
            underline="none"
            variant="h6"
          >
            <LogoIcon />
          </Link>
        </Box>
        <Hidden mdDown>
          <Box ml={2}>
            {menuList.map(menuItem => (
              <Link
                key={menuItem.label}
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to={menuItem.link}
                underline="none"
                variant="h6"
              >
                {menuItem.badge ? (
                  <Badge color="secondary" badgeContent={menuItem.badge}>
                    {menuItem.label}
                  </Badge>
                ) : (
                  menuItem.label
                )}
              </Link>
            ))}
          </Box>
          <Box ml={2} flexGrow={1} />
          <Box>
            <TotalBalance />
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Box flexGrow={1} />
        </Hidden>
        <Box ml={2}>
          <EarningDisplay />
        </Box>
        <Box ml={2}>
          <Hidden mdDown>
            <Account />
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={() => setMobileNavOpen(true)}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>

            <Drawer
              onClose={() => setMobileNavOpen(false)}
              open={isOpenMobileMenu}
              classes={{ paper: classes.drawer }}
              anchor="right"
              variant="temporary"
            >
              <Card className={clsx(classes.root, className)} {...rest}>
                <CardHeader
                  title="Menu"
                  action={
                    <IconButton
                      // className={classes.menuButton}
                      color="inherit"
                      onClick={() => setMobileNavOpen(false)}
                    >
                      <SvgIcon fontSize="small">
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  }
                />
                <PerfectScrollbar>
                  <Box>
                    {mobilMenuList.map(menuItem => (
                      <div className={classes.menuLink} key={menuItem.link}>
                        <Link
                          className={classes.link}
                          onClick={() => setMobileNavOpen(false)}
                          color="textSecondary"
                          component={RouterLink}
                          to={menuItem.link}
                          underline="none"
                          variant="h6"
                        >
                          {menuItem.label}
                        </Link>
                      </div>
                    ))}
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Drawer>
          </Hidden>
        </Box>
        {userRole === 'developer' && (
          <Box>
            <CopyToClipboard
              text={authService.getAccessToken()}
              onCopy={handleCopyAccessToken}
            >
              <IconButton color="inherit">
                <SvgIcon fontSize="small">
                  <CopyIcon />
                </SvgIcon>
              </IconButton>
            </CopyToClipboard>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
