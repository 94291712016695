/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_ROLE_RATIO,
  SET_USER_META,
  UPDATE_SELECTED_ROLE,
  GET_REPLACE_COINS,
  GET_COMMON_SETTINGS,
  UPDATE_SETTING,
  UPDATE_COMPUTE_STATUS
} from 'src/store/actions/settingActions';

const initialState = {
  roleRatio: {},
  userMetadata: {
    role: ['reporter']
  },
  selectedRole: 'reporter',
  selectedRatio: 0,
  totalHolding: 0,
  replaceCoins: {},
  commonSettings: {},
  computeStatus: null
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_RATIO: {
      return produce(state, draft => {
        draft.roleRatio = action.payload;
      });
    }

    case GET_REPLACE_COINS: {
      return produce(state, draft => {
        draft.replaceCoins = action.payload;
      });
    }

    case SET_USER_META: {
      return produce(state, draft => {
        draft.userMetadata = action.payload.meta;
        draft.selectedRole = action.payload.role;
        draft.selectedRatio = state.roleRatio[draft.selectedRole] || 0;
      });
    }

    case UPDATE_SELECTED_ROLE: {
      return produce(state, draft => {
        draft.selectedRole = action.payload;
        draft.selectedRatio = state.roleRatio[action.payload] || 0;
      });
    }

    case GET_COMMON_SETTINGS: {
      return produce(state, draft => {
        action.payload.forEach(item => {
          draft.commonSettings[item.key] = item;
        });
      });
    }

    case UPDATE_SETTING: {
      return produce(state, draft => {
        draft.commonSettings[action.payload.key].value = action.payload.value;
      });
    }

    case UPDATE_COMPUTE_STATUS: {
      return produce(state, draft => {
        draft.computeStatus = action.payload.value;
      });
    }

    default: {
      return state;
    }
  }
};

export default settingReducer;
